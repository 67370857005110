import { Box } from '@mui/material'
import _ from 'lodash'
import React from 'react'

import FacebookCardContainer from '../FacebookCardContainer/FacebookCardContainer'
import FacebookCardImage from '../FacebookCardImage/FacebookCardImage'
import { CardLink, ContentLeft, Description, StyledButton, Title } from './styles'

interface FacebookAdCardProps {
  image?: string | null
  title?: string
  description?: string
  buttonLabel?: string | null
  cardLink?: string
  adType?: string
}

const FacebookAdCard: React.FC<FacebookAdCardProps> = ({
  image = null,
  title = '',
  description = '',
  buttonLabel = null,
  cardLink = '',
  adType = '',
}) => {
  let url: string
  try {
    url = new URL(cardLink).host.toUpperCase()
  } catch (e) {
    url = ''
  }

  const isImageType: boolean = adType === 'image'

  const primary = <FacebookCardImage src={image} />

  const secondary = (
    <>
      <ContentLeft>
        {isImageType && (
          <CardLink variant='body2' data-testid='FacebookAdCard_cardLink_label'>
            {_.truncate(url, { length: 25 })}
          </CardLink>
        )}
        <Title
          variant='body1'
          fullheight={!isImageType}
          data-testid='FacebookAdCard_shortHeadline_label'
        >
          {_.truncate(title, { length: 40 })}
        </Title>
        <Description variant='body2' data-testid='FacebookAdCard_shortDescription_label'>
          {_.truncate(description, { length: 20 })}
        </Description>
      </ContentLeft>
      {buttonLabel && (
        <Box>
          <StyledButton variant='outlined' data-testid='FacebookAdCard_cTA_button'>
            {buttonLabel}
          </StyledButton>
        </Box>
      )}
    </>
  )

  return <FacebookCardContainer primary={primary} secondary={secondary} fullwidth={isImageType} />
}

export default FacebookAdCard
