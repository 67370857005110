import {
  MenuMappingOverrideSectionResource,
  MenuMappingOverrideSectionResponse,
  MenuMappingOverrideSectionResponseIncluded,
  MenuMappingOverrideSectionsResponse,
  MenuMappingScheduleResource,
  MenuMappingScheduleResponse,
  MenuMappingSectionCollectionResource,
  MenuMappingSectionResponseIncluded,
  MenuMappingServiceAvailabilityResource,
  MenuMappingSubsectionResource,
  MenuMappingTimePeriodResource,
  TypeName,
} from 'api/models'
import { dayIndex, to12HourTimeFrom24Hour } from 'utils'

import { JoinedMenuMappingOverrideSection, JoinedMenuMappingSchedule } from './types'

export const getAvailabilities = (
  included:
    | MenuMappingOverrideSectionResponseIncluded[]
    | MenuMappingSectionResponseIncluded[]
    | undefined,
) => {
  return (
    ((included ?? []) as MenuMappingServiceAvailabilityResource[])
      .filter<MenuMappingServiceAvailabilityResource>(
        (obj): obj is MenuMappingServiceAvailabilityResource =>
          obj.type === TypeName.MenuMappingServiceAvailability,
      )
      .map(({ id, attributes, relationships }) => ({
        id,
        ...attributes,
        timePeriods: (relationships.time_periods?.data ?? [])
          .map(({ id }) =>
            ((included ?? []) as MenuMappingTimePeriodResource[]).find(
              (obj): obj is MenuMappingTimePeriodResource => obj.id === id,
            ),
          )
          .filter((obj): obj is MenuMappingTimePeriodResource => Boolean(obj)),
      }))
      // .filter(({ enabled, timePeriods }) => enabled && timePeriods.length > 0)
      .map(({ id, day_of_week: dayOfWeek, timePeriods, enabled }) => {
        const hours = timePeriods
          .filter(
            ({ attributes }) =>
              attributes.start_time !== undefined && attributes.end_time !== undefined,
          )
          .map(({ attributes: { start_time, end_time } }) => {
            if (start_time && end_time) {
              return [to12HourTimeFrom24Hour(start_time), to12HourTimeFrom24Hour(end_time)].join(
                ' - ',
              )
            }
          })
          .join(', ')
        return { id, dayOfWeek, timePeriods, hours, enabled }
      })
      .sort((a, b) => dayIndex[a.dayOfWeek] - dayIndex[b.dayOfWeek])
  )
}

export const joinAvailabilitiesAndPeriods = ({
  data,
  included,
}: MenuMappingScheduleResponse): JoinedMenuMappingSchedule => {
  return {
    ...data.attributes,
    id: data.id,
    availabilities: getAvailabilities(included),
  }
}

export const joinScheduleAndSubsections = ({
  data,
  included,
}: MenuMappingOverrideSectionResponse | MenuMappingOverrideSectionsResponse) => {
  const join = ({
    id,
    attributes,
    relationships,
  }: MenuMappingOverrideSectionResource): JoinedMenuMappingOverrideSection => {
    const secColls = (included ?? []).filter<MenuMappingSectionCollectionResource>(
      (obj): obj is MenuMappingSectionCollectionResource =>
        (relationships.override_section_collections.data ?? []).some(
          (section_collection) => section_collection.id === obj.id,
        ),
    )
    const schedule = (included ?? []).find(
      (obj): obj is MenuMappingScheduleResource => obj.id === relationships.schedule.data?.id,
    )
    return {
      id,
      ...attributes,
      schedule,
      subsections: (included ?? [])
        .filter<MenuMappingSubsectionResource>((obj): obj is MenuMappingSubsectionResource =>
          (relationships.subsections.data ?? []).some((subsection) => subsection.id === obj.id),
        )
        .sort((a, b) => {
          const collA = secColls.find(
            (secColl) =>
              secColl.relationships.section.data.id === a.relationships?.section?.data.id,
          )
          const collB = secColls.find(
            (secColl) =>
              secColl.relationships.section.data.id === b.relationships?.section?.data.id,
          )
          return (collA?.attributes.sort_position || 0) - (collB?.attributes.sort_position || 0)
        }),
      availabilities: getAvailabilities(included),
    }
  }

  if (Array.isArray(data)) {
    return data
      .sort((a, b) => (a.attributes.sort_position || 0) - (b.attributes.sort_position || 0))
      .map(join)
  }
  return join(data)
}
