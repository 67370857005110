import { useLocations } from 'components/MainLayout/MainLayout'
import StyledSelect from './StyledSelect/StyledSelect'
import {
  LocationsContainer,
  PageContainer,
  Title,
  ToDoContainer,
  PaidAdsContainer,
  ToDoTitle,
  TabsContainer,
  Tabs,
  Tab,
} from './styles'
import ToDo from './ToDo/ToDo'
import PublishedCampaigns from './PublishedCampaigns/PublishedCampaigns'
import { useEffect, useState } from 'react'
import { LocationSummary } from 'types'

const DigitalMarketing = () => {
  const { locationsData } = useLocations()
  const { locations } = locationsData
  const [partnerId, setPartnerId] = useState<string | undefined>(undefined)
  const mapLocations = locations?.map((loc: LocationSummary) => ({
    value: loc.id.toString(),
    label: loc.name,
  }))

  useEffect(() => {
    if (mapLocations.length > 0 && !partnerId) {
      setPartnerId(mapLocations[0]?.value)
    }
  }, [mapLocations, partnerId])

  return (
    <PageContainer>
      <Title>Digital Marketing</Title>
      <LocationsContainer>
        <StyledSelect
          options={mapLocations}
          placeholder={mapLocations[0]?.label || 'Loading...'}
          onChange={setPartnerId}
          value={mapLocations.find(option => option.value === partnerId)}
        />
      </LocationsContainer>

      <ToDoContainer>
        <ToDoTitle>Digital Marketing To-Do</ToDoTitle>
        <ToDo partnerId={partnerId} />
      </ToDoContainer>

      <TabsContainer>
          <Tabs>
            <Tab active>Paid Ads</Tab>
          </Tabs>
      </TabsContainer>
      
      <PaidAdsContainer>
        <PublishedCampaigns partnerId={partnerId} />
      </PaidAdsContainer>
    </PageContainer>
  )
}

export default DigitalMarketing
