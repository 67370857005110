import CloseIcon from '@mui/icons-material/Close'
import { Drawer, IconButton } from '@mui/material'
import { ReportingOrder } from 'api/orderManagment/types'
import React from 'react'
import { formatSubmissionDate, formatSubmissionTime } from 'utils'

import * as Styled from './OrderDetailsDrawerStyles'
import { OrderDisplayStatus, mapOrderStatus, statusConfig } from './OrderManagement'

interface OrderDetailsDrawerProps {
  isOpen: boolean
  onClose: () => void
  order: ReportingOrder | null
}

const OrderDetailsDrawer: React.FC<OrderDetailsDrawerProps> = ({ isOpen, onClose, order }) => {
  if (!order) return null

  const {
    order_platform_id,
    platform_name,
    submission_time,
    pos_order_id,
    customer_first_name,
    customer_last_name,
    customer_phone,
    customer_email,
    subtotal,
    tax,
    tip,
    delivery_fee,
    order_type,
    order_status,
    delivery_street,
  } = order.attributes

  return (
    <Drawer
      anchor='right'
      open={isOpen}
      onClose={onClose}
      sx={{ '& .MuiDrawer-paper': { maxWidth: '400px', width: '100%', padding: 0 } }}
    >
      <Styled.DrawerHeader>
        <h2>Order Details</h2>
        <IconButton onClick={onClose}>
          <CloseIcon />
        </IconButton>
      </Styled.DrawerHeader>
      <Styled.DrawerContent>
        <Styled.RowPair>
          <Styled.Row>
            <Styled.Label>Platform Ticket ID:</Styled.Label>
            <Styled.Value horizontal>
              #{order_platform_id}
              <Styled.StyledChip
                label={mapOrderStatus(order_status)}
                icon={statusConfig[mapOrderStatus(order_status) as OrderDisplayStatus].icon}
                status={mapOrderStatus(order_status) as OrderDisplayStatus}
                size='small'
              />
            </Styled.Value>
          </Styled.Row>
          <Styled.Row>
            <Styled.Label>Platform:</Styled.Label>
            <Styled.Value>{platform_name}</Styled.Value>
          </Styled.Row>
        </Styled.RowPair>
        <Styled.RowPair>
          <Styled.Row>
            <Styled.Label>Submission Time:</Styled.Label>
            <Styled.Value horizontal>
              <Styled.SubmissionTime>{formatSubmissionTime(submission_time)}</Styled.SubmissionTime>
              <Styled.SubmissionDate>{formatSubmissionDate(submission_time)}</Styled.SubmissionDate>
            </Styled.Value>
          </Styled.Row>
          <Styled.Row>
            <Styled.Label>POS Ticket ID:</Styled.Label>
            <Styled.Value>{pos_order_id}</Styled.Value>
          </Styled.Row>
        </Styled.RowPair>
        <Styled.Row>
          <Styled.Label>Customer Information:</Styled.Label>
          <Styled.Value>
            <Styled.InfoItem>
              {customer_first_name} {customer_last_name}
            </Styled.InfoItem>
            <Styled.InfoItem>
              <a href={`tel:${customer_phone}`}>{customer_phone}</a>
            </Styled.InfoItem>
            <Styled.InfoItem>
              <a href={`mailto:${customer_email}`}>{customer_email}</a>
            </Styled.InfoItem>
          </Styled.Value>
        </Styled.Row>
        <Styled.RowPair>
          <Styled.Row>
            <Styled.Label>Subtotal:</Styled.Label>
            <Styled.Value>${subtotal}</Styled.Value>
          </Styled.Row>
          <Styled.Row>
            <Styled.Label>Tax Amount:</Styled.Label>
            <Styled.Value>${tax}</Styled.Value>
          </Styled.Row>
        </Styled.RowPair>
        <Styled.RowPair>
          <Styled.Row>
            <Styled.Label>Tip Amount:</Styled.Label>
            <Styled.Value>${tip}</Styled.Value>
          </Styled.Row>
          <Styled.Row>
            <Styled.Label>Delivery Fee:</Styled.Label>
            <Styled.Value>${delivery_fee}</Styled.Value>
          </Styled.Row>
        </Styled.RowPair>
        <Styled.Row>
          <Styled.Label>Delivery Address:</Styled.Label>
          <Styled.Value>{delivery_street}</Styled.Value>
        </Styled.Row>
        <Styled.Row>
          <Styled.Label>Order Type:</Styled.Label>
          <Styled.Value>{order_type}</Styled.Value>
        </Styled.Row>
        {/* <Styled.ButtonContainer>
                <Styled.StyledButton variant="contained">
                    Cancel Order
                </Styled.StyledButton>
                <Styled.StyledButton variant="contained">
                    Refund Order
                </Styled.StyledButton>
            </Styled.ButtonContainer> */}
      </Styled.DrawerContent>
    </Drawer>
  )
}

export default OrderDetailsDrawer
