import { useEffect, useRef, useCallback } from 'react'

/**
 * Helper hook to determine if a component is mounted.
 */
const useMountedState = () => {
  const mountedRef = useRef(false)
  /**
   * A function that returns `true` if the component is mounted.
   */
  const isMounted = useCallback(() => mountedRef.current, [])

  useEffect(() => {
    mountedRef.current = true

    return () => {
      mountedRef.current = false
    }
  }, [])

  return isMounted
}

export default useMountedState;