import { Close as CloseIcon, Image as ImageIcon, Store as StoreIcon } from '@mui/icons-material'
import { Avatar, Button, CardActions } from '@mui/material'
import _ from 'lodash'
import React from 'react'
import { CampaignAd } from 'types/digitalMarketing'
import { getCTAOptions } from 'utils/dmUtils'

import {
  BlurredImage,
  ButtonLabel,
  ChevronIcon,
  CloseButton,
  ContentContainer,
  ContentText,
  HeaderRoot,
  HeaderSubTitle,
  HeaderTitle,
  MainImage,
  ProgressBar,
  StyledArrowUpIcon,
  StyledCard,
  StyledCardContent,
} from './styles'

const getFirstImageCard = (adCards: Array<any>) =>
  _.find(adCards, (card) => !card.type || card.type === 'image')

interface InstagramMobileStoryProps {
  profileImageUrl: string
  handle: string
  firstAd: CampaignAd
}

const InstagramMobileStory: React.FC<InstagramMobileStoryProps> = ({
  profileImageUrl,
  handle,
  firstAd,
}) => {
  const { mainMessage, adCards = [] } = firstAd.ad
  const firstImageCard = getFirstImageCard(adCards)
  const imageSrc = firstImageCard?.image?.src
  const concatenatedText = [firstImageCard?.imageDescription, mainMessage]
    .filter((text) => text?.trim())
    .join('\r\n')
  const contentText = _.truncate(concatenatedText, { length: 168, omission: '... more' })
  const adCtaOptions = _.find(getCTAOptions(), { value: firstAd.ad.callToAction })
  const adCtaText = adCtaOptions && adCtaOptions.value !== 'NO_BUTTON' ? adCtaOptions.text : ''
  return (
    <StyledCard variant='outlined'>
      {imageSrc && <BlurredImage src={imageSrc} alt='Blurred Image' />}
      <ProgressBar />
      <HeaderRoot
        avatar={
          <Avatar src={profileImageUrl} alt='Avatar Image'>
            <StoreIcon />
          </Avatar>
        }
        title={<HeaderTitle>{handle}</HeaderTitle>}
        subheader={
          <HeaderSubTitle>
            {'Sponsored'}
            <ChevronIcon>►</ChevronIcon>
            {'Expand Story'}
          </HeaderSubTitle>
        }
        action={
          <CloseButton>
            <CloseIcon style={{ color: '#a0a4a7', fontSize: 22 }} />
          </CloseButton>
        }
      />
      {imageSrc ? (
        <MainImage src={imageSrc} alt='Main Image' />
      ) : (
        <ImageIcon
          style={{
            fontSize: 64,
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            fill: 'white',
          }}
        />
      )}
      <StyledCardContent>
        {contentText && (
          <ContentContainer>
            <ContentText>{contentText}</ContentText>
          </ContentContainer>
        )}
        <CardActions>
          <Button>
            <ButtonLabel>
              <StyledArrowUpIcon />
              {adCtaText || 'Learn More'}
            </ButtonLabel>
          </Button>
        </CardActions>
      </StyledCardContent>
    </StyledCard>
  )
}

export default InstagramMobileStory
