import React from 'react'
import { Select } from 'components'
import { SingleSelectProps } from 'components/Select/SingleSelect'


const StyledSelect = (props: SingleSelectProps) => {
  return (
    <Select
      styles={{
        container: {
          height: 24,
          fontSize: 10,
        },
        control: {
          height: 24,
          width: 200,
          maxWidth: 200,
          minHeight: 30,
          background: '#FEFEFE',
        },
        menu: {
          width: '100%',
        },
        menuList: {
          paddingTop: 0,
          paddingBottom: 0,
        },
        singleValue: {
          fontSize: 10,
          fontWeight: 400,
        },
        placeholder: {
          fontSize: 10,
          fontWeight: 400,
        },
        option: {
          fontSize: 10,
          fontWeight: 400,
        },
      }}
      {...props}
    />
  )
}

export default StyledSelect