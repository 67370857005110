import { useCallback } from 'react';
import useMountedState from './useMountedState';

/**
 * Helper hook to safely execute an async function. It will only resolve if 
 * the component is still mounted. Example:
 * 
 * ```tsx
 * const safeAsync = useSafeAsync();
 * safeAsync(fetchData()).then(data => setData(data));
 * ```
 */
const useSafeAsync = () => {
  const isMounted = useMountedState()
  const safeAsync = useCallback(<T=any>(p: Promise<T>) => {
    return new Promise<T>((resolve, reject) => {
      p.then((value) => {
        if (isMounted()) {
          resolve(value)
        }
      }).catch((err) => {
        if (isMounted()) {
          reject(err)
        }
      })
    })
  }, [isMounted])

  return safeAsync
}

export default useSafeAsync;