import { InsightCampaignUpdateTypes, TargetableInsight } from "types/digitalMarketing";
import { LoadingSkeleton } from 'components'
import { 
  KeywordsIcon, 
  GooglePaidLocationIcon, 
  GooglePaidPromotionUpdateIcon, 
  MetaPaidAdBudgetIcon, 
  NewCampaignIcon,
  GoogleHeadlineAndDescriptionIcon,
  GoogleEndDateIcon,
} from './icons';
import { ActionsButtons, CampaignDescription, CampaignTitle, SuggestedAdContainer, TableCell, TableRow, TitleContainer } from "./styles";
import GreenButton from "components/modern/GreenButton";
import RedButton from "components/modern/RedButton";
import { ToDoItem } from "types/digitalMarketing/common";
import { 
  isMetaAd, 
  isGoogleAd, 
  withTodoAsMetaAd, 
  withTodoAsGoogleAd, 
  withTodoAsTargetableInsight 
} from "utils/dmUtils";

export const Skeleton = () => {
  return (
    <>
      {Array.from({ length: 5 }).map((_, index) => (
        <TableRow key={index} data-testid="skeleton-row">
          <TableCell fullWidth>
            <LoadingSkeleton lineCount={2} />
          </TableCell>
        </TableRow>
      ))}
    </>
  )
}

export const NoData = () => {
  return (
    <TableRow>
      <TableCell centered fullWidth>
        No data
      </TableCell>
    </TableRow>
  )
}

/**
 * ToDo table Row
 */
export const CampaignItem = (props: { 
  item: ToDoItem;
  onAction: (item: ToDoItem, approved: boolean) => void;
  onClick: (item: ToDoItem) => void;
}) => {
  const { item, onAction, onClick } = props;
  const icon = getToDoIcon(item);
  const title = getToDoTitle(item);
  const description = getToDoContentType(item);

  const handleApproval = () => {
    onAction(item, true);
  }

  const handleRejection = () => {
    onAction(item, false);
  }

  return (
    <TableRow>
      <TableCell fullWidth>
        <SuggestedAdContainer onClick={() => onClick(item)}>
          {icon}
          <TitleContainer>
            <CampaignTitle>
              {title}
            </CampaignTitle>
            <CampaignDescription>
              {description}
            </CampaignDescription>
          </TitleContainer>
        </SuggestedAdContainer>
      </TableCell>
      <TableCell pushRight>
        <ActionsButtons data-type="actions-buttons">
          <GreenButton onClick={handleApproval}>Approve</GreenButton>
          <RedButton variant="text" onClick={handleRejection}>Reject</RedButton>
        </ActionsButtons>
      </TableCell>
    </TableRow>
  )
}

/**
 * @returns the icon of the ToDo item based on the type of ad
 * @param item - a google, facebook or targetable insight ad
 */
export const getToDoIcon = (item?: ToDoItem | null) => {
  if(isMetaAd(item) || isGoogleAd(item)) {
    return <NewCampaignIcon />
  }

  const updateType = (item as TargetableInsight).campaignUpdate?.updateType;
  switch(updateType) {
    case InsightCampaignUpdateTypes.Keywords:
      return <KeywordsIcon />;
    case InsightCampaignUpdateTypes.Budget:
      return <MetaPaidAdBudgetIcon />;
    case InsightCampaignUpdateTypes.Locations:
      return <GooglePaidLocationIcon />;
    case InsightCampaignUpdateTypes.Promotions:
      return <GooglePaidPromotionUpdateIcon />;
    case InsightCampaignUpdateTypes.EndDate:
        return <GoogleEndDateIcon />;
    case InsightCampaignUpdateTypes.HeadlinesAndDescriptions:
      return <GoogleHeadlineAndDescriptionIcon />;
    default:
      return <span>???</span>;
  }
}

/**
 * @returns the title of the ToDo item based on the type of ad
 * @param item - a google, facebook or targetable insight ad
 */
export const getToDoTitle = (item?: ToDoItem | null) => {
  if(!item) return '';
  
  const fbItem = withTodoAsMetaAd(item);
  const gAd = withTodoAsGoogleAd(item);
  const tItem = withTodoAsTargetableInsight(item);
  
  return fbItem?.name || gAd?.name || tItem?.title || 'Unknown Ad Type';
}

/**
 * @returns the content type of the ToDo item based on the type of ad
 * @param item - a google, facebook or targetable insight ad
 */
export const getToDoContentType = (item: ToDoItem) => {
  if(isMetaAd(item)) {
    return `Meta Paid Ads: New Campaign`
  }

  if(isGoogleAd(item)) {
    return 'Google Paid Search Ads: New Campaign'
  }

  const updateType = (item as TargetableInsight).campaignUpdate?.updateType;
  switch(updateType) {
    case InsightCampaignUpdateTypes.Keywords:
      return 'Google Paid Search Ads: Keyword Targeting Update';
    case InsightCampaignUpdateTypes.Budget:
      return 'Google Paid Search Ads: Budget Update';
    case InsightCampaignUpdateTypes.Locations:
      return 'Google Paid Search Ads: Location Targeting Update';
    case InsightCampaignUpdateTypes.Promotions:
      return 'Google Paid Search Ads: Promotion Update';
    case InsightCampaignUpdateTypes.EndDate:
      return 'Google Paid Search Ads: End Date Update';
    case InsightCampaignUpdateTypes.HeadlinesAndDescriptions:
      return 'Google Paid Search Ads: Headline and Description Update';
    default:
      return 'Unknown Update Type';
  }
}
