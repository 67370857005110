import { FormattedMenuSectionInfo } from 'api'
import TRASH_ICON from 'assets/icons/trash-icon.svg'
import { Button } from 'components'
import { useIsMobile } from 'hooks'
import { toast } from 'react-toastify'

import { ModifierValuesMap } from '../MenuSectionEditor'
import * as Styled from '../styles'
import { validateInput } from './EditableModifierGroupForm'
import { createDefaultModInfo } from './index'

type Props = {
  nestedModifierGroupDetails: FormattedMenuSectionInfo
  setNestedModifierGroupDetails: (newDetails: FormattedMenuSectionInfo) => void
  nestedModifiers: ModifierValuesMap
  setNestedModifiers: React.Dispatch<React.SetStateAction<ModifierValuesMap>>
  removeNestedGroupAndMods: (group: FormattedMenuSectionInfo) => void
}

/**
 * Filters our modifier values that belong to this group only
 * @param groupId - the nested group id (combined id with {parentModId:groupId})
 * @param modifierValues
 */
export const getNestedGroupModifierValues = (
  groupId: string,
  modifierValues: ModifierValuesMap,
) => {
  const nestedModsForGroup = Object.entries(modifierValues)?.filter(
    ([_id, values]) => values.parentGroupId === groupId,
  )
  return Object.fromEntries(nestedModsForGroup)
}

const NestedGroupAndMods = ({
  nestedModifierGroupDetails,
  setNestedModifierGroupDetails,
  nestedModifiers,
  setNestedModifiers,
  removeNestedGroupAndMods,
}: Props) => {
  const { isMobile } = useIsMobile()

  const nestedModifiersForGroup = getNestedGroupModifierValues(
    nestedModifierGroupDetails.id,
    nestedModifiers,
  )

  const removeNestedGroupModifier = (nestedModId: string) => {
    const minCount = nestedModifierGroupDetails?.attributes.min_permitted

    if (
      minCount === undefined ||
      (minCount !== undefined && minCount <= Object.keys(nestedModifiersForGroup).length - 1)
    ) {
      const updatedNestedMods = { ...nestedModifiers }
      delete updatedNestedMods[nestedModId]

      setNestedModifiers(updatedNestedMods)
    }

    if (minCount !== undefined && minCount > Object.keys(nestedModifiers).length - 1) {
      toast.error(
        'The modifier group minimum count is set. Change or remove the minimum count to delete this modifier.',
        { autoClose: 5000 },
      )
    }
  }

  const createNestedGroupModifier = (nestedModGroup: FormattedMenuSectionInfo) => {
    const newNestedModValues = createDefaultModInfo({ group: nestedModGroup, isNested: true })

    const updatedNestedModValues = {
      ...nestedModifiers,
      ...newNestedModValues,
    }

    setNestedModifiers(updatedNestedModValues)
  }

  return (
    <div style={{ border: '1px solid #1B51A4', borderRadius: 12, padding: '24px' }}>
      <Styled.ItemViewProductTitle>Nested Modifier Group Name</Styled.ItemViewProductTitle>
      <div style={{ marginTop: 8, marginBottom: 16 }}>
        <Styled.Input
          height='47px'
          value={nestedModifierGroupDetails.attributes.title}
          onChange={(e) => {
            setNestedModifierGroupDetails({
              ...nestedModifierGroupDetails,
              attributes: {
                ...nestedModifierGroupDetails.attributes,
                title: e.target.value,
              },
            })
          }}
          required
        />
      </div>
      <div style={{ display: 'flex', gap: 12 }}>
        <div style={{ display: 'flex', flexDirection: 'column', gap: 8 }}>
          <Styled.ItemViewProductTitle>Minimum Selection</Styled.ItemViewProductTitle>
          <Styled.Input
            height='47px'
            width={isMobile ? '100%' : '170px'}
            type='number'
            min='0'
            value={nestedModifierGroupDetails.attributes.min_permitted}
            onInput={(e: React.ChangeEvent<HTMLInputElement>) => {
              const newValue = validateInput(e.target.value)
              let maxPermitted = nestedModifierGroupDetails?.attributes?.max_permitted

              // validation to ensure max value is never less than min value
              if (maxPermitted !== undefined && (newValue || 0) > maxPermitted) {
                maxPermitted = newValue
              }

              if (maxPermitted === undefined) {
                maxPermitted = newValue
              }

              setNestedModifierGroupDetails({
                ...nestedModifierGroupDetails,
                attributes: {
                  ...nestedModifierGroupDetails.attributes,
                  min_permitted: newValue,
                  max_permitted: maxPermitted,
                },
              })
            }}
            onChange={(e) => {
              const newValue = validateInput(e.target.value)
              let maxPermitted = nestedModifierGroupDetails?.attributes?.max_permitted

              // validation to ensure max value is never less than min value
              if (maxPermitted !== undefined && (newValue || 0) > maxPermitted) {
                maxPermitted = newValue
              }

              if (maxPermitted === undefined) {
                maxPermitted = newValue
              }

              setNestedModifierGroupDetails({
                ...nestedModifierGroupDetails,
                attributes: {
                  ...nestedModifierGroupDetails.attributes,
                  min_permitted: parseInt(e.target.value),
                  max_permitted: maxPermitted,
                },
              })
            }}
          />
        </div>
        <div style={{ display: 'flex', flexDirection: 'column', gap: 8 }}>
          <Styled.ItemViewProductTitle>Maximum Selection</Styled.ItemViewProductTitle>
          <Styled.Input
            height='47px'
            width={isMobile ? '100%' : '170px'}
            type='number'
            min='0'
            value={nestedModifierGroupDetails?.attributes.max_permitted}
            onInput={(e: React.ChangeEvent<HTMLInputElement>) => {
              const newValue = validateInput(e.target.value)
              let minPermitted = nestedModifierGroupDetails?.attributes.min_permitted

              // validate to ensure min permitted is never more than max permitted
              if (minPermitted !== undefined && (newValue || 0) < minPermitted) {
                minPermitted = newValue
              }

              setNestedModifierGroupDetails({
                ...nestedModifierGroupDetails,
                attributes: {
                  ...nestedModifierGroupDetails.attributes,
                  max_permitted: newValue,
                  min_permitted: minPermitted,
                },
              })
            }}
            onChange={(e) => {
              const newValue = validateInput(e.target.value)
              let minPermitted = nestedModifierGroupDetails?.attributes.min_permitted

              // validate to ensure min permitted is never more than max permitted
              if (minPermitted !== undefined && (newValue || 0) < minPermitted) {
                minPermitted = newValue
              }

              setNestedModifierGroupDetails({
                ...nestedModifierGroupDetails,
                attributes: {
                  ...nestedModifierGroupDetails.attributes,
                  max_permitted: newValue,
                  min_permitted: minPermitted,
                },
              })
            }}
          />
        </div>
      </div>
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          gap: 24,
          marginTop: '24px',
        }}
      >
        <Styled.ItemViewProductTitle>Nested Modifiers</Styled.ItemViewProductTitle>
        <div style={{ display: 'flex', flexDirection: 'column', flex: 1 }}>
          {Object.entries(nestedModifiersForGroup).map(([modId, values]) => (
            <div style={{ display: 'flex', flexDirection: 'column' }} key={modId}>
              <div
                style={{
                  marginTop: 8,
                  marginBottom: 16,
                  display: 'flex',
                  alignItems: 'flex-end',
                  gap: 12,
                }}
              >
                <div style={{ display: 'flex', flexDirection: 'column', gap: 8 }}>
                  <Styled.ItemViewProductTitle>Title</Styled.ItemViewProductTitle>
                  <Styled.Input
                    height='47px'
                    value={values.title}
                    onChange={(e) => {
                      const changedModValues = {
                        ...nestedModifiers,
                        [modId]: {
                          ...nestedModifiers[modId],
                          title: e.target.value,
                        },
                      }

                      setNestedModifiers(changedModValues)
                    }}
                    required
                  />
                </div>
                <div style={{ display: 'flex', flexDirection: 'column', gap: 8 }}>
                  <Styled.ItemViewProductTitle>Price</Styled.ItemViewProductTitle>
                  <div style={{ display: 'flex', alignItems: 'center' }}>
                    <Styled.Input
                      height='47px'
                      value={values.price}
                      type='number'
                      step='0.01'
                      pattern='[0-9]*'
                      min='0'
                      onChange={(e) => {
                        const changedModValues = {
                          ...nestedModifiers,
                          [modId]: {
                            ...nestedModifiers[modId],
                            price: e.target.value ? parseFloat(e.target.value) : undefined,
                          },
                        }
                        setNestedModifiers(changedModValues)
                      }}
                    />

                    <Styled.IconButton
                      margin='0 10px 0 10px'
                      onClick={() => removeNestedGroupModifier(modId)}
                    >
                      <img
                        src={TRASH_ICON}
                        width='16px'
                        alt='delete'
                        style={{ maxWidth: '16px' }}
                      />
                    </Styled.IconButton>
                  </div>
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
      <Button
        size='small'
        narrow
        type='button'
        variant='textonly'
        onClick={(e) => {
          e.preventDefault()
          createNestedGroupModifier(nestedModifierGroupDetails)
        }}
      >
        <u>Add Nested Modifier</u>
      </Button>
      <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
        <Button
          size='small'
          narrow
          type='button'
          variant='textonly'
          onClick={(e) => {
            e.preventDefault()
            removeNestedGroupAndMods(nestedModifierGroupDetails)
          }}
        >
          <u>Remove Nested Group</u>
        </Button>
      </div>
    </div>
  )
}

export default NestedGroupAndMods
