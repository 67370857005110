import { UserFeedbackSentiment } from "types/digitalMarketing/googleAd"
import { QuestionContainer, QuestionText, SentimentAction, SentimentContainer } from "./styles"
import { LoadingSkeleton } from 'components'
import { ReactComponent as Dissatisfied } from 'assets/icons/feedback-sentiment-dissatisfied.svg'
import { ReactComponent as Neutral } from 'assets/icons/feedback-sentiment-neutral.svg'
import { ReactComponent as Satisfied } from 'assets/icons/feedback-sentiment-satisfied.svg'

type Props = {
  question: string;
  loadingQuestion: boolean;
  sentiment?: UserFeedbackSentiment;
  onChange: (question: string, sentiment: UserFeedbackSentiment) => void;
}

const FeedbackQuestion = (props: Props) => {
  const { question, loadingQuestion, onChange, sentiment } = props;

  const handleSentimentChange = (sentiment: UserFeedbackSentiment) => {
    if(question){
      onChange(question, sentiment);
    }
  }

  return (
    <QuestionContainer>
      <QuestionText>
        {loadingQuestion ? 
          <LoadingSkeleton lineCount={1}/> : 
          <>
            {question}<span>*</span>
          </>
        }
      </QuestionText>
      {loadingQuestion ? <LoadingSkeleton lineCount={2}/> : (
        <SentimentContainer>
          <SentimentAction 
            data-testid='sentiment-action-dissatisfied'
            selected={sentiment === 'dissatisfied'} 
            onClick={() => handleSentimentChange('dissatisfied')}
          >
            <Dissatisfied />
          </SentimentAction>
          <SentimentAction 
            data-testid='sentiment-action-neutral'
            selected={sentiment === 'neutral'}
            onClick={() => handleSentimentChange('neutral')}
          >
            <Neutral />
          </SentimentAction>
          <SentimentAction 
            data-testid='sentiment-action-satisfied'
            selected={sentiment === 'satisfied'}
            onClick={() => handleSentimentChange('satisfied')}
          >
            <Satisfied />
          </SentimentAction>
        </SentimentContainer>
      )}
    </QuestionContainer>
  )
}

export default FeedbackQuestion;