export const targetableApi = {
  questionnaireByIdRoute: (id: string) => `targetable/api/v1/questionnaire/${id}`,
  submitInsightFeedbackRoute: (id: string) => `targetable/api/v1/insights/${id}/feedback`,
  updateInsightRoute: (id: string) => `targetable/api/v1/insights/${id}`,
  submitGoogleAdFeedbackRoute: (id: string) => `targetable/api/v2/ad/${id}/feedback`,
  publishGoogleAdRoute: (id: string) => `targetable/api/v2/ad/${id}/publish`,
  updateSuggestedCampaignRoute: (id: string) => `targetable/api/v1/campaigns/suggested/${id}`,
  getSuggestedCampaignRoute: (id: string) => `targetable/api/v1/campaigns/suggested/${id}`,
  createMetaCampaignRoute: () => `targetable/api/v1/campaigns/new`,
  publishMetaCampaignRoute: (id: string) => `targetable/api/v1/campaigns/${id}/submit`,
  getGoogleSearchCampaignAssetsRoute: (marketingChannelId: string, assetType: string) => `targetable/api/v1/marketing/google/campaign/assets/${assetType}?marketingChannelId=${marketingChannelId}`,
  pauseOrResumeGoogleAdRoute: (id: string, pause: boolean) => `targetable/api/v2/ad/${id}/status?paused=${pause}`,
  pauseOrResumeMetaAdRoute: (id: string) => `targetable/api/v1/campaigns/${id}/pause-or-unpause`,
}