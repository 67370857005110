import { FormattedMenuSectionInfo } from 'api'
import { createNewMenuMappingSubsection } from 'api/menuMapping/subsections'
import { Button } from 'components'
import { useEffect, useState } from 'react'
import { toast } from 'react-toastify'

import MenuEditBottomActionBar from './MenuEditBottomActionBar'
import MenuSubsectionEditor from './MenuSubsectionEditor'
import * as Styled from './styles'
import { updateItemsAndCategories } from './util/updateItemsAndCategories'

type Props = {
  combinedMenuSectionData?: FormattedMenuSectionInfo
  isLoading: boolean
  expandAllModifiers: boolean
  refreshMenuSection: () => void
}

// The price is stored on the join table, thus we need to pass in both subsection and item ids
export type ItemPriceMap = {
  [itemAndSubsectionId: string]: { price: number | undefined }
}

// same as items, prices are stored on a join table and we need both ids. Prices are also unique based on this id combination.
export type ModifierValuesMap = {
  [modId: string]: { price?: number; title: string; parentGroupId: string; isNested: boolean }
}

export type CategoryNamesMap = {
  [categoryId: string]: string
}

// Since prices of modifier and nested modifiers are editable in this view, we need to keep track of all the original prices and compare to changed values.
// This recursive function helps us get all the prices of nested modifiers that can go two levels deep. All original modifier values are stored by id on the modifierPrices state object (nested and not nested)
export const getNestedCustomizationOptionAttributes = ({
  customization,
  isNested,
}: {
  customization: FormattedMenuSectionInfo
  isNested: boolean
}): ModifierValuesMap => {
  let modValuesMap: ModifierValuesMap = {}

  // If customization_options exist, extract price and id
  if (customization.children.customization_options) {
    customization.children.customization_options.forEach((option) => {
      modValuesMap[option.id] = {
        price: option.attributes.price,
        title: option.attributes.title || '',
        parentGroupId: customization.id,
        isNested: isNested,
      }

      // Recursively check nested customizations
      if (option.children.customizations) {
        option.children.customizations.forEach((customization) => {
          const nestedValues = getNestedCustomizationOptionAttributes({
            customization,
            isNested: true,
          })
          modValuesMap = { ...modValuesMap, ...nestedValues }
        })
      }
    })
  }

  return modValuesMap
}

const MenuSectionEditor = ({
  combinedMenuSectionData,
  isLoading,
  expandAllModifiers,
  refreshMenuSection,
}: Props) => {
  const [formattedMenuSection, setFormattedMenuSection] = useState<FormattedMenuSectionInfo>()
  // stages subsection names
  const [originalCategoryNames, setOriginalCategoryNames] = useState<CategoryNamesMap>({})
  const [categoryNames, setCategoryNames] = useState<CategoryNamesMap>({})
  const [originalItemPrices, setOriginalItemPrices] = useState<ItemPriceMap>({})
  const [itemPrices, setItemPrices] = useState<ItemPriceMap>({})
  const [originalModifierValues, setOriginalModifierValues] = useState<ModifierValuesMap>({})
  const [modifierValues, setModifierValues] = useState<ModifierValuesMap>({})
  const [isMenuEdited, setIsMenuEdited] = useState<boolean>(false)
  const [openNewCategoryInput, setOpenNewCategoryInput] = useState<boolean>(false)
  const [newCategoryName, setNewCategoryName] = useState<string>('')

  const setDefaultCategories = () => {
    // set default category names
    const subsectionNamesMap: { [id: string]: string } = {}
    combinedMenuSectionData?.children.subsections?.forEach((subsection) => {
      subsectionNamesMap[subsection.id] = subsection.attributes.title || ''
    })
    // original names will be unchanged so we can easily compare the diff later
    setOriginalCategoryNames(subsectionNamesMap)
    setCategoryNames(subsectionNamesMap)
  }

  const setDefaultItemAndModPrices = () => {
    const itemPricesMap: ItemPriceMap = {}
    let modValuesMap: ModifierValuesMap = {}

    combinedMenuSectionData?.children.subsections?.forEach(({ id, children }) =>
      children.items?.forEach((item) => {
        const itemAndSubsectionIds = `${item.id}:${id}`
        itemPricesMap[itemAndSubsectionIds] = {
          price: item.attributes.price,
        }

        item.children.customizations?.forEach((modGroup) => {
          // get all nested modifier prices and store them on the same state object
          const nestedCustomizationValues: ModifierValuesMap =
            getNestedCustomizationOptionAttributes({ customization: modGroup, isNested: false })

          modValuesMap = { ...modValuesMap, ...nestedCustomizationValues }
        })
      }),
    )
    setOriginalItemPrices(itemPricesMap)
    setItemPrices(itemPricesMap)
    setOriginalModifierValues(modValuesMap)
    setModifierValues(modValuesMap)
  }

  useEffect(() => {
    if (combinedMenuSectionData) {
      setFormattedMenuSection(combinedMenuSectionData)
      // prefill categories
      setDefaultCategories()
      setDefaultItemAndModPrices()
    }
  }, [combinedMenuSectionData])

  // tracks changes in the menu
  useEffect(() => {
    const { categoriesToUpdate, itemPricesToUpdate, modifierPricesToUpdate } = getValuesDifference()

    if (
      Object.entries(categoriesToUpdate).length ||
      Object.entries(itemPricesToUpdate).length ||
      Object.entries(modifierPricesToUpdate).length
    ) {
      setIsMenuEdited(true)
    } else {
      setIsMenuEdited(false)
    }
  }, [categoryNames, itemPrices, modifierValues])

  // reset menu back to defaults
  const unstageEdits = () => {
    setCategoryNames(originalCategoryNames)
    setItemPrices(originalItemPrices)
  }

  // deep comparison between existing and new category names and item prices
  const getValuesDifference = () => {
    const categoriesToUpdate: CategoryNamesMap = {}
    const itemPricesToUpdate: ItemPriceMap = {}
    const modifierPricesToUpdate: ModifierValuesMap = {}

    Object.entries(categoryNames).forEach(([id, name]) => {
      if (name !== originalCategoryNames[id]) {
        categoriesToUpdate[id] = name
      }
    })

    Object.entries(itemPrices).forEach(([id, priceObj]) => {
      const { price: newPrice } = priceObj
      if (newPrice !== originalItemPrices[id]?.price || !originalItemPrices[id]) {
        itemPricesToUpdate[id] = itemPrices[id]
      }
    })

    Object.entries(modifierValues).forEach(([id, values]) => {
      const { price } = values
      if (price !== originalModifierValues[id].price) {
        modifierPricesToUpdate[id] = values
      }
    })

    return { categoriesToUpdate, itemPricesToUpdate, modifierPricesToUpdate }
  }

  const saveMenuEdits = async () => {
    // get diff between original and new
    const { categoriesToUpdate, itemPricesToUpdate, modifierPricesToUpdate } = getValuesDifference()

    try {
      const { updatedPricesResult, updatedCategoriesResult, updateModifiersResult } =
        await updateItemsAndCategories({
          itemsToUpdate: itemPricesToUpdate,
          categoriesToUpdate: categoriesToUpdate,
          modifiersToUpdate: modifierPricesToUpdate,
        })

      // TODO: add more detailed success message
      if (updatedPricesResult || updatedCategoriesResult || updateModifiersResult) {
        toast.success('Your menu was updated successfully.', { autoClose: 5000 })
        refreshMenuSection()
      }
    } catch (err) {
      console.error('Error updating item prices and category titles', err)
      toast.error(`Error updating menu. Please try again: ${err}`, { autoClose: 5000 })
    }
  }

  const createNewCategory = async () => {
    if (!newCategoryName) {
      toast.error('The category name is required for creating a category.', { autoClose: 5000 })
      return
    }

    if (combinedMenuSectionData) {
      try {
        const { data } = await createNewMenuMappingSubsection(
          { title: newCategoryName },
          combinedMenuSectionData.id,
        )

        if (data) {
          toast.success(`New category ${newCategoryName} was successfully created.`, {
            autoClose: 5000,
          })
          setOpenNewCategoryInput(false)
          refreshMenuSection()
        }
      } catch (err) {
        console.error('Error creating category', err)
        toast.error('Error creating new category. Please try again.', { autoClose: 5000 })
      }
    }
  }

  return (
    <div>
      <Styled.HeaderRow>
        <div style={{ display: 'flex', flex: 1 }}>
          {openNewCategoryInput ? (
            <>
              <Styled.Input
                height='unset'
                // ref={inputRef}
                // defaultValue={categoryName}
                placeholder='New category title...'
                value={newCategoryName}
                width='200px'
                onChange={(e) => setNewCategoryName(e.target.value)}
              />
              <Button
                size='small'
                narrow
                variant='textonly'
                onClick={() => {
                  setNewCategoryName('')
                  setOpenNewCategoryInput(false)
                }}
              >
                Cancel
              </Button>
              <Button size='small' narrow onClick={createNewCategory}>
                Create
              </Button>
            </>
          ) : (
            <Button size='small' narrow onClick={() => setOpenNewCategoryInput(true)}>
              Add Menu Category
            </Button>
          )}
        </div>
      </Styled.HeaderRow>

      {formattedMenuSection?.children?.subsections?.map((subsection) => (
        <MenuSubsectionEditor
          subsection={subsection}
          key={subsection.id}
          isLoading={isLoading}
          expandAllModifiers={expandAllModifiers}
          categoryName={categoryNames[subsection.id]}
          setCategoryName={(id: string, value: string) =>
            setCategoryNames({ ...categoryNames, ...{ [id]: value } })
          }
          itemPrices={itemPrices}
          setItemPrices={(itemSubsectionId: string, newPrice?: number) =>
            setItemPrices({
              ...itemPrices,
              [itemSubsectionId]: {
                ...itemPrices[itemSubsectionId],
                price: newPrice,
              },
            })
          }
          refreshMenuSection={refreshMenuSection}
          modifierValues={modifierValues}
          onChangeModifierValues={({ modId, price, title }) =>
            setModifierValues({
              ...modifierValues,
              [modId]: { ...modifierValues[modId], price: price, title: title || '' },
            })
          }
        />
      ))}
      {/* Show bottom bar only if changes were made */}
      {isMenuEdited ? (
        <MenuEditBottomActionBar onCancel={unstageEdits} onSave={saveMenuEdits} />
      ) : null}
    </div>
  )
}

export default MenuSectionEditor
