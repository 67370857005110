import { AdRequestInfo } from "./common";
import { InsightFeedback } from "./insights";

export type AdCallToAction =
  | 'OPEN_LINK'
  | 'LIKE_PAGE'
  | 'SHOP_NOW'
  | 'PLAY_GAME'
  | 'INSTALL_APP'
  | 'USE_APP'
  | 'CALL'
  | 'CALL_ME'
  | 'VIDEO_CALL'
  | 'INSTALL_MOBILE_APP'
  | 'USE_MOBILE_APP'
  | 'MOBILE_DOWNLOAD'
  | 'BOOK_TRAVEL'
  | 'LISTEN_MUSIC'
  | 'WATCH_VIDEO'
  | 'LEARN_MORE'
  | 'SIGN_UP'
  | 'DOWNLOAD'
  | 'WATCH_MORE'
  | 'VISIT_PAGES_FEED'
  | 'CALL_NOW'
  | 'APPLY_NOW'
  | 'CONTACT'
  | 'BUY_NOW'
  | 'GET_OFFER'
  | 'GET_OFFER_VIEW'
  | 'BUY_TICKETS'
  | 'UPDATE_APP'
  | 'GET_DIRECTIONS'
  | 'BUY'
  | 'SEND_UPDATES'
  | 'MESSAGE_PAGE'
  | 'DONATE'
  | 'SUBSCRIBE'
  | 'SAY_THANKS'
  | 'SELL_NOW'
  | 'SHARE'
  | 'DONATE_NOW'
  | 'GET_QUOTE'
  | 'CONTACT_US'
  | 'ORDER_NOW'
  | 'START_ORDER'
  | 'ADD_TO_CART'
  | 'VIDEO_ANNOTATION'
  | 'RECORD_NOW'
  | 'INQUIRE_NOW'
  | 'CONFIRM'
  | 'REFER_FRIENDS'
  | 'REQUEST_TIME'
  | 'GET_SHOWTIMES'
  | 'LISTEN_NOW'
  | 'WOODHENGE_SUPPORT'
  | 'SOTTO_SUBSCRIBE'
  | 'FOLLOW_USER'
  | 'RAISE_MONEY'
  | 'EVENT_RSVP'
  | 'WHATSAPP_MESSAGE'
  | 'FOLLOW_NEWS_STORYLINE'
  | 'SEE_MORE'
  | 'BOOK_NOW'
  | 'FIND_A_GROUP'
  | 'FIND_YOUR_GROUPS'
  | 'PAY_TO_ACCESS'
  | 'PURCHASE_GIFT_CARDS'
  | 'FOLLOW_PAGE'
  | 'SEND_A_GIFT'
  | 'SWIPE_UP_SHOP'
  | 'SWIPE_UP_PRODUCT'
  | 'SEND_GIFT_MONEY'
  | 'PLAY_GAME_ON_FACEBOOK'
  | 'GET_STARTED'
  | 'OPEN_INSTANT_APP'
  | 'AUDIO_CALL'
  | 'GET_PROMOTIONS'
  | 'JOIN_CHANNEL'
  | 'MAKE_AN_APPOINTMENT'
  | 'ASK_ABOUT_SERVICES'
  | 'BOOK_A_CONSULTATION'
  | 'GET_A_QUOTE'
  | 'BUY_VIA_MESSAGE'
  | 'ASK_FOR_MORE_INFO'
  | 'CHAT_WITH_US'
  | 'VIEW_PRODUCT'
  | 'NO_BUTTON';

export type FacebookSuggestedCampaignStatus =
  | 'draft'
  | 'review'
  | 'published'
  | 'rejected'
  | 'approved'
  | 'saved';

export type MetaCampaignStatus =
  | 'draft'
  | 'submitted'
  | 'failed'
  | 'with-issues'
  | 'live'
  | 'paused'
  | 'pre-approved'
  | 'in-process'
  | 'deleted'
  | 'archived'
  | 'review'
  | 'stale';

export type AdPlacement = {
  facebook: boolean;
  instagram: boolean;
}

export const enum AdTargetingTypes {
  Zips = 'zips',
  Radius = 'radius',
  Countries = 'countries',
  Cities = 'cities',
  Regions = 'regions',
  GeoMarkets = 'geoMarkets',
}

export type AdTargeting = {
  customAudience?: {
    id: string;
    name: string;
  };
  minAge?: number;
  maxAge?: number;
  gender?: 'all' | 'men' | 'women';
  radius?: number;
  languages?: {
    id: string;
    value: string;
  }[];
  specificTargets?: {
    id: string;
    reach_lower: number;
    reach_upper: number;
    value: string;
    type: string;
  }[];
  type?: 'zips' | 'radius' | 'countries' | 'cities' | 'regions' | 'geoMarkets';
  zips?: {
    key: string;
    name: string;
    city: string;
  }[];
  countries?: {
    key: string;
    name: string;
  }[];
  cities?: {
    key: string;
    name: string;
  }[];
  regions?: {
    key: string;
    name: string;
  }[];
  geoMarkets?: {
    key: string;
    name: string;
  }[];
}

export type AdImage = {
  filename: string;
  publicId: string;
  src: string;
}

export type AdVideo = {
  filename?: string;
  publicId?: string;
  src?: string;
}

export type FacebookVideo = {
  videoId: { type: 'string' },
  /** the thumbnail in Cloudinary  */
  thumbnailUrl: { type: 'string' },
  name: { type: 'string' },
}

export const enum AdCardTypes {
  Image = 'image',
  Map = 'map',
  Video = 'video'
}

export type AdCard = {
  id?: string;
  image?: AdImage;
  imageDescription?: string;
  link?: string;
  subMessage?: string;
  type: 'image' | 'map' | 'video';
  video?: AdVideo;
  urlPresetId?: string;
}

export type CampaignAdInfoType = 'carousel' | 'image' | 'video';

export type CampaignAdInfo = {
  adCards: AdCard[];
  adPlacement?: AdPlacement;
  callToAction?: AdCallToAction;
  displayLink?: string;
  mainMessage?: string;
  phoneNumber?: string;
  type: CampaignAdInfoType;
}

export const enum AdBudgetTypes {
  Daily = 'daily',
  Lifetime = 'lifetime',
}

export type AdBudgetType = 'daily' | 'lifetime';
export type AdBudget = {
  type?: AdBudgetType;
  amount?: number;
  currency?: string;
  locale?: string;
  precision?: number;
}

export type FBError = {
  message?: string;
  userMessage?: string;
  userTitle?: string;
}

export type CardImages = {
  [key: string]: {
    hash?: string;
    updated?: number;
    created?: number;
    error?: FBError;
  },
};

export type AdIntegration = {
  type: 'facebook';
  flighted: boolean;
  campaign?: {
    id?: string;
    error?: FBError;
    created?: number;
    updated?: number;
  };
  adCreative?: {
      id?: string;
      error?: FBError;
      created?: number;
      updated?: number;
  };
  adSet?: {
      id?: string;
      error?: FBError;
      created?: number;
      updated?: number;
  };
  ad?: {
      id?: string;
      error?: FBError;
      created?: number;
      updated?: number;
  };
  status?: {
    date?: number;
    effectiveStatus?: string;
    error?: FBError;
  };
  cardImages?: CardImages;
};

export type CampaignAd = {
  storeId: string;
  ad: CampaignAdInfo;
  budget: AdBudget;
  integrations?: AdIntegration[];
  schedule: AdSchedule;
  targeting: AdTargeting;
}

export type SplitDate = {
  month: string;
  year: string;
  day: string;
}

export type SplitTime = {
  hour: string | number;
  minute: string | number;
}


export type ValidDay = 'sun' | 'mon' | 'tue' | 'wed' | 'thu' | 'fri' | 'sat';
export type AdSchedule = {
  startDate?: SplitDate;
  endDate?: SplitDate;
  startTime?: SplitTime;
  endTime?: SplitTime;
  allDay?: boolean;
  days?: ValidDay[];
  duration?: number;
  type?: string;
  frequency?: {
    cap: number;
    interval: number;
  }
}

/** 
 * Represents an active targetable campaign.
 **/
export type FacebookCampaignInfo = {
  ads: CampaignAd[];
  businessId: string;
  /**
   * This is to have a direct relationship between
   * Chowly’s "Location"/PartnerID object and Targetable’s StoreID object.
   */
  chowlyPartnerId?: string;
  created: number;
  goal: 'standard' | 'hiring' | 'housing';
  id: string;
  /**
   * @deprecated this field has no implementation
   */
  integrations?: AdIntegration[];
  name: string;
  objective: 'reach' | 'traffic' | 'conversions' | 'event' | 'quality_call' | 'leads' | 'sales' | 'engagement';
  pixelEventType?: string;
  suggestedCampaignId?: string;
  overrideAutoAging?: boolean;
  retryTimeout?: 24 | 48 | 72;
  status: MetaCampaignStatus;
  statusDetail?: FBError;
  updated?: number;
  userId?: string;
  /**
   * If provided, indicates the ad was created from an Ad Request.
   */
  requestInfo?: AdRequestInfo;
  /** Hold the date in ISO format when the record was "deleted" */
  deletedOn?: string;
  publishedAsPaused?: boolean;
  questionnaireId?: string;
  type?: string;
}

export type OptimizedCampaignStore = {
  storeId: string;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  targeting?: any;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  integrations?: any;
}

export type OptimizedCampaignData = {
  /** individual store info */
  stores?: OptimizedCampaignStore[];
  /** Add structure shared among all stores */
  ad?: {
    adCards: {
      image?: string;
      link?: string;
      type: AdCard['type'];
    }[]
  }
};

/**
 * Targetable Suggested Campaign in DynamoDB
 */
export type FacebookSuggestedCampaign = Omit<FacebookCampaignInfo, 'suggestedCampaignId' | 'retryTimeout' | 'status'> & {
  displayDate?: SplitDate;
  displayTime?: SplitTime;
  feedback?: InsightFeedback;
  status: FacebookSuggestedCampaignStatus;
  expiryDate: string;
  questionnaireId: string;
};

/**
 * Targetable Suggested Campaign in Agolia
 */
export type OptimizedCampaign = (FacebookCampaignInfo | FacebookSuggestedCampaign) & {
  displayDate?: SplitDate;
  displayTime?: SplitTime;
  isOptimized: true;
  storesData?: OptimizedCampaignData;
  ads: CampaignAd[] | ([Omit<CampaignAd, 'ad'> & {
    ad: OptimizedCampaignData['ad'];
  }]);
}

export type PauseMetaCampaignResponse =  {
  succesful: unknown[];
  failed: unknown[];
}