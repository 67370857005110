import DMBaseButton from './DMBaseButton';
import { styled } from '@mui/material/styles';
import { ButtonProps } from '@mui/material';

const ButtonWrapper = styled(DMBaseButton)``;

const PrimaryButton = (props: ButtonProps) => {
  return <ButtonWrapper variant="contained" {...props} />
}

export default PrimaryButton;