import styled from '@emotion/styled';
import { CircularProgress, css, Dialog } from '@mui/material';

export const SentimentContainer = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
  padding: 0px 24px;
`

export const SentimentAction = styled.div<{ selected?: boolean }>`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  cursor: pointer;

  & svg {
    width: 36px;
    height: 36px;
    fill: ${({ selected }) => selected ? '#1B51A4' : '#777777'};
  }
`
export const QuestionContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 15px;
`

export const QuestionText = styled.div`
  color: #292929;
  font-size: 15px;
  font-style: normal;
  font-weight: 400;
  line-height: 150%;

  & span {
    color: #C13030;
  }
`

export const ModalTitle = styled.div`
  color: #292929;
  font-size: 20px;
  font-style: normal;
  font-weight: 600;
  line-height: 140%;
`

export const BaseModal = styled(Dialog)`
  & .MuiBackdrop-root {
    background-color: #FAFAFACC;
  }
  
  & .MuiDialog-paper {
    max-width: 600px;
    width: 100%;
    padding: 32px;
  }
`

export const FeedbackTitle = styled(ModalTitle)`
  display: flex;
  align-items: center;
  justify-content: space-between;
`

export const FeedbackContent = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 16px;
  gap: 15px;
`

export const CustomTextArea = styled.textarea`
  width: 100%;
  height: 200px;
  border-radius: 8px;
  border: 1px solid #D7D7D7;
  padding: 12px;
  max-height: 200px;

  &::placeholder {
    color: #D7D7D7;
    font-size: 15px;
    font-style: normal;
    font-weight: 400;
    line-height: 150%;
  }

  &:focus {
    outline: none;
    border: 1px solid #1B51A4;
    box-shadow: unset;
  }
`

export const DialogActions = styled.div`
  display: flex;
  justify-content: flex-end;
  gap: 10px;
  margin-top: 40px;

  & button {
    padding: 8px 16px;
  }
`

export const ButtonLoadingIcon = styled(CircularProgress, {
  shouldForwardProp: (prop) => prop !== 'loading',
})<{loading?: boolean}>`
  display: none;
  color: inherit;
  ${({ loading }) => loading && css`
    display: block;
    color: #1B51A4;
  `}
`