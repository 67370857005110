/**
 * Official documentation:
 * https://developers.google.com/google-ads/api/rest/reference/rest/v11/Asset#AssetType
 */
export declare type AssetType = 'UNKNOWN' | 'IMAGE' | 'YOUTUBE_VIDEO' | 'TEXT' | 'SITELINK' | 'PROMOTION' | 'CALLOUT' | 'CALL' | 'LEAD_FORM';
export enum AssetTypes {
    Sitelink = "SITELINK",
    Text = "TEXT",
    YoutubeVideo = "YOUTUBE_VIDEO",
    Image = "IMAGE",
    Unknown = "UNKNOWN",
    Promotion = "PROMOTION",
    Callout = "CALLOUT",
    Call = "CALL",
    LeadForm = "LEAD_FORM"
}
export declare type DayOfWeek = 'MONDAY' | 'TUESDAY' | 'WEDNESDAY' | 'THURSDAY' | 'FRIDAY' | 'SATURDAY' | 'SUNDAY' | 'UNKNOWN';
export declare type MinuteOfHour = 'ZERO' | 'FIFTEEN' | 'THIRTY' | 'FORTY_FIVE' | 'UNKNOWN';
export declare type AssetScheduleTarget = {
    startMinute: MinuteOfHour;
    endMinute: MinuteOfHour;
    dayOfWeek: DayOfWeek;
    startHour: number;
    endHour: number;
};
export declare type PromotionAssetInfo = {
    /** A freeform description of what the promotion is targeting. */
    promotionTarget: string;
    /** one of 'UP_TO' | 'UNKNOWN' | 'UNSPECIFIED' */
    discountModifier: string;
    percentOff?: string;
    promotionCode?: string;
    redemptionStartDate?: string;
    redemptionEndDate?: string;
    moneyAmountOff?: {
        amountMicros: string;
        currencyCode: string;
    };
    ordersOverAmount?: {
        currencyCode: string;
        amountMicros: string;
    };
    /**
     * The occasion the promotion was intended for, eg. 'VALENTINES_DAY'. If an occasion is set, the redemption window
     * will need to fall within the date range associated with the occasion.
     */
    occasion?: string;
    startDate?: string;
    endDate?: string;
    adScheduleTargets?: AssetScheduleTarget[];
};
export declare type CommonAsset = {
    name?: string;
    asset?: string;
    resourceName?: string;
    finalUrls?: string[];
    finalMobileUrls?: string[];
    sitelinkAsset?: {
        description1: string;
        description2: string;
        linkText: string;
        startDate?: string;
        endDate?: string;
        adScheduleTargets?: AssetScheduleTarget[];
    };
    calloutAsset?: {
        calloutText: string;
        startDate?: string;
        endDate?: string;
        adScheduleTargets?: AssetScheduleTarget[];
    };
    callAsset?: {
        phoneNumber: string;
        countryCode: string;
        callConversionReportingState: string;
        callConversionAction: string;
        adScheduleTargets?: AssetScheduleTarget[];
    };
    textAsset?: {
        text: string;
    };
    imageAsset?: {
        fullSize: {
            url: string;
        };
    };
    promotionAsset?: PromotionAssetInfo;
    /**
     * ad group info for ad group assets types,
     * artificially added during fetching.
     */
    group?: {
        id: string;
        name: string;
        /**
         * The ad group resource name with format:
         * `customers/{customerId}/adGroups/{adGroupId}`
         */
        resourceName: string;
    };
    /** campaign info artificially added during fetching */
    campaign?: {
        id: string;
        name: string;
        /**
         * The campaign resource name with format:
         * `customers/{customerId}/campaigns/{campaignId}`
         */
        resourceName: string;
    };
};
export declare type CampaignAsset = CommonAsset & {
    type: AssetType;
    id: string;
    source: string;
    campaignId?: string;
};
export declare type GroupAsset = CommonAsset & {
    type: AssetType;
};
export declare type AccountAsset = CommonAsset & {
    type: AssetType;
};
export declare type GetAssetsResponse = {
    campaignAssets: CampaignAsset[];
    groupAssets: GroupAsset[];
    accountAssets: AccountAsset[];
};
