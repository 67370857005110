import React from 'react'

import { StyledCard, StyledCardActions, StyledCardContent } from './styles'

interface FacebookCardContainerProps {
  primary?: React.ReactNode
  secondary?: React.ReactNode
  fullwidth?: boolean
  classes?: {
    cardContainer?: string
    cardActionsContainer?: string
    contentRoot?: string
  }
}

const FacebookCardContainer: React.FC<FacebookCardContainerProps> = ({
  primary,
  secondary,
  fullwidth = false,
  classes = {},
}) => {
  return (
    <StyledCard
      variant='outlined'
      fullwidth={fullwidth}
      data-testid='facebookCardContainer_component'
    >
      {primary && (
        <StyledCardContent
          className={classes.contentRoot}
          data-testid='facebookCardContainer_cardContent_container'
        >
          {primary}
        </StyledCardContent>
      )}
      <StyledCardActions
        className={classes.cardActionsContainer}
        data-testid='facebookCardContainer_cardActions_container'
      >
        {secondary}
      </StyledCardActions>
    </StyledCard>
  )
}

export default FacebookCardContainer
