import styled from '@emotion/styled';
import { Box } from '@mui/material';

export const RootBox = styled(Box)`
  align-items: center;
  color: #606770;
  font-family: Montserrat, sans-serif;
  display: flex;
  flex: 1 0;
  font-weight: 600;
  height: 32px;
  justify-content: center;
  padding: 0 2px;
  text-decoration: none;
  cursor: pointer;
  &:hover {
    background-color: rgba(0, 0, 0, 0.05);
    border-radius: 2px;
  }
`;

export const Icon = styled(Box)<{ backgroundimage: string; backgroundsize: string; backgroundposition: string }>`
  background-repeat: no-repeat;
  background-size: ${(props) => props.backgroundsize};
  background-position: ${(props) => props.backgroundposition};
  display: inline-block;
  width: 18px;
  height: 18px;
  margin-right: 5px;
  background-image: url(${(props) => props.backgroundimage});
`;

export const Text = styled(Box)`
  font-family: Arial;
  font-size: 14px;
`;
