import {
  CustomizationGroupByCustomizationId,
  CustomizationOptionsByCustomizationId,
  FormattedMenuSectionInfo,
  ItemModifier,
  MenuItemSuspensionUpdateInput,
  MenuItemSuspensionUpdateResponse,
  MenuMappingCustomization,
  MenuMappingItemDetails,
  MenuMappingSubsection,
  SectionModifier,
  TypeName,
  getPosProvidesStockUpdates,
  updateMenuItemSuspensionStatus,
} from 'api'
import { SuspendUntilItemsObj, SuspendUntilModifiersObj } from 'api/menuMapping/types'
import { AxiosResponse } from 'axios'
import { AlertModal, LoadingSkeleton, Toggle } from 'components'
import { useLocations } from 'components/MainLayout/MainLayout'
import moment from 'moment'
import { useEffect, useState } from 'react'
import { toast } from 'react-toastify'
import { colors } from 'theme'
import {
  convertUTCToPartnerTimezone,
  getSuspendUntilDefaultTime,
  getTzValueByKey,
  isDateEqual,
} from 'utils/DateTime'

import MenuItemViewModal from './MenuItemViewModal'
import MenuPreviewBottomActionBar from './MenuPreviewBottomActionBar'
import MenuSubsection from './MenuSubsection'
import * as Styled from './styles'
import SuspendUntilModal from './SuspendUntilModal'
import {
  TABS,
  importProvidesStockUpdates_false,
  importProvidesStockUpdates_false_title,
  importProvidesStockUpdates_true,
  importProvidesStockUpdates_true_title,
} from './util/constants'
import { getRowText } from './util/formatMenuText'
import getSearchFilteredItemsModifiers from './util/getSearchFilteredItemsModifiers'

export const INDEFINITELY_86ED = '86ed Indefinitely'

// util function for adding/removing item from a list.
const toggleItemInList = (list: string[] | [], item: string): string[] => {
  const newList = [...list]
  const index = newList.indexOf(item)

  if (index > -1) {
    newList.splice(index, 1) // removes item
  } else {
    newList.push(item) // adds item
  }
  return newList
}

export const itemIsActive = (item: FormattedMenuSectionInfo | SectionModifier) => {
  // suspend_until field can be missing, null, or a date that has already passed
  if (
    !item.attributes.suspend_until ||
    (item.attributes.suspend_until && moment(item.attributes.suspend_until) < moment())
  ) {
    return true
  }
  return false
}

/**
 * @param item
 * @param modifiers
 * @returns flatted array of modifiers for all the customization options of an item. (item.itemCustomizations[id] = key in itemModifiers)
 */
export const mapItemToModifiers = (
  item: FormattedMenuSectionInfo,
  modifiers: CustomizationOptionsByCustomizationId,
): ItemModifier[] | [] => {
  let flattenedModifiers: ItemModifier[] | [] = []

  item.children.customizations?.map(({ id }) => {
    const mods = modifiers[id]

    if (mods?.length) {
      flattenedModifiers = [...flattenedModifiers, ...mods]
    }
  })

  return flattenedModifiers
}

const getTabName = (idx: number) => {
  switch (idx) {
    case 0:
    default:
      return TABS.ALL_PRODUCTS
    case 1:
      return TABS.ACTIVE
    case 2:
      return TABS.INACTIVE
  }
}

type Props = {
  combinedMenuSectionData?: FormattedMenuSectionInfo
  refreshMenuSection: () => void
  isLoading: boolean
  publishMenu: () => void
  searchInput: string
  setSearchInput: (input: string) => void
  itemModifierGroups: CustomizationGroupByCustomizationId | undefined
  selectedTab: number
  setSelectedTab: (tab: number) => void
  expandAllModifiers: boolean
  setExpandAllModifiers: (status: boolean) => void
  setIs86PreviewOnly: (status: boolean) => void
  is86PreviewOnly: boolean
  allMenuItems: FormattedMenuSectionInfo[]
  setAllMenuItems: (items: FormattedMenuSectionInfo[]) => void
}

const MenuSectionPreview = ({
  combinedMenuSectionData,
  refreshMenuSection,
  isLoading,
  publishMenu,
  searchInput,
  setSearchInput,
  itemModifierGroups,
  selectedTab,
  setSelectedTab,
  expandAllModifiers,
  setExpandAllModifiers,
  setIs86PreviewOnly,
  is86PreviewOnly,
  allMenuItems,
  setAllMenuItems,
}: // viewByModifier,
Props) => {
  const { locationsData } = useLocations()

  const [formattedMenuSection, setFormattedMenuSection] = useState<FormattedMenuSectionInfo>()

  const [selectedItemIds, setSelectedItemIds] = useState<MenuMappingItemDetails['id'][]>([])
  const [selectedSubsectionIds, setSelectedSubsectionIds] = useState<MenuMappingSubsection['id'][]>(
    [],
  )
  const [selectedMenuSectionId, setSelectedMenuSectionId] = useState<string | null>(null)
  const [selectedFilteredSection, setSelectedFilteredSection] = useState<boolean>(false) // for search input section toggle

  const [posProvidesStockUpdates, setPosProvidesStockUpdates] = useState<boolean>(false)

  const [selectedModifierIds, setSelectedModifierIds] = useState<ItemModifier['id'][]>([])

  const [itemsToSuspend, setItemsToSuspend] = useState<MenuMappingItemDetails['id'][]>([])
  const [itemsToUnsuspend, setItemsToUnsuspend] = useState<MenuMappingItemDetails['id'][]>([])
  const [modifiersToSuspend, setModifiersToSuspend] = useState<ItemModifier['id'][]>([])
  const [modifiersToUnsuspend, setModifiersToUnsuspend] = useState<ItemModifier['id'][]>([])
  const [selectedModalItemNumber, setSelectedModalItemNumber] = useState<number>(0)

  const [suspendUntilItems, setSuspendUntilItems] = useState<SuspendUntilItemsObj[]>([]) // suspend_until times items
  const [suspendUntilModifiers, setSuspendUntilModifiers] = useState<SuspendUntilModifiersObj[]>([]) // suspend until times modifiers
  const [posWarningAlertOpen, setPosWarningAlertOpen] = useState(false)
  // const [uniqueModifiersGroups, setUniqueModifiersGroups] = useState<FormattedMenuSectionInfo[]>([])

  const [itemViewModalOpen, setItemViewModalOpen] = useState(false)
  const [selectedItemInModal, setSelectedItemInModal] = useState<FormattedMenuSectionInfo | null>(
    null,
  )

  const showBottomActionBar =
    itemsToSuspend.length +
      itemsToUnsuspend.length +
      modifiersToSuspend.length +
      modifiersToUnsuspend.length >
    0

  // inits the Suspend Until Modal for the selected item/modifier
  const [suspendUntilItem, setSuspendUntilItem] = useState<{
    id: MenuMappingItemDetails['id'] | ItemModifier['id']
    type: TypeName.Item | TypeName.CustomizationOption
  }>()

  useEffect(() => {
    if (selectedItemInModal) {
      const itemIdx = allMenuItems.findIndex((item) => item.id === selectedItemInModal.id)
      if (itemIdx !== -1) {
        setSelectedModalItemNumber(itemIdx + 1)
      }

      setItemViewModalOpen(true)
    }
  }, [selectedItemInModal])

  useEffect(() => {
    if (combinedMenuSectionData) {
      setFormattedMenuSection(combinedMenuSectionData)

      if (combinedMenuSectionData.attributes.partnerId) {
        fetchPosCustomerRule(combinedMenuSectionData.attributes.partnerId)
      }
    }
  }, [combinedMenuSectionData])

  useEffect(() => {
    if (formattedMenuSection) {
      resetToggleSelection()
    }
  }, [formattedMenuSection])

  const partnerLocation = locationsData?.locations?.find(
    (location) => location.id === formattedMenuSection?.attributes.partnerId,
  )
  const timezone = getTzValueByKey(partnerLocation?.timezone || '')
  // if partnerTimezone is null, use browser timezone.
  const partnerTimezone = timezone || Intl.DateTimeFormat().resolvedOptions().timeZone
  // next day at midnight
  const suspendUntilDefault = getSuspendUntilDefaultTime(partnerTimezone)

  // check if this parnter has a POS system that requires stock updates
  const fetchPosCustomerRule = async (partnerId: number) => {
    let res: AxiosResponse | undefined
    try {
      res = await getPosProvidesStockUpdates(partnerId)
    } catch (err) {
      console.log('failed to fetch pos status', err)
      return
    }

    const stockUpdatesProvided = res?.data?.attributes?.import_provides_stock_updates

    if (typeof stockUpdatesProvided === 'boolean') {
      setPosProvidesStockUpdates(stockUpdatesProvided)
    }
  }

  // reset everything to the original combinedMenuSectionData
  // inits toggle selection
  const resetToggleSelection = () => {
    const menuItems: FormattedMenuSectionInfo[] = []
    const activeIds: MenuMappingItemDetails['id'][] = []
    const numSubsections: number = formattedMenuSection?.children.subsections?.length || 0
    const activeSubsectionIds: MenuMappingSubsection['id'][] = []
    const activeModifierIds: ItemModifier['id'][] = []

    const subsections = formattedMenuSection?.children.subsections

    // Menu Subsection level
    subsections?.forEach(({ id: subsectionId, children }) => {
      const subsectionItems = children.items
      const totalItemsInSection = subsectionItems?.length
      let activeIdsInSection = 0

      // Item level
      subsectionItems?.forEach((item) => {
        menuItems.push(item)

        // item is active if suspend_until is not set
        if (itemIsActive(item)) {
          activeIds.push(item.id)
          activeIdsInSection += 1
        }

        // Modifier Groups level
        const customizations = item.children.customizations

        customizations?.forEach((customization) => {
          // Modifiers level
          const customizationOptions = customization.children.customization_options

          customizationOptions?.forEach((option) => {
            if (itemIsActive(option)) {
              activeModifierIds.push(option.id)
            }

            // nested groups and modifiers level
            option.children.customizations?.forEach((nestedCustomization) =>
              nestedCustomization.children.customization_options?.forEach((nestedOption) => {
                if (itemIsActive(nestedOption)) {
                  activeModifierIds.push(nestedOption.id)
                }
              }),
            )
          })
        })
      })

      // all items in the section are active, toggle whole section on
      // TODO: Do the same with customizations (groups)
      if (activeIdsInSection === totalItemsInSection) {
        activeSubsectionIds.push(subsectionId)
      }
    })

    if (numSubsections === activeSubsectionIds.length) {
      // set the main menu toggle to active if all subsections are active
      if (formattedMenuSection) {
        setSelectedMenuSectionId(formattedMenuSection.id)
      }
    } else {
      setSelectedMenuSectionId(null)
    }

    setAllMenuItems(menuItems)
    setSelectedItemIds(activeIds) // preselect active items
    setSelectedSubsectionIds(activeSubsectionIds)
    setSelectedTab(0)
    setSearchInput('')
    setExpandAllModifiers(false)
    setIs86PreviewOnly(false)
    setSuspendUntilItems([])
    setSelectedModifierIds([...new Set(activeModifierIds)]) // these have to be unique
    setSuspendUntilModifiers([])
  }

  // The main useEffect managing item suspension when a change to selectedItemIds occurs (fires when we toggle an item on/off) by resorting items to the right places.
  // Considers the previous suspendUntil status of an item:
  // Newly toggled suspended items with time not set yet: suspend_until gets set to default (next day)
  // Newly suspended items with suspend_until set by the user: suspend_until is preserved to the set value
  // Previously suspended items (from the db) keep their original suspend_until time
  useEffect(() => {
    const suspendItemIds: MenuMappingItemDetails['id'][] = []
    const inactiveItemSuspendUntil: SuspendUntilItemsObj[] = []
    const unsuspendItemIds: MenuMappingItemDetails['id'][] = []

    allMenuItems.forEach((item) => {
      // unsuspend item. Don't add it to the list if it's already unsuspended.
      if (selectedItemIds.includes(item.id) && !itemIsActive(item)) {
        unsuspendItemIds.push(item.id)
      }

      // suspend new item for the first time, use default.
      if (!selectedItemIds.includes(item.id) && itemIsActive(item)) {
        // check if we already reset the time of this item.
        let suspendUntil = suspendUntilItems.find((el) => el.id === item.id)?.suspendUntil

        if (suspendUntil === undefined) {
          suspendUntil = suspendUntilDefault
        }

        inactiveItemSuspendUntil.push({ id: item.id, suspendUntil })
        suspendItemIds.push(item.id)
      }

      // if already suspended item is not in the selected items list, we may still be updating the time. Add it to the list.
      if (!selectedItemIds.includes(item.id) && !itemIsActive(item)) {
        const suspendUntil = suspendUntilItems.find((el) => el.id === item.id)?.suspendUntil

        if (
          suspendUntil !== undefined &&
          item.attributes.suspend_until !== undefined &&
          isDateEqual(suspendUntil, item.attributes.suspend_until)
        ) {
          inactiveItemSuspendUntil.push({ id: item.id, suspendUntil })
          suspendItemIds.push(item.id)
        } else {
          // we aren't updating the item, don't add it to suspenditemIds, just preserve its state
          if (item.attributes.suspend_until) {
            inactiveItemSuspendUntil.push({
              id: item.id,
              suspendUntil: item.attributes.suspend_until,
            })
          }
        }
      }
    })

    setItemsToSuspend(suspendItemIds)
    setSuspendUntilItems(inactiveItemSuspendUntil)
    setItemsToUnsuspend(unsuspendItemIds)
  }, [selectedItemIds])

  // Manages modifier suspension when a change to selectedModifierIds occurs (fires when we toggle a modifier on/off) by resorting modifiers to the right places.
  // Same logic as selectedItemIds useEffect
  useEffect(() => {
    let suspendModifierIds: ItemModifier['id'][] = []
    const inactiveModifierSuspendUntil: SuspendUntilModifiersObj[] = []
    let unsuspendModifierIds: ItemModifier['id'][] = []

    allMenuItems.forEach((item) =>
      item.children.customizations?.forEach((customization) =>
        customization.children.customization_options?.forEach((modifier) => {
          // if modifier was previously inactive but is now set to active, we want to unsuspend it
          if (selectedModifierIds.includes(modifier.id) && !itemIsActive(modifier)) {
            unsuspendModifierIds = [...new Set([...unsuspendModifierIds, modifier.id])]
          }

          // if modifier was previously active and now it's not active, we want to suspend it and set the suspend until time.
          if (!selectedModifierIds.includes(modifier.id) && itemIsActive(modifier)) {
            let suspendUntil = suspendUntilModifiers.find(
              (el) => el.id === modifier.id,
            )?.suspendUntil

            if (suspendUntil === undefined) {
              suspendUntil = suspendUntilDefault
            }

            inactiveModifierSuspendUntil.push({
              id: modifier.id,
              suspendUntil,
            })
            suspendModifierIds = [...new Set([...suspendModifierIds, modifier.id])]
          }

          // if modifier was not active, and we want to keep as as inactive, we are updating the suspend until time and need to keep track of it to send in the request
          if (!selectedModifierIds.includes(modifier.id) && !itemIsActive(modifier)) {
            const suspendUntil = suspendUntilModifiers.find(
              (el) => el.id === modifier.id,
            )?.suspendUntil

            if (
              suspendUntil !== undefined &&
              isDateEqual(suspendUntil, modifier.attributes.suspend_until || null)
            ) {
              inactiveModifierSuspendUntil.push({ id: modifier.id, suspendUntil })
              suspendModifierIds = [...new Set([...suspendModifierIds, modifier.id])]
            } else {
              inactiveModifierSuspendUntil.push({
                id: modifier.id,
                suspendUntil: modifier.attributes.suspend_until || null,
              })
            }
          }

          // NESTED MODS: Do the same thing (this is ugly but we only go two levels down)
          modifier.children.customizations?.forEach((nestedCustomization) =>
            nestedCustomization.children.customization_options?.forEach((nestedMod) => {
              // if modifier was previously inactive but is now set to active, we want to unsuspend it
              if (selectedModifierIds.includes(nestedMod.id) && !itemIsActive(nestedMod)) {
                unsuspendModifierIds = [...new Set([...unsuspendModifierIds, nestedMod.id])]
              }

              // if modifier was previously active and now it's not active, we want to suspend it and set the suspend until time.
              if (!selectedModifierIds.includes(nestedMod.id) && itemIsActive(nestedMod)) {
                let suspendUntil = suspendUntilModifiers.find(
                  (el) => el.id === nestedMod.id,
                )?.suspendUntil

                if (suspendUntil === undefined) {
                  suspendUntil = suspendUntilDefault
                }

                inactiveModifierSuspendUntil.push({
                  id: nestedMod.id,
                  suspendUntil,
                })
                suspendModifierIds = [...new Set([...suspendModifierIds, nestedMod.id])]
              }

              // if modifier was not active, and we want to keep as as inactive, we are updating the suspend until time and need to keep track of it to send in the request
              if (!selectedModifierIds.includes(nestedMod.id) && !itemIsActive(nestedMod)) {
                const suspendUntil = suspendUntilModifiers.find(
                  (el) => el.id === nestedMod.id,
                )?.suspendUntil

                if (
                  suspendUntil !== undefined &&
                  isDateEqual(suspendUntil, nestedMod.attributes.suspend_until || null)
                ) {
                  inactiveModifierSuspendUntil.push({ id: nestedMod.id, suspendUntil })
                  suspendModifierIds = [...new Set([...suspendModifierIds, nestedMod.id])]
                } else {
                  inactiveModifierSuspendUntil.push({
                    id: nestedMod.id,
                    suspendUntil: nestedMod.attributes.suspend_until || null,
                  })
                }
              }
            }),
          )
        }),
      ),
    )

    setModifiersToSuspend(suspendModifierIds)
    setSuspendUntilModifiers(inactiveModifierSuspendUntil)
    setModifiersToUnsuspend(unsuspendModifierIds)
  }, [selectedModifierIds])

  // individual item selection
  const manageSelectedItems = (itemId: MenuMappingItemDetails['id']) => {
    setSelectedItemIds((prevItems) => toggleItemInList(prevItems, itemId))
  }

  const manageSelectedModifiers = (modifierId: ItemModifier['id']) => {
    setSelectedModifierIds((prevIds) => toggleItemInList(prevIds, modifierId))
  }

  const getFailedUpdateMessage = (
    itemIds: MenuMappingItemDetails['id'][],
    modIds: ItemModifier['id'][],
  ) => {
    let failedMessage =
      'Some items have not updated due to pending changes with the menu. Please try again later. '

    const failedItemTitles = allMenuItems
      .filter((item) => itemIds?.includes(item.id))
      .map((product) => product.attributes.title)
    const failedModifierTitles: string[] = []

    if (itemModifierGroups) {
      Object.keys(itemModifierGroups).map((key: MenuMappingCustomization['id']) => {
        itemModifierGroups[key]?.children.customization_options.forEach((mod) => {
          if (modIds.includes(mod.id)) {
            failedModifierTitles.push(mod.attributes.title)
          }
        })
      })
    }

    if (failedItemTitles.length) {
      failedMessage = `These items have failed: (${failedItemTitles.toString()}). `
    }

    if (failedModifierTitles.length) {
      failedMessage = failedMessage.concat(
        `These modifiers have failed: (${failedModifierTitles.toString()}).`,
      )
    }

    return failedMessage
  }

  const mapToSuspendUntil = (type: TypeName.Item | TypeName.CustomizationOption) => {
    if (type === TypeName.Item) {
      const finalItemsToSuspend: { id: string; suspend_until: string | null }[] = []

      itemsToSuspend.forEach((itemId) => {
        let itemSuspendUntilVal = suspendUntilItems.find((item) => item.id === itemId)?.suspendUntil

        // have to check for undefined, because null means "indefinitely"
        if (itemSuspendUntilVal === undefined) {
          itemSuspendUntilVal = suspendUntilDefault
        }

        finalItemsToSuspend.push({
          id: itemId,
          suspend_until: itemSuspendUntilVal,
        })
      })

      return finalItemsToSuspend
    }
    if (type === TypeName.CustomizationOption) {
      const finalModifiersToSuspend: { id: string; suspend_until: string | null }[] = []

      modifiersToSuspend.forEach((modId) => {
        let modSuspendUntilVal = suspendUntilModifiers.find((el) => el.id === modId)?.suspendUntil

        if (modSuspendUntilVal === undefined) {
          modSuspendUntilVal = suspendUntilDefault
        }

        finalModifiersToSuspend.push({
          id: modId,
          suspend_until: modSuspendUntilVal,
        })
      })

      return finalModifiersToSuspend
    }
    return []
  }

  const triggerMenuUpdate = async () => {
    const payload: MenuItemSuspensionUpdateInput = {
      data: [],
    }
    if (itemsToSuspend.length || itemsToUnsuspend.length) {
      payload.data.push({
        type: TypeName.Items,
        actions: {
          suspend: mapToSuspendUntil(TypeName.Item),
          unsuspend: itemsToUnsuspend,
        },
      })
    }

    if (modifiersToSuspend.length || modifiersToUnsuspend.length) {
      payload.data.push({
        type: TypeName.CustomizationOptions,
        actions: {
          suspend: mapToSuspendUntil(TypeName.CustomizationOption),
          unsuspend: modifiersToUnsuspend,
        },
      })
    }

    let updateResponse: MenuItemSuspensionUpdateResponse | null = null

    try {
      // update item status in Chowly's db
      updateResponse = await updateMenuItemSuspensionStatus(payload)
    } catch (error) {
      toast.error(`Error updating the menu. Please try again. ${error}`)
    }

    if (updateResponse?.status === 'success') {
      // publish changes out to the TOOS
      publishMenu()
      refreshMenuSection()
    } else if (updateResponse?.status === 'partial_success') {
      // request publish for successful items and show which did not update
      const failedItemIds: MenuMappingItemDetails['id'][] = []
      let failedModifierIds: ItemModifier['id'][] = []

      updateResponse.data?.failed_items?.forEach((item) => {
        if (item.type === TypeName.Item) {
          failedItemIds.push(item.id)
        }
        if (item.type === TypeName.CustomizationOption) {
          failedModifierIds.push(item.id)
        }
      })

      // dedupe modifier ids (they are stored as duplicates mapped to customization ids)
      failedModifierIds = [...new Set([...failedModifierIds])]

      const failMessage = getFailedUpdateMessage(failedItemIds, failedModifierIds)
      toast.error(failMessage)
      // publish changes out to the TOOS for partially successful items
      publishMenu()
      refreshMenuSection()
    } else if (updateResponse?.status === 'failure') {
      toast.error(
        'All items and/or modifiers have failed to update, possibly due to pending changes with the menu. Please try again later.',
      )
    }
  }

  // this will override the previous individual item selections.
  const manageSelectedSubsections = (subsectionId: string) => {
    const subsection = formattedMenuSection?.children.subsections?.find(
      (subsection) => subsection.id === subsectionId,
    )
    const subsectionItemIds = subsection?.children.items?.map((item) => item.id) ?? []

    const updatedSubsections = [...selectedSubsectionIds]
    let updatedItems = [...selectedItemIds]

    if (updatedSubsections.includes(subsectionId)) {
      // remove individual menu items
      subsectionItemIds?.forEach((id) => {
        const idx = updatedItems.indexOf(id)
        updatedItems.splice(idx, 1)
      })
    } else {
      // add individual menu items
      if (subsectionItemIds?.length) {
        const combinedItemIds = [...selectedItemIds, ...subsectionItemIds]
        const uniqueItemIds = [...new Set(combinedItemIds)]
        updatedItems = uniqueItemIds
      }
    }

    setSelectedItemIds(updatedItems)
    setSelectedSubsectionIds((prevSubsections) => toggleItemInList(prevSubsections, subsectionId))
  }

  // this will override individual item selections and subsection selections to either select everything or nothing.
  const manageSelectedMenu = (checked: boolean) => {
    let itemIdsToToggle: MenuMappingItemDetails['id'][] = []
    let modifierIdsToToggle: ItemModifier['id'][] = []

    // if there is a search filter applied, only select those items. Otherwise select all of them.
    if (searchInput) {
      const searchFilteredData = getSearchFilteredItemsModifiers(searchInput, allMenuItems)

      if (searchFilteredData) {
        const { matchingItems, filteredModifiers } = searchFilteredData

        let flatModifiers: FormattedMenuSectionInfo[] = []

        Object.values(filteredModifiers).map((modifiers) => {
          flatModifiers = [...flatModifiers, ...modifiers]
        })

        itemIdsToToggle = matchingItems.map(({ id }) => id)
        modifierIdsToToggle = flatModifiers.map(({ id }) => id)
      }
    } else {
      itemIdsToToggle = [...allMenuItems].map(({ id }) => id)
    }

    if (checked) {
      const allSubsectionIds: string[] = []

      formattedMenuSection?.children.subsections?.forEach((subsection) => {
        allSubsectionIds.push(subsection.id)
      })

      if (formattedMenuSection?.id) {
        if (searchInput) {
          setSelectedItemIds([...new Set([...selectedItemIds, ...itemIdsToToggle])]) // add these new items to the current list, deduped
          setSelectedModifierIds([...new Set([...selectedModifierIds, ...modifierIdsToToggle])])
          setSelectedFilteredSection(true)
        } else {
          setSelectedSubsectionIds(allSubsectionIds)
          setSelectedItemIds(itemIdsToToggle)
          setSelectedMenuSectionId(formattedMenuSection.id)
        }
      }
    } else {
      if (searchInput) {
        // only clear the search results, not all items.
        const updatedSelectedItemIds = selectedItemIds.filter((id) => !itemIdsToToggle.includes(id))
        const updatedSelectedModsIds = selectedModifierIds.filter(
          (id) => !modifierIdsToToggle.includes(id),
        )

        setSelectedItemIds(updatedSelectedItemIds)
        setSelectedModifierIds(updatedSelectedModsIds)
        setSelectedItemIds(updatedSelectedItemIds)
        setSelectedModifierIds(updatedSelectedModsIds)
        setSelectedFilteredSection(false)
      } else {
        setSelectedItemIds([])
        setSelectedSubsectionIds([])
        setSelectedMenuSectionId(null)
      }
    }
  }

  // triggered by the suspend until modal's Save action
  const saveSuspendUntilTime = (
    type: TypeName.Item | TypeName.CustomizationOption,
    id: MenuMappingItemDetails['id'] | ItemModifier['id'],
    suspendUntil: string | null,
  ) => {
    if (type === TypeName.Item) {
      const modifiedSuspendUntilItems = [...suspendUntilItems]
      const existingSuspendUntil = modifiedSuspendUntilItems.find((item) => item.id === id)

      // we're either modifying the suspend until time or setting a new one
      if (existingSuspendUntil) {
        existingSuspendUntil.suspendUntil = suspendUntil
      } else {
        modifiedSuspendUntilItems.push({ id, suspendUntil })
      }

      setSuspendUntilItems(modifiedSuspendUntilItems)
      // add to itemsToSuspend since we're changing the suspend_until time
      setItemsToSuspend((prevItems) => [...new Set([...prevItems, id])])
    }

    if (type === TypeName.CustomizationOption) {
      const modifiedSuspendUntilModifiers = [...suspendUntilModifiers]
      const existingSuspendUntil = modifiedSuspendUntilModifiers.find((mod) => mod.id === id)

      if (existingSuspendUntil) {
        existingSuspendUntil.suspendUntil = suspendUntil
      } else {
        modifiedSuspendUntilModifiers.push({ id, suspendUntil })
      }

      setSuspendUntilModifiers(modifiedSuspendUntilModifiers)
      setModifiersToSuspend((prevItems) => [...new Set([...prevItems, id])])
    }
  }

  const checkSuspendUntilValue = (
    type: TypeName.Item | TypeName.CustomizationOption,
    id: MenuMappingItemDetails['id'] | ItemModifier['id'],
  ) => {
    let suspendUntil = null

    if (type === TypeName.Item) {
      suspendUntil = suspendUntilItems.find((el) => el.id === id)?.suspendUntil
    }

    if (type === TypeName.CustomizationOption) {
      suspendUntil = suspendUntilModifiers.find((el) => el.id === id)?.suspendUntil
    }

    // the backend converts null suspendUntil time on a suspended item to a date in the future with '2525-12-25'
    if (suspendUntil && !suspendUntil.includes('2525-12-25')) {
      return convertUTCToPartnerTimezone(suspendUntil, partnerTimezone).toString()
    } else {
      return INDEFINITELY_86ED
    }
  }

  const TableHeaderRow = () => {
    if (is86PreviewOnly) {
      const numUpdatedItems = itemsToSuspend.length + itemsToUnsuspend.length
      const numUpdatedModifiers = modifiersToSuspend.length + modifiersToUnsuspend.length

      return (
        <>
          <Styled.TopRowText padding='0 0 0 12px'>
            {getRowText(numUpdatedItems, numUpdatedModifiers)}
          </Styled.TopRowText>
          {/* Placeholder to keep the flex container columns in line. */}
          <Styled.TopRowText marginRight='112px' />
        </>
      )
    } else {
      return (
        <>
          <Styled.CheckboxContainer>
            <Toggle
              id={'top-toggle'}
              isToggled={!!selectedMenuSectionId}
              onToggle={() => {
                selectedMenuSectionId ? manageSelectedMenu(false) : manageSelectedMenu(true)
              }}
              activeColor={colors.blue[700]}
            />
          </Styled.CheckboxContainer>
          {selectedItemIds.length ? (
            <>
              <Styled.TopRowText marginLeft='10px'>
                {selectedItemIds.length > 1 ? `${selectedItemIds.length} Products` : '1 Product'}
              </Styled.TopRowText>
              {/* Placeholder to keep the flex container columns in line. */}
              <Styled.TopRowText marginRight='112px' />
            </>
          ) : (
            <>
              <Styled.TopRowText marginLeft='56px'>Product</Styled.TopRowText>
              <Styled.TopRowText marginRight='56px'>Status</Styled.TopRowText>
            </>
          )}
        </>
      )
    }
  }

  const TableHeaderRowSearch = () => {
    return (
      <>
        {!is86PreviewOnly && (
          <Styled.CheckboxContainer>
            <Toggle
              id={'top-toggle-input'}
              isToggled={selectedFilteredSection}
              onToggle={() => {
                selectedFilteredSection ? manageSelectedMenu(false) : manageSelectedMenu(true)
              }}
              activeColor={colors.blue[700]}
            />
          </Styled.CheckboxContainer>
        )}
        <Styled.TopRowText marginLeft='56px'>Product</Styled.TopRowText>
        <Styled.TopRowText marginRight='56px'>Status</Styled.TopRowText>
      </>
    )
  }

  return (
    <>
      <>
        <Styled.TopRow height='69px'>
          {searchInput.length ? <TableHeaderRowSearch /> : <TableHeaderRow />}
        </Styled.TopRow>
        {isLoading ? (
          <LoadingSkeleton lineCount={10} isFlex />
        ) : (
          formattedMenuSection?.children.subsections?.map((subsection) => (
            <MenuSubsection
              key={subsection.id}
              subsection={subsection}
              onItemToggle={manageSelectedItems}
              checkedItemIds={selectedItemIds}
              onSubsectionClick={manageSelectedSubsections}
              checkedSubsectionIds={selectedSubsectionIds}
              isPreviewOnly={is86PreviewOnly}
              groupModifiers={itemModifierGroups}
              itemsToSuspend={itemsToSuspend}
              itemsToUnsuspend={itemsToUnsuspend}
              checkedModifierIds={selectedModifierIds}
              onModifierClick={manageSelectedModifiers}
              modifiersToSuspend={modifiersToSuspend}
              modifiersToUnsuspend={modifiersToUnsuspend}
              setSuspendUntilItem={setSuspendUntilItem}
              checkSuspendUntilStatus={checkSuspendUntilValue}
              tab={getTabName(selectedTab)}
              searchInput={searchInput}
              isModifiersLoading={isLoading}
              expandAllModifiers={expandAllModifiers}
              setSelectedItemInModal={setSelectedItemInModal}
            />
          ))
        )}
      </>

      {/* Show bottom bar only if changes were made */}
      {showBottomActionBar ? (
        <MenuPreviewBottomActionBar
          onPublishMenu={triggerMenuUpdate}
          isPreviewOnly={is86PreviewOnly}
          onReset={resetToggleSelection}
          onContinue={() => {
            if (posProvidesStockUpdates) {
              setIs86PreviewOnly(true)
              setPosWarningAlertOpen(true)
            } else {
              setPosWarningAlertOpen(true)
            }
          }}
        />
      ) : null}

      <SuspendUntilModal
        isOpen={!!(suspendUntilItem && Object.keys(suspendUntilItem).length)}
        onClose={() => setSuspendUntilItem(undefined)}
        buttonAction={saveSuspendUntilTime}
        itemToSuspend={suspendUntilItem}
        partnerTimezone={partnerTimezone}
      />

      <AlertModal
        isOpen={posWarningAlertOpen}
        title={
          posProvidesStockUpdates
            ? importProvidesStockUpdates_true_title
            : importProvidesStockUpdates_false_title
        }
        message={
          posProvidesStockUpdates
            ? importProvidesStockUpdates_true
            : importProvidesStockUpdates_false
        }
        buttonTitle='Proceed'
        buttonAction={() => {
          setIs86PreviewOnly(true)
          setPosWarningAlertOpen(false)
        }}
        onClose={() => setPosWarningAlertOpen(false)}
        showInfoIcon={true}
      />

      {selectedItemInModal && itemViewModalOpen && (
        <MenuItemViewModal
          item={selectedItemInModal}
          onClose={() => {
            setSelectedItemInModal(null)
            setItemViewModalOpen(false)
          }}
          isOpen={itemViewModalOpen}
          itemCount={selectedModalItemNumber}
          totalItemsCount={allMenuItems.length}
          setSelectedItemInModal={setSelectedItemInModal}
          allMenuItems={allMenuItems}
          checkedModifierIds={selectedModifierIds}
          onModifierClick={manageSelectedModifiers}
          setSuspendUntilItem={setSuspendUntilItem}
          checkSuspendUntilStatus={checkSuspendUntilValue}
          expandAllModifiers={expandAllModifiers}
        />
      )}
    </>
  )
}

export default MenuSectionPreview
