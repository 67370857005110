import styled from '@emotion/styled';
import { Card, CardActions, CardContent } from '@mui/material';

export const StyledCard = styled(Card, {
  shouldForwardProp: (prop) => prop !== 'fullwidth',
})<{ fullwidth: boolean | undefined }>`
  min-width: 80%;
  display: flex;
  flex-direction: column;
  border-color: #c7c7c7;
  height: 300px;
  text-align: initial;

  ${({ fullwidth }) =>
    fullwidth &&
    `
    border-radius: 0;
    border: 0;
    width: 100%;
  `}
`;

export const StyledCardContent = styled(CardContent)`
  padding: 0;
  height: 230px;
`;

export const StyledCardActions = styled(CardActions)`
  flex: 1;
  padding: 6px 8px;
  background-color: #f0f2f5;
`;
