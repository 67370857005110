import { OrderReportResponse } from 'api/orderManagment/types'
import { chowlyApi } from 'config'
import { generatePath } from 'react-router-dom'

import client from '../chowly'

export const getOrderReport = async ({
  partnerId,
  link = null,
  limit = 15,
  filter = '',
}: {
  partnerId: string
  link: string | null
  limit: number
  filter: string
}) => {
  const page = 1

  const endpoint = link || generatePath(chowlyApi.orderManagement)

  const { data } = await client.get<OrderReportResponse>(endpoint, {
    params: !link ? { partner_id: partnerId, page, limit, filter } : undefined,
  })

  return data
}
