import { ActionBar, Button } from 'components'

import * as Styled from './styles'

type Props = {
  onCancel: () => void
  onSave: () => void
}

const MenuEditBottomActionBar = ({ onCancel, onSave }: Props) => {
  return (
    <ActionBar position='bottom'>
      <Styled.ButtonContainer>
        <Button
          variant='textonly'
          narrow
          onClick={onCancel} // reset all menu updates
        >
          Cancel
        </Button>
        <Button variant='solid' narrow onClick={onSave}>
          Save
        </Button>
      </Styled.ButtonContainer>
    </ActionBar>
  )
}

export default MenuEditBottomActionBar
