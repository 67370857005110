import { 
  FacebookSuggestedCampaign, 
  FacebookSuggestedCampaignStatus, 
  InsightFeedback, 
  InsightFeedbackQuestion, 
  InsightStatus, 
  TargetableInsight 
} from "types/digitalMarketing";
import { UserFeedback, UserFeedbackSentiment } from "types/digitalMarketing/googleAd";

const AdditionalFeedbackQuestion = 'Any additional thoughts?';

const getSentimentIcon = (sentiment: UserFeedbackSentiment) => {
  if(sentiment === 'satisfied') return '🙂';
  if(sentiment === 'dissatisfied') return '😞';
  return '😐';
}

/**
 * Builds feedback object from a record of answers compatible with Suggested Campaigns and Insight objects.
 * @param answers the answers to build feedback from
 * @returns the feedback
 */
const buildFeedback = (
  answers: Record<string, UserFeedbackSentiment>,
  rccUserId?: string | null,
  additionalFeedback?: string
): InsightFeedback => {
  const questions = Object.entries(answers).map(([question, sentiment]) => ({
    question,
    value: getSentimentIcon(sentiment),
  } as InsightFeedbackQuestion))

  if(additionalFeedback){
    questions.push({
      question: AdditionalFeedbackQuestion,
      value: additionalFeedback,
    } as InsightFeedbackQuestion)
  }

  const feedback: InsightFeedback = {
    timestamp: new Date().getTime(),
    userId: rccUserId || undefined,
    questions,
  };

  return feedback;
}

/**
 * Updates an insight object with feedback.
 * @param insight - The insight to update
 * @param answers - The answers to the feedback questions
 * @param approved - Whether the insight was approved
 * @param rccUserId - The RCC user ID
 * @param additionalFeedback - Any additional feedback
 * @returns The updated insight object.
 */
export const updateInsightWithFeedback = (
  insight: TargetableInsight, 
  answers: Record<string, UserFeedbackSentiment>,
  approved: boolean,
  rccUserId?: string | null,
  additionalFeedback?: string
): TargetableInsight => {
  const updatedInsight: TargetableInsight = {
    ...insight,
    status: approved ? 'approved' : 'rejected' as InsightStatus,
    feedback: buildFeedback(answers, rccUserId, additionalFeedback),
  };

  return updatedInsight;
}

/**
 * Updates a suggested campaign object with feedback.
 * @param campaign - The suggested campaign to update
 * @param answers - The answers to the feedback questions
 * @param approved - Whether the insight was approved
 * @param rccUserId - The RCC user ID
 * @param additionalFeedback - Any additional feedback
 * @returns The updated suggested campaign
 */
export const updateSuggestedCampaignWithFeedback = (
  campaign: FacebookSuggestedCampaign,
  answers: Record<string, UserFeedbackSentiment>,
  approved: boolean,
  rccUserId?: string | null,
  additionalFeedback?: string
): FacebookSuggestedCampaign => {
  const updatedCampaign: FacebookSuggestedCampaign = {
    ...campaign,
    status: approved ? 'approved' : 'rejected' as FacebookSuggestedCampaignStatus,
    feedback: buildFeedback(answers, rccUserId, additionalFeedback),
  };

  return updatedCampaign;
}

/**
 * Builds google ad feedback from a record of answers
 * @param answers the answers to build feedback from
 * @returns the google ad feedback
 */
export const buildGoogleAdFeedback = (
  answers: Record<string, UserFeedbackSentiment>,
  extraFeedback?: string
): UserFeedback[] => {
  const questions = Object.entries(answers).map(([question, sentiment]) => ({
    question,
    sentiment,
  } as UserFeedback));

  if(extraFeedback){
    questions.push({
      question: AdditionalFeedbackQuestion,
      answer: extraFeedback,
    } as UserFeedback)
  }

  return questions;
}