import { AdRequestInfo, AdvancedLocationTargeting, RequireOnlyOne } from "./common";

export type AdCreatorType = 'admin' | 'user';
export type MarketingChannelType = 'facebook' | 'google' | 'tiktok' | 'mailchimp';

export type UserFeedbackSentiment = 'satisfied' | 'dissatisfied' | 'neutral';

export type UserFeedback = {
  question: string;
  sentiment?: UserFeedbackSentiment;
  answer?: string;
}

export type GoogleAdBudgetFields = {
  amount: number;
  cpc?: number;
}

export type GoogleAdBudget = GoogleAdBudgetFields & {
  /** id of the budget after publishing */
  id?: string;
}

export type AdError = {
  title: string;
  description: string;
  /** extra information about the error */
  details?: string;
}

export type GoogleAdGroupWithReferenceId = {
  /** 
   * the full resource name of an existing google ad group, Eg. `customers/1234/adGroups/5678`.
   **/
  referenceId: string;
  fields?: never;
}

export type GoogleAdGroupFields = {
  type: 'SEARCH_STANDARD';
  name: string;
  keywords: string[];
};

export type GoogleAdGroupWithFields = {
  referenceId?: never;
  /** define the specific settings required for the creation of the ad group */
  fields: GoogleAdGroupFields;
  /** id of the ad group in Google after publishing */
  id?: string;
  /** populated when the ad group fails to create */
  error?: AdError;
}

export type GoogleAdWithReferenceId = {
  /** the full resource name Eg. customers/1234/adGroups/5678 */
  referenceId: string;
  fields?: never;
}

export type GoogleLocationSettings = {
  resourceName: string;
  reach: string;
  targetType: string;
  canonicalName: string;
}

export type GoogleAdFields = {
  /** 
   * @deprecated use `advancedLocations.[location | radius]`
   * instead. Additionally, old campaigns with geo-target constants 
   * defined in the locationSettings are understood to be of 
   * the 'location' type.
   * 
   * This property will be removed in the next major release.
   **/
  type: 'radius' | 'location';
  name: string;
  biddingStrategyType: string;

  locationSettings?: GoogleLocationSettings[];
  advancedLocations?: AdvancedLocationTargeting[];
}

export type GoogleAdGroupAd = {
  /** id of the ad in google after creation */
  id?: string;
  url: string;
  headlines: string[];
  descriptions: string[];
  /** populated when the ad fails to create */
  error?: AdError;
};


export type GoogleAdGroup = GoogleAdGroupWithFields | GoogleAdGroupWithReferenceId;

export type GoogleAdWithFields = {
  referenceId?: never;
  fields: GoogleAdFields;
  /** id of the campaign in Google after publishing */
  id?: string;
  /** populated when the campaign fails to create */
  error?: AdError;
}

export type GoogleAdCampaign = GoogleAdWithFields | GoogleAdWithReferenceId;

export type GoogleAdCampaignInfo = {
  budget: GoogleAdBudget;
  campaign: GoogleAdCampaign;
  group: GoogleAdGroup;
  ad: GoogleAdGroupAd;
}

export const enum AdStatus {
  /** user or admin draft */
  Draft = 'draft',
  /** Interstitial status that is in between the ‘Admin Draft’ and ‘Scheduled’/'In Smartfeed' */
  Review = 'review',
  SmartFeed = 'smartFeed',
  /** user rejects suggested the ad */
  DraftRejected = 'draftRejected',
  /** user saves the suggested Ad */
  DraftApproved = 'draftApproved',
  /** the ad has been published to the marketing channel (Google or facebook) */
  Submitted = 'submitted',
  Live = 'live',
  Paused = 'paused',
  /** the ad exhausted all publishing attempts */
  Failed = 'failed',
  Stale = 'stale',
  Archived = 'archived',

  // Facebook statuses
  PreApproved = 'preApproved',
  InProcess = 'inProcess',
  Deleted = 'deleted',
}

export const enum PublishingOrigin {
  Targetable = 'targetable',
  User = 'user',
}

/** generic ad object */
export type GenericAd = {
  id: string;
  /** if of the business this ad belongs to */
  businessId: string;
  /** 
   * Id of the store this ad is associated with.
   * This is mainly used by ad-groups child ads.
   */
  storeId?: string;
  /**
   * This is to have a direct relationship between
   * Chowly’s "Location"/PartnerID object and Targetable’s StoreID object.
   */
  chowlyPartnerId?: string;
  /** id of the user that created the ad */
  userAuth0Id: string;
  /** marketing channel type */
  type: MarketingChannelType;
  /** generic name of the ad */
  name: string;
  /** publishes the ad as 'paused' */
  publishAsPaused: boolean;
  /** start date of the ad in ISO format */
  startDate?: string;
  /** end date of the ad in ISO format */
  endDate?: string;
  /** ISO date when the ad was created */
  createdAt: string;
  /** ISO date when the ad was updated */
  updatedAt: string;
  /** id of the user who performed the update */
  lastModifiedBy: string;
  /** lists the type of notifications produced for the given ad */
  notifications?: any[];
  /** 
   * Indicates the ad failed after it was submitted. 
   * Details about the failure cause should be extracted from
   * the configError property or the marketing type's sub-property, 
   * Eg. `google.campaign.error`
   **/
  withIssues: boolean;

  smartFeed?: {
    /** ISO date */
    expiryDate?: string;
    /** date it should appear on the smartFeed */
    displayDate?: string;
    /** if true, the expiry date is ignored */
    overrideAutoAging: boolean;
  };

  marketingChannelId: string;
  // Id of the original Ad this ad was created from.
  copiedFrom?: string;
  createdBy: AdCreatorType;
  status: AdStatus;
  /** 
   * contains a JSON string that can be used to re-create the whole object. 
   * The parsed string should map to a `GenericAdBase` type.
   **/
  originalObject: string;

  /** the date in ISO format whe the campaign was last attempted to publish */
  publishingLastAttemptAt?: string;
  /** number of times the campaign has been attempted to publish */
  publishingRetryCount: number;
  /** Contains the user feedback after an ad has been approves/rejected */
  feedback?: UserFeedback[];
  /** The id of the default questionnaire document to show users when providing feedback */
  questionnaireId?: string,

  /** 
   * Contains information about issues caused by configuration errors, 
   * that is, before it reaches the marketing channel itself.
   **/
  configError?: AdError;

  google?: GoogleAdCampaignInfo;
  /** Not used in Chowly */
  mailchimp?: never;
  /**
   * Indicates the origin of the ad, in other words, who created it.
   * Ads imported directly from the marketing channel are marked as 'user',
   * while ads created by the TGT platform (from either the Admin or User portals) are marked as 'targetable'.
   */
  publishingOrigin?: PublishingOrigin;
  /**
   * If provided, indicates the ad was created from an Ad Request.
   */
  requestInfo?: AdRequestInfo;
  /** Hold the date in ISO format when the record was "deleted" */
  deletedOn?: string;

  /** 
   * indicates this ad is a container of multiple ads 
   * or is part of a group of ads.
   **/
  group?: RequireOnlyOne<{
    /** 
     * the id of the group this ad belongs to. If present,
     * this ad is a member of a group of ads.
     **/
    sourceGroupId: string;
    /** 
     * the ids of the ads that are part of this group. If present,
     * this ad is the container of a group of ads.
     **/
    ads: string[];
  }, 'ads' | 'sourceGroupId'>;
}

// These are statuses from the Google Ads Platform
export declare type GoogleCampaignStatus = 'ENABLED' | 'PAUSED' | 'REMOVED' | 'UNKNOWN' | 'UNSPECIFIED';
export declare type PauseGoogleCampaignResponse = GenericAd;