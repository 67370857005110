import { FormattedMenuSectionInfo, FormattedMenuSectionInfoWithParent } from 'api'
import TRASH_ICON from 'assets/icons/trash-icon.svg'
import { Button } from 'components'
import { useIsMobile } from 'hooks'
import { useEffect, useState } from 'react'

import { ModifierValuesMap } from '../MenuSectionEditor'
import * as Styled from '../styles'
import { createDefaultGroupInfo, getGroupModifierValues } from './index'
import NestedGroupAndMods from './NestedGroupAndMods'

type Props = {
  modifierGroupDetails: FormattedMenuSectionInfo
  setModifierGroupDetails: React.Dispatch<
    React.SetStateAction<FormattedMenuSectionInfo | undefined>
  >
  handleStageModifierGroup: ({
    nestedGroups,
    nestedModifiers,
    isNew,
  }: {
    nestedGroups: FormattedMenuSectionInfo[]
    nestedModifiers: ModifierValuesMap
    isNew?: boolean
  }) => void
  modifierValues: ModifierValuesMap
  setModifierValues: React.Dispatch<React.SetStateAction<ModifierValuesMap>>
  removeGroupModifier: ({
    group,
    modId,
  }: {
    group: FormattedMenuSectionInfo
    modId: string
  }) => void
  createGroupModifier: (group: FormattedMenuSectionInfo) => void
  handleCancelEditGroup: () => void
  isNew?: boolean
}

// removes negative signs (html input validation not working)
export const validateInput = (value: string) => {
  const absValue = value.replace('-', '')

  if (absValue) {
    return parseInt(absValue)
  }
  return undefined
}

const EditableModifierGroupForm = ({
  modifierGroupDetails,
  setModifierGroupDetails,
  handleStageModifierGroup,
  modifierValues,
  setModifierValues,
  removeGroupModifier,
  createGroupModifier,
  handleCancelEditGroup,
  isNew,
}: Props) => {
  const { isMobile } = useIsMobile()
  const [nestedGroups, setNestedGroups] = useState<FormattedMenuSectionInfoWithParent[]>([])
  const [nestedModifiers, setNestedModifiers] = useState<ModifierValuesMap>({})

  // Prefills the nested groups and modifiers from the combined existing group object that we are now editing
  useEffect(() => {
    if (isNew === false) {
      // extract nested groups and mods
      const existingNestedGroups: FormattedMenuSectionInfoWithParent[] = []
      const existingNestedMods: ModifierValuesMap = {}

      modifierGroupDetails.children.customization_options?.forEach((option) =>
        option.children.customizations?.forEach((nestedGroup) => {
          existingNestedGroups.push({ ...nestedGroup, parentModId: option.id })

          nestedGroup.children.customization_options?.forEach((nestedMod) => {
            existingNestedMods[nestedMod.id] = {
              title: nestedMod.attributes.title || '',
              price: nestedMod.attributes.price || 0,
              parentGroupId: nestedGroup.id,
              isNested: true,
            }
          })
        }),
      )

      setNestedGroups(existingNestedGroups)
      setNestedModifiers(existingNestedMods)
    }
  }, [isNew])

  const groupModifierValues = getGroupModifierValues(modifierGroupDetails.id, modifierValues)

  const createNestedGroup = (tempModId: string) => {
    const nestedGroupDefaultInfo = createDefaultGroupInfo({ parentModId: tempModId })

    setNestedGroups([...nestedGroups, nestedGroupDefaultInfo])
  }

  // remove nested groups from modifier
  // these changes have not been staged/added to the main Item object yet
  const removeNestedGroupAndMods = (nestedGroup: FormattedMenuSectionInfo) => {
    const idxToRemove = nestedGroups.findIndex((group) => group.id === nestedGroup.id)
    const updatedNestedGroups = [...nestedGroups]
    if (idxToRemove !== -1) {
      updatedNestedGroups.splice(idxToRemove, 1)

      setNestedGroups(updatedNestedGroups)
    }

    // remove nested mods for this group
    const filteredNestedMods = Object.entries(nestedModifiers)?.filter(
      ([id, _values]) => !id.endsWith(nestedGroup.id),
    )
    setNestedModifiers(Object.fromEntries(filteredNestedMods))
  }

  return (
    <form
      style={{ border: '1px solid #1B51A4', borderRadius: 12, padding: '24px' }}
      role='form'
      onSubmit={(e) => {
        e.preventDefault()
        handleStageModifierGroup({ nestedGroups, nestedModifiers, isNew })
      }}
    >
      <Styled.ItemViewProductTitle>Modifier Group Name</Styled.ItemViewProductTitle>
      <div style={{ marginTop: 8, marginBottom: 16 }}>
        <Styled.Input
          height='47px'
          value={modifierGroupDetails.attributes.title}
          onChange={(e) => {
            setModifierGroupDetails({
              ...modifierGroupDetails,
              attributes: {
                ...modifierGroupDetails.attributes,
                title: e.target.value,
              },
            })
          }}
          required
        />
      </div>
      <div style={{ display: 'flex', gap: 12 }}>
        <div style={{ display: 'flex', flexDirection: 'column', gap: 8 }}>
          <Styled.ItemViewProductTitle>Minimum Selection</Styled.ItemViewProductTitle>
          <Styled.Input
            height='47px'
            width={isMobile ? '100%' : '170px'}
            type='number'
            min='0'
            value={modifierGroupDetails.attributes.min_permitted}
            onInput={(e: React.ChangeEvent<HTMLInputElement>) => {
              const newValue = validateInput(e.target.value)
              let maxPermitted = modifierGroupDetails?.attributes?.max_permitted

              // validation to ensure max value is never less than min value
              if (maxPermitted !== undefined && (newValue || 0) > maxPermitted) {
                maxPermitted = newValue
              }

              if (maxPermitted === undefined) {
                maxPermitted = newValue
              }

              setModifierGroupDetails({
                ...modifierGroupDetails,
                attributes: {
                  ...modifierGroupDetails.attributes,
                  min_permitted: newValue,
                  max_permitted: maxPermitted,
                },
              })
            }}
            onChange={(e) => {
              const newValue = validateInput(e.target.value)
              let maxPermitted = modifierGroupDetails?.attributes?.max_permitted

              // validation to ensure max value is never less than min value
              if (maxPermitted !== undefined && (newValue || 0) > maxPermitted) {
                maxPermitted = newValue
              }

              if (maxPermitted === undefined) {
                maxPermitted = newValue
              }

              setModifierGroupDetails({
                ...modifierGroupDetails,
                attributes: {
                  ...modifierGroupDetails.attributes,
                  min_permitted: newValue,
                  max_permitted: maxPermitted,
                },
              })
            }}
          />
        </div>
        <div style={{ display: 'flex', flexDirection: 'column', gap: 8 }}>
          <Styled.ItemViewProductTitle>Maximum Selection</Styled.ItemViewProductTitle>
          <Styled.Input
            height='47px'
            width={isMobile ? '100%' : '170px'}
            type='number'
            min='0'
            value={modifierGroupDetails?.attributes.max_permitted}
            onInput={(e: React.ChangeEvent<HTMLInputElement>) => {
              const newValue = validateInput(e.target.value)
              let minPermitted = modifierGroupDetails?.attributes.min_permitted

              // validate to ensure min permitted is never more than max permitted
              if (minPermitted !== undefined && (newValue || 0) < minPermitted) {
                minPermitted = newValue
              }

              setModifierGroupDetails({
                ...modifierGroupDetails,
                attributes: {
                  ...modifierGroupDetails.attributes,
                  max_permitted: newValue,
                  min_permitted: minPermitted,
                },
              })
            }}
            onChange={(e) => {
              const newValue = validateInput(e.target.value)
              let minPermitted = modifierGroupDetails?.attributes.min_permitted

              // validate to ensure min permitted is never more than max permitted
              if (minPermitted !== undefined && (newValue || 0) < minPermitted) {
                minPermitted = newValue
              }

              setModifierGroupDetails({
                ...modifierGroupDetails,
                attributes: {
                  ...modifierGroupDetails.attributes,
                  max_permitted: newValue,
                  min_permitted: minPermitted,
                },
              })
            }}
          />
        </div>
      </div>
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          gap: 24,
          marginTop: '24px',
        }}
      >
        <Styled.ItemViewProductTitle>Modifiers</Styled.ItemViewProductTitle>
        <div style={{ display: 'flex', flexDirection: 'column', flex: 1 }}>
          {Object.entries(groupModifierValues).map(([modId, values]) => {
            const nestedGroupsForMod = nestedGroups?.filter((group) => group.parentModId === modId)

            return (
              <div style={{ display: 'flex', flexDirection: 'column', gap: 24 }} key={modId}>
                <div
                  style={{
                    marginTop: 8,
                    marginBottom: 16,
                    display: 'flex',
                    alignItems: 'flex-end',
                    gap: 12,
                  }}
                >
                  <div style={{ display: 'flex', flexDirection: 'column', gap: 8 }}>
                    <Styled.ItemViewProductTitle>Title</Styled.ItemViewProductTitle>
                    <Styled.Input
                      height='47px'
                      value={values.title}
                      onChange={(e) => {
                        const changedModValues = {
                          ...modifierValues,
                          [modId]: {
                            ...modifierValues[modId],
                            title: e.target.value,
                          },
                        }

                        setModifierValues(changedModValues)
                      }}
                      required
                    />
                  </div>
                  <div style={{ display: 'flex', flexDirection: 'column', gap: 8 }}>
                    <Styled.ItemViewProductTitle>Price</Styled.ItemViewProductTitle>
                    <div style={{ display: 'flex', alignItems: 'center' }}>
                      <Styled.Input
                        height='47px'
                        value={values.price}
                        type='number'
                        step='0.01'
                        pattern='[0-9]*'
                        min='0'
                        onChange={(e) => {
                          const changedModValues = {
                            ...modifierValues,
                            [modId]: {
                              ...modifierValues[modId],
                              price: e.target.value ? parseFloat(e.target.value) : undefined,
                            },
                          }
                          setModifierValues(changedModValues)
                        }}
                      />

                      <Styled.IconButton
                        margin='0 10px 0 10px'
                        onClick={() =>
                          removeGroupModifier({ group: modifierGroupDetails, modId: modId })
                        }
                      >
                        <img
                          src={TRASH_ICON}
                          width='16px'
                          alt='delete'
                          style={{ maxWidth: '16px' }}
                        />
                      </Styled.IconButton>
                    </div>
                  </div>
                </div>
                {nestedGroupsForMod?.map((nestedGroup) => {
                  return (
                    <NestedGroupAndMods
                      key={nestedGroup.id}
                      nestedModifierGroupDetails={nestedGroup}
                      setNestedModifierGroupDetails={(newGroupDetails) => {
                        const updatedGroups = [...nestedGroups].map((group) => {
                          if (group.id === newGroupDetails.id) {
                            return newGroupDetails
                          }
                          return group
                        })

                        setNestedGroups(updatedGroups)
                      }}
                      nestedModifiers={nestedModifiers}
                      setNestedModifiers={setNestedModifiers}
                      removeNestedGroupAndMods={removeNestedGroupAndMods}
                    />
                  )
                })}
                <div style={{ marginLeft: '12px' }}>
                  <Button
                    size='small'
                    narrow
                    type='button'
                    variant='textonly'
                    onClick={(e) => {
                      e.preventDefault()
                      createNestedGroup(modId)
                    }}
                  >
                    <u>Add Nested Group</u>
                  </Button>
                </div>
              </div>
            )
          })}
        </div>
      </div>
      <Button
        size='small'
        narrow
        type='button'
        variant='textonly'
        onClick={(e) => {
          e.preventDefault()
          createGroupModifier(modifierGroupDetails)
        }}
      >
        <u>Add Modifier</u>
      </Button>

      <div
        style={{
          width: '100%',
          display: 'flex',
          justifyContent: 'flex-end',
          gap: 16,
        }}
      >
        <Button
          type='button'
          onClick={() => handleCancelEditGroup()}
          variant='textonly'
          danger
          narrow
        >
          Cancel
        </Button>
        <Button narrow type='submit'>
          Add
        </Button>
      </div>
    </form>
  )
}

export default EditableModifierGroupForm
