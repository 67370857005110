import React from 'react'

import FacebookAdCard from '../FacebookAdCard/FacebookAdCard'

interface FacebookCardProps {
  type: 'map' | 'profile' | 'image'
  image?: string | null
  title?: string
  description?: string
  buttonLabel?: string
  cardLink?: string
  adType?: string
}

const FacebookCard: React.FC<FacebookCardProps> = ({
  type,
  image = null,
  title = '',
  description = '',
  buttonLabel = '',
  cardLink = '',
  adType = '',
}) => {
  switch (type) {
    case 'map':
      return <>{/* Render content for map type if needed */}</>
    case 'image':
    default:
      return (
        <FacebookAdCard
          adType={adType}
          image={image}
          title={title}
          cardLink={cardLink}
          description={description}
          buttonLabel={buttonLabel}
        />
      )
  }
}

export default FacebookCard
