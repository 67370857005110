import InfoIcon from '@mui/icons-material/Info'
import { CardContent, Grid, Skeleton, Tooltip } from '@mui/material'
import { ReactComponent as SaleIcon } from 'assets/icons/sale-icon.svg'
import { useDigitalMarketingApi } from 'hooks'
import _ from 'lodash'
import React, { useEffect, useState } from 'react'
import {
  AccountAsset,
  AssetType,
  AssetTypes,
  CampaignAsset,
} from 'types/digitalMarketing/googleAssets'

import {
  Bullet,
  ContentText,
  HeaderRoot,
  HeaderTitle,
  HeaderUrl,
  HeaderUrlAd,
  HeaderUrlInfo,
  HeaderUrlLink,
  InfoIconStyled,
  InfoRemovedIcon,
  PromotionDescriptionText,
  PromotionWrapper,
  SellIcon,
  SiteLink,
  SiteLinkWrapper,
  SkeletonWrapper,
  StyledCard,
  StyledLinearProgress,
} from './styles'

export interface GoogleFeedAd {
  id: string
  url?: string
  headlines?: string[]
  descriptions?: string[]
  impressions?: number
  percentage?: number
  enabled?: boolean
}

interface GoogleFeedProps {
  ad: GoogleFeedAd
  campaign: {
    id?: string
    referenceId?: string
  }
  onClick?: () => void
  marketingChannelId: string
  partnerId: string
}

const GoogleFeed: React.FC<GoogleFeedProps> = ({
  ad,
  campaign,
  onClick,
  marketingChannelId,
  partnerId,
}) => {
  const [fetchGoogleAssets] = useDigitalMarketingApi(partnerId)
  const content = ad.descriptions?.[0] || ''
  const url = ad.url || ''
  const [siteLinks, setSiteLinks] = useState<AccountAsset[] | null>(null)
  const [promotions, setPromotions] = useState<CampaignAsset[] | AccountAsset[] | null>(null)
  const [loadingSitelinks, setLoadingSitelinks] = useState(false)
  const [loadingPromotions, setLoadingPromotions] = useState(false)

  useEffect(() => {
    const getCampaignAssetsByCampaignId = (campaignAssets: CampaignAsset[]) =>
      campaignAssets?.filter(
        (prom) =>
          prom?.campaign?.id === campaign?.referenceId ||
          prom?.campaign?.resourceName === campaign?.id,
      ) || []

    const loadAssets = async (
      assetType: AssetType,
      setAsset: (asset: CampaignAsset[] | AccountAsset[]) => void,
      setLoading: (state: boolean) => void,
    ) => {
      if (!marketingChannelId) return
      setLoading(true)
      try {
        // Safe to invoke here since the invoke function guarantees not to resolve if unmounted
        const { data, error } = await fetchGoogleAssets(
          'getGoogleSearchCampaignAssets',
          marketingChannelId,
          assetType,
        )
        if (data && !error) {
          if (assetType === AssetTypes.Sitelink) {
            setAsset(data?.accountAssets || [])
          } else if (assetType === AssetTypes.Promotion) {
            setAsset([
              // Promotions associated to the current Campaign
              ...getCampaignAssetsByCampaignId(data?.campaignAssets || []),
              ...(data?.accountAssets || []),
            ])
          }
        } else {
          setAsset([])
        }
      } catch (err) {
        console.log('Error loading Google Assets', err)
      } finally {
        setLoading(false)
      }
    }

    if (!loadingSitelinks && !siteLinks) {
      loadAssets(AssetTypes.Sitelink, setSiteLinks, setLoadingSitelinks)
    }

    if (!loadingPromotions && !promotions) {
      loadAssets(AssetTypes.Promotion, setPromotions, setLoadingPromotions)
    }
  }, [
    fetchGoogleAssets,
    marketingChannelId,
    campaign,
    siteLinks,
    promotions,
    loadingSitelinks,
    loadingPromotions,
  ])

  const buildSkeleton = () => (
    <SkeletonWrapper>
      <Grid item>
        <Skeleton variant='text' width='50%' height={30} />
      </Grid>
      <Grid item>
        <Skeleton variant='text' width='50%' height={30} />
      </Grid>
      <Grid item>
        <Skeleton variant='text' width='50%' height={30} />
      </Grid>
    </SkeletonWrapper>
  )

  const generatePromotionText = (promotion: any, index: number) => {
    const { promotionAsset } = promotion
    let mainText = ''
    let additionalText = ''

    if (promotionAsset?.discountModifier === 'PERCENT_OFF') {
      const percentOff = (promotionAsset?.percentOff / 10000).toFixed(0)
      mainText = `${percentOff}% off ${promotionAsset?.promotionTarget}`
    } else if (promotionAsset?.moneyAmountOff) {
      const amountOff = (promotionAsset?.moneyAmountOff.amountMicros / 1000000).toFixed(2)
      mainText = `${amountOff} off ${promotionAsset?.promotionTarget}`
    }

    if (promotionAsset?.promotionCode) {
      additionalText += ` with code ${promotionAsset?.promotionCode}`
    }
    if (promotionAsset?.ordersOverAmount) {
      const ordersOver = (promotionAsset?.ordersOverAmount.amountMicros / 1000000).toFixed(2)
      additionalText += `\nOn orders over $${ordersOver}`
    }

    return (
      <Grid container key={index}>
        <Grid item sm={1}>
          <SellIcon>
            <SaleIcon />
          </SellIcon>
        </Grid>
        <Grid item sm={11}>
          <PromotionDescriptionText>{mainText}</PromotionDescriptionText>
          <ContentText>{additionalText}</ContentText>
        </Grid>
      </Grid>
    )
  }

  return (
    <>
      <StyledCard variant='outlined' onClick={onClick}>
        <HeaderUrl container wrap='nowrap' spacing={1}>
          <HeaderUrlAd>Ad</HeaderUrlAd>
          <Bullet />
          <HeaderUrlLink noWrap>{url}</HeaderUrlLink>
          <HeaderUrlInfo>
            <InfoIconStyled as={InfoIcon} />
          </HeaderUrlInfo>
        </HeaderUrl>
        <HeaderRoot>
          <HeaderTitle>{ad?.headlines?.slice(0, 3)?.join(' | ')}</HeaderTitle>
        </HeaderRoot>
        {content && (
          <CardContent>
            <ContentText>{_.truncate(content, { length: 93 })}</ContentText>
          </CardContent>
        )}
        {(loadingPromotions || loadingSitelinks) && buildSkeleton()}
        {promotions && promotions.length > 0 && (
          <PromotionWrapper>
            {promotions.map((promo, index) => generatePromotionText(promo, index))}
          </PromotionWrapper>
        )}
        {siteLinks && siteLinks.length > 0 && (
          <SiteLinkWrapper>
            {siteLinks.map((asset, index) => (
              <SiteLink key={asset.name || index}>{asset?.sitelinkAsset?.linkText}</SiteLink>
            ))}
          </SiteLinkWrapper>
        )}
      </StyledCard>
      {ad?.impressions && ad?.percentage && (
        <>
          <StyledLinearProgress variant='determinate' value={ad?.percentage} />
          <ContentText>
            {`${ad?.impressions} impressions (${ad?.percentage}%)`}
            {!ad?.enabled && (
              <>
                {' - '}
                {'Removed'}
                <Tooltip title='This ad isn’t showing anymore because one of its assets is no longer available.'>
                  <InfoRemovedIcon as={InfoIcon} />
                </Tooltip>
              </>
            )}
          </ContentText>
        </>
      )}
    </>
  )
}

export default GoogleFeed
