import { chowlyApi } from 'config'
import { generatePath } from 'react-router'

import client from '../chowly'
import {
  MenuMappingCustomization,
  MenuMappingItem,
  MenuMappingItemInput,
  MenuMappingItemResource,
  MenuMappingItemResponse,
  MenuMappingMenuResource,
  MenuMappingSubsection,
  TypeName,
} from '../models'

type QueryParams = { include: string }

export const getMenuMappingItem = async (menuMappingItemId: string, params?: QueryParams) => {
  const url = generatePath(chowlyApi.menuMappingItem, { id: menuMappingItemId })
  const { data } = await client.get<MenuMappingItemResponse>(url, { params })
  return data
}

type ItemPayloadProps = {
  itemId?: MenuMappingItemResource['id']
  attributes: MenuMappingItem
  itemCustomizations?: MenuMappingCustomization['id'][]
  customizations?: MenuMappingCustomization['id'][]
  subsections?: MenuMappingSubsection['id'][]
  menuId?: MenuMappingMenuResource['id']
}

export const createMenuMappingItem = async ({
  attributes,
  menuId,
  itemCustomizations,
  customizations,
  subsections,
}: ItemPayloadProps) => {
  const url = generatePath(chowlyApi.menuMappingItems)

  const payload = formatMenuMappingItemPayload({
    attributes,
    menuId,
    itemCustomizations,
    customizations,
    subsections,
  })

  const { data } = await client.post<MenuMappingItemResponse>(url, payload)
  return data
}

export const updateMenuMappingItem = async ({
  itemId,
  attributes,
  itemCustomizations,
  customizations,
  subsections,
}: ItemPayloadProps) => {
  const payload = formatMenuMappingItemPayload({
    attributes,
    itemCustomizations,
    customizations,
    subsections,
  })

  const url = generatePath(chowlyApi.menuMappingItem, { id: itemId })

  const { data } = await client.patch<MenuMappingItemResource>(url, payload)

  return data
}

// hard deletes the item
export const deleteMenuMappingItem = async (itemId: MenuMappingItemResource['id']) => {
  const url = generatePath(chowlyApi.menuMappingItem, { id: itemId })

  const response = await client.delete(url)

  return response
}

// soft deletion of the item under the specific subsection
// the destroy item endpoint deletes the relationship (not the item object) when a subsection_id is provided
export const removeMenuMappingItemRelationship = async ({
  itemId,
  subsectionId,
}: {
  itemId: MenuMappingItemResource['id']
  subsectionId: MenuMappingSubsection['id']
}) => {
  const url = generatePath(chowlyApi.menuMappingItem, { id: itemId })
  const urlWithQueryParams = `${url}?subsection_id=${subsectionId}`

  const response = await client.delete<MenuMappingItemResponse>(urlWithQueryParams)

  return response
}

const formatMenuMappingItemPayload = ({
  attributes,
  menuId,
  itemCustomizations,
  customizations,
  subsections,
}: ItemPayloadProps): MenuMappingItemInput => {
  return {
    data: {
      type: TypeName.MenuMappingItem,
      attributes,
      relationships: {
        ...(menuId && {
          menu_id: {
            data: { id: menuId, type: TypeName.MenuMappingMenu },
          },
        }),
        ...(subsections && {
          subsections: {
            data: subsections.map((id) => ({ id, type: TypeName.MenuMappingSubsection })),
          },
        }),
        ...(itemCustomizations && {
          item_customizations: {
            data: itemCustomizations?.map((id) => ({
              id,
              type: TypeName.MenuMappingCustomization,
            })),
          },
        }),
        ...(customizations && {
          customizations: {
            data: customizations?.map((id) => ({ id, type: TypeName.MenuMappingCustomization })),
          },
        }),
      },
    },
  }
}
