import DMBaseButton from './DMBaseButton';
import { styled } from '@mui/material/styles';
import { ButtonProps } from '@mui/material/Button';

const GreenButtonWrapper = styled(DMBaseButton)`
  ${props => props.variant === 'contained' && `background-color: #32965D; color: #FAFAFA;`}
  ${props => props.variant === 'outlined' && `border: 1px solid #32965D; color: #32965D;`}
  ${props => props.variant === 'text' && `color: #32965D;`}
  
  &:hover {
    background-color: ${props => props.variant === 'contained' ? '#32965D' : 'rgba(50, 150, 93, 0.1)'};
  }
`

/**
 * A styled MUI button with a green background. Default variant is 'contained'.
 */
const GreenButton = (props: ButtonProps) => {
  return <GreenButtonWrapper variant="contained" {...props} />
}

export default GreenButton