import { FormattedMenuSectionInfo, MenuMappingSubsection } from 'api'
import { removeMenuMappingCustomizationOptionRelationship } from 'api/menuMapping/customizationOptions'
import { removeMenuMappingItemRelationship } from 'api/menuMapping/items'
import { deleteMenuMappingSubsection } from 'api/menuMapping/subsections'
import { ExpandModifiersMap } from 'api/menuMapping/types'
import EDIT_ICON from 'assets/icons/edit-clear-icon.svg'
import TRASH_ICON from 'assets/icons/trash-icon.svg'
import { Button } from 'components'
import { Fragment, useEffect, useRef, useState } from 'react'
import { toast } from 'react-toastify'

import MenuItemRow from './MenuItemRow'
import MenuItemViewModal from './MenuItemViewModal'
import { ItemPriceMap, ModifierValuesMap } from './MenuSectionEditor'
import ModifierSubsectionEditor from './ModifierSubsectionEditor'
import * as Styled from './styles'

type Props = {
  subsection: FormattedMenuSectionInfo
  isLoading: boolean
  expandAllModifiers?: boolean
  categoryName: string
  setCategoryName: (id: string, value: string) => void
  itemPrices: ItemPriceMap
  setItemPrices: (id: string, value?: number) => void
  modifierValues: ModifierValuesMap
  onChangeModifierValues: ({
    modId,
    price,
    title,
  }: {
    modId: string
    price?: number
    title?: string
  }) => void
  refreshMenuSection: () => void
}

const MenuSubsectionEditor = ({
  subsection,
  isLoading,
  expandAllModifiers,
  categoryName,
  setCategoryName,
  itemPrices,
  setItemPrices,
  refreshMenuSection,
  modifierValues,
  onChangeModifierValues,
}: Props) => {
  const [sectionItems, setSectionItems] = useState<FormattedMenuSectionInfo[]>([])
  const [expandModifiers, setExpandModifiers] = useState<ExpandModifiersMap>({})
  const [isEditCategory, setEditCategory] = useState<{ [categoryId: string]: boolean }>({})
  const [itemViewModalOpen, setItemViewModalOpen] = useState<boolean>(false)
  const [selectedItemInModal, setSelectedItemInModal] = useState<FormattedMenuSectionInfo>()
  const inputRef = useRef<HTMLInputElement | null>(null)

  useEffect(() => {
    if (subsection.children.items?.length) {
      setSectionItems(subsection.children.items)
    }
  }, [subsection])

  useEffect(() => {
    if (selectedItemInModal) {
      setItemViewModalOpen(true)
    }
  }, [selectedItemInModal])

  useEffect(() => {
    if (expandAllModifiers) {
      const allItemsWithChildrenIds = sectionItems
        ?.filter((item) => item.children.customizations?.length)
        ?.map((item) => item.id)
      const allItemsMap: ExpandModifiersMap = {}
      allItemsWithChildrenIds?.forEach((id) => {
        allItemsMap[id] = true
      })

      setExpandModifiers(allItemsMap)
    } else {
      setExpandModifiers({})
    }
  }, [expandAllModifiers])

  // categories are instantly deleted / not staged.
  const onDeleteCategory = async (subsection: FormattedMenuSectionInfo) => {
    try {
      const res = await deleteMenuMappingSubsection(subsection.id)

      if (res) {
        toast.success(`The category "${subsection.attributes.title}" was deleted.`, {
          autoClose: 5000,
        })
        refreshMenuSection()
      }
    } catch (err) {
      console.error('deleteMenuMappingSubsection', err)
      toast.error(
        `There was an error deleting your category "${subsection.attributes.title}". Please try again later.`,
        { autoClose: 5000 },
      )
    }
  }

  // We aren't hard deleting items, only removing the subsection relationship so they can still be searched for.
  const onRemoveItemFromSubsection = async (
    item: FormattedMenuSectionInfo,
    subsectionId: MenuMappingSubsection['id'],
  ) => {
    try {
      await removeMenuMappingItemRelationship({
        itemId: item.id,
        subsectionId,
      })

      toast.success(`The product "${item.attributes.title}" was removed from this section.`, {
        autoClose: 5000,
      })
      refreshMenuSection()
    } catch (err) {
      toast.error(
        `There was an error removing your product "${item.attributes.title}". Please try again later.`,
        { autoClose: 5000 },
      )
    }
  }

  /**
   * @param modId - modifier (customization_option) id
   * @param customization - group object
   */
  const onRemoveModifierFromCustomization = async ({
    modId,
    customization,
  }: {
    modId: string
    customization: FormattedMenuSectionInfo
  }) => {
    const { min_permitted } = customization.attributes
    const numModifiersInGroup = customization.children.customization_options || []

    if (min_permitted !== undefined && min_permitted < numModifiersInGroup.length - 1) {
      toast.error(
        'The modifier cannot be deleted because the minimum number of modifiers would not be met. Please change the minimum and try again.',
        { autoClose: 5000 },
      )
      return
    }

    if (numModifiersInGroup.length - 1 < 1) {
      toast.error('This modifier cannot be deleted because a group requires at least 1 modifier.', {
        autoClose: 5000,
      })
      return
    }

    try {
      const res = await removeMenuMappingCustomizationOptionRelationship({
        modifierId: modId,
        customizationId: customization.id,
      })

      if (res) {
        toast.success(`The modifier was removed from this group.`, {
          autoClose: 5000,
        })
        refreshMenuSection()
      }
    } catch (err) {
      toast.error(`There was an error removing your modifier. Please try again later.`, {
        autoClose: 5000,
      })
    }
  }

  const toggleEditCategory = (subsectionId: string) => {
    const editableCategories = { ...isEditCategory }
    if (subsectionId in editableCategories) {
      setEditCategory({
        ...editableCategories,
        ...{ [subsectionId]: !editableCategories[subsectionId] },
      })
    } else {
      setEditCategory({ ...editableCategories, ...{ [subsectionId]: true } })
    }
  }

  useEffect(() => {
    if (inputRef.current) {
      inputRef.current?.focus()
    }
  }, [isEditCategory[subsection.id]])

  return (
    <Styled.SubsectionContainer key={subsection.id}>
      <Styled.HeaderRow>
        <div style={{ display: 'flex', flex: 1 }}>
          {isEditCategory[subsection.id] ? (
            <Styled.Input
              ref={inputRef}
              // defaultValue={categoryName}
              value={categoryName}
              width='200px'
              onChange={(e) => setCategoryName(subsection.id, e.target.value)}
              onBlur={() => toggleEditCategory(subsection.id)}
              onKeyDown={(e) => {
                if (e.key === 'Enter') {
                  toggleEditCategory(subsection.id)
                }
              }}
            />
          ) : (
            <>
              <Styled.SectionTitle marginRight='10px'>{categoryName}</Styled.SectionTitle>
              <Styled.IconButton
                onClick={() => {
                  toggleEditCategory(subsection.id)
                }}
              >
                <img src={EDIT_ICON} alt='edit' />
              </Styled.IconButton>
            </>
          )}
        </div>

        <Styled.IconButton margin='0 10px 0 10px'>
          <img
            src={TRASH_ICON}
            alt='delete'
            onClick={() => onDeleteCategory(subsection)}
            style={{ maxWidth: '16px' }}
          />
        </Styled.IconButton>
      </Styled.HeaderRow>
      {sectionItems.map((item, idx) => {
        const modifierGroups = item.children.customizations

        return (
          <Fragment key={item.id}>
            <MenuItemRow
              item={item}
              itemIdx={idx}
              isEditor
              isModifiersLoading={isLoading}
              onDeleteItem={(item) => {
                onRemoveItemFromSubsection(item, subsection.id)
              }}
              modifiersExist={!!modifierGroups?.length}
              onRowExpand={() =>
                setExpandModifiers((prevExpandStatus) => ({
                  ...prevExpandStatus,
                  [item.id]: !prevExpandStatus[item.id],
                }))
              }
              isExpanded={expandModifiers[item.id]}
              itemPrice={itemPrices[`${item.id}:${subsection.id}`]?.price}
              itemSubsectionId={`${item.id}:${subsection.id}`}
              onChangeItemPrice={setItemPrices}
              setSelectedItemInModal={setSelectedItemInModal}
            />

            {/* Display modifier groups with their modifiers */}
            {expandModifiers[item.id]
              ? modifierGroups?.map((group) => {
                  const groupModifiers = group.children.customization_options

                  return (
                    <ModifierSubsectionEditor
                      key={group.id}
                      customization={group}
                      groupModifiers={groupModifiers}
                      expandAllModifiers={expandAllModifiers}
                      modifierValues={modifierValues}
                      onChangeModifierValues={onChangeModifierValues}
                      onDeleteModifier={(modId, customization) => {
                        onRemoveModifierFromCustomization({ modId, customization })
                      }}
                    />
                  )
                })
              : null}
          </Fragment>
        )
      })}
      <Styled.HeaderRow>
        <div style={{ display: 'flex', flex: 1 }}>
          <Button
            size='small'
            narrow
            variant='textonly'
            onClick={() => {
              setItemViewModalOpen(true)
            }}
          >
            <u>Add Product</u>
          </Button>
        </div>
      </Styled.HeaderRow>
      {itemViewModalOpen && (
        <MenuItemViewModal
          isEditable
          item={selectedItemInModal}
          onClose={() => {
            setSelectedItemInModal(undefined)
            setItemViewModalOpen(false)
          }}
          isOpen={itemViewModalOpen}
          setSelectedItemInModal={setSelectedItemInModal}
          refreshMenuSection={refreshMenuSection}
          setItemViewModalOpen={setItemViewModalOpen}
          subsectionId={subsection.id}
        />
      )}
    </Styled.SubsectionContainer>
  )
}

export default MenuSubsectionEditor
