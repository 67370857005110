import { StyledLabel } from 'components/AdPreviews/Disclaimer/styles'
import FacebookAdPreviewStepper from 'components/FacebookAdPreviewStepper/FacebookAdPreviewStepper'
import { DineroObject } from 'dinero.js'
import _ from 'lodash'
import moment from 'moment'
import {
  AdBudgetType,
  AdBudgetTypes,
  AdTargetingTypes,
  FacebookCampaignInfo,
} from 'types/digitalMarketing'
import { FacebookLocation, TargetableStore } from 'types/digitalMarketing/store'
import { formatCurrency, getDateFromSchedule, getFacebookAddress, sumLifetime } from 'utils/dmUtils'

import { AdTypeSpan, AdTypeValue, StyledSubtitle, StyledTypography } from './styles'

interface FacebookAdDetailsProps {
  campaign: FacebookCampaignInfo
  associatedStore: TargetableStore
}

const getAdScheduleText = (campaign: FacebookCampaignInfo): string => {
  const getScheduleText = (startDate: string, endDate: string) =>
    `This ad will start on: ${startDate} and end on: ${endDate}`
  const schedule = campaign?.ads?.[0]?.schedule
  const isEvergreen = !schedule?.endDate
  const startDate = moment(getDateFromSchedule(schedule, 'startDate')).format('MM/DD/YYYY')
  const endDate = moment(getDateFromSchedule(schedule, 'endDate'))?.format('MM/DD/YYYY')
  return getScheduleText(startDate, isEvergreen ? 'No End Date (Evergreen)' : endDate)
}

const getAdBudgetText = (campaign: FacebookCampaignInfo): string => {
  const budget = (campaign?.ads?.[0]?.budget || { amount: 0 }) as DineroObject & {
    type: AdBudgetType
  }
  const storeCount = (campaign?.ads || []).length
  let spendTranslationObj = {
    sumDailySpend: formatCurrency(budget.amount * storeCount),
    sumWeeklySpend: formatCurrency(budget.amount * storeCount * 7),
    sumMonthlySpend: formatCurrency(budget.amount * storeCount * 30),
    sumLifetimeSpend: '',
    interpolation: { escapeValue: false },
  }

  if (budget?.type === AdBudgetTypes.Lifetime) {
    spendTranslationObj = {
      ...spendTranslationObj,
      sumLifetimeSpend: sumLifetime(budget, storeCount) || '',
      interpolation: { escapeValue: true },
    }
    return `This Campaign has a ‘Lifetime Budget’, so you will spend up to <strong>$${spendTranslationObj.sumLifetimeSpend}</strong> for this campaign.`
  } else {
    return `This Campaign has a ‘Daily Budget’, so you will spend up to <strong>$${spendTranslationObj.sumDailySpend}</strong> a day for this campaign.<br>Within a week you will spend up to <strong>$${spendTranslationObj.sumWeeklySpend}</strong> for this campaign.<br>Within a month you will spend up to <strong>$${spendTranslationObj.sumMonthlySpend}</strong> for this campaign.`
  }
}

const getAdTargeting = (campaign: FacebookCampaignInfo, associatedStore: TargetableStore) => {
  const targetingType = campaign?.ads?.[0]?.targeting?.type || AdTargetingTypes.Radius
  const targetingValues = campaign?.ads?.[0]?.targeting?.[targetingType]
  const targeting = _.isArray(targetingValues)
    ? targetingValues.map((zipcode) => zipcode.name).join(', ')
    : targetingValues

  const getTargeting = (type: string, storeAddress = '', prefix = '', suffix = '') => (
    <>
      <StyledSubtitle variant='body1'>{type}</StyledSubtitle>
      {storeAddress && (
        <StyledTypography variant='body2'>
          {storeAddress}
          <AdTypeSpan>
            {prefix}
            {targeting}
            {suffix}
          </AdTypeSpan>
        </StyledTypography>
      )}
      {!storeAddress && (
        <AdTypeValue>
          {prefix}
          {targeting}
          {suffix}
        </AdTypeValue>
      )}
    </>
  )

  switch (targetingType) {
    case AdTargetingTypes.Zips:
      return getTargeting('Zipcode(s)')
    case AdTargetingTypes.Radius:
      return getTargeting(
        associatedStore?.name,
        getFacebookAddress(associatedStore?.integrations?.[0]?.location as FacebookLocation),
        ': Radius ',
        ' mi.',
      )
    case AdTargetingTypes.Countries:
      return getTargeting('Country / Countries')
    case AdTargetingTypes.Cities:
      return getTargeting('City / Cities')
    case AdTargetingTypes.Regions:
      return getTargeting('Region(s)')
    case AdTargetingTypes.GeoMarkets:
      return getTargeting('GeoMarket(s)')
    default:
      return <></>
  }
}

const getAdKeywordsText = (campaign: FacebookCampaignInfo): string =>
  (campaign?.ads?.[0]?.targeting?.specificTargets || []).map((target) => target.value).join(',') ||
  'N/A'

const FacebookAdDetails: React.FC<FacebookAdDetailsProps> = ({ associatedStore, campaign }) => {
  return (
    <>
      <FacebookAdPreviewStepper
        campaign={campaign}
        associatedStore={associatedStore}
        stepperButtonsPos='top'
      />
      <StyledLabel variant='caption'>Marketing Platform</StyledLabel>
      <StyledTypography variant='body2'>Meta Paid Ads</StyledTypography>

      <StyledLabel variant='caption'>Budget</StyledLabel>
      <StyledTypography
        variant='body2'
        dangerouslySetInnerHTML={{
          __html: getAdBudgetText(campaign),
        }}
      />

      <StyledLabel variant='caption'>Schedule</StyledLabel>
      <StyledTypography variant='body2'>{getAdScheduleText(campaign)}</StyledTypography>

      <StyledLabel variant='caption'>Keywords</StyledLabel>
      <StyledTypography variant='body2'>{getAdKeywordsText(campaign)}</StyledTypography>

      <StyledLabel variant='caption'>Location Marketing</StyledLabel>
      {getAdTargeting(campaign, associatedStore)}
    </>
  )
}

export default FacebookAdDetails
