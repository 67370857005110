import Tippy from '@tippyjs/react'
import { FormattedMenuSectionInfo, ItemModifier, MenuMappingItemDetails, TypeName } from 'api'
import clock_icon_gray from 'assets/icons/clock-gray.svg'
import clock_icon_green from 'assets/icons/clock-green.svg'
import ellipse_2_green from 'assets/icons/ellipse-2-green.svg'
import ellipse_2_red from 'assets/icons/ellipse-2-red.svg'
import PLUS_ICON from 'assets/icons/plus-icon.svg'
import TRASH_ICON from 'assets/icons/trash-icon.svg'
import { Toggle } from 'components'
import { INDEFINITELY_86ED } from 'features/manage-locations/IndividualMenu/MenuSectionPreview'
import { useIsMobile } from 'hooks'
import { Dispatch, SetStateAction } from 'react'
import { colors } from 'theme'
import * as Styled from './styles'

type Props = {
  modifierId: ItemModifier['id']
  modifier?: { title: string; price?: number }
  isSelected?: boolean
  isExpanded?: boolean
  onRowClick?: () => void
  onModifierClick?: (id: ItemModifier['id']) => void
  isPreviewOnly?: boolean
  isNested?: boolean
  setSuspendUntilItem?: Dispatch<
    SetStateAction<
      | {
          id: MenuMappingItemDetails['id'] | ItemModifier['id']
          type: TypeName.Item | TypeName.CustomizationOption
        }
      | undefined
    >
  >
  existingSuspendUntil?: string | null | undefined
  checkBoxContainerStyle?: React.CSSProperties
  modifiersExist?: boolean
  isItemPreview?: boolean
  onChangeModifierValues?: ({
    modId,
    price,
    title,
    parentGroupId,
    isNested,
  }: {
    modId: string
    price?: number
    title?: string
    parentGroupId: string
    isNested: boolean
  }) => void
  customization?: FormattedMenuSectionInfo
  onDeleteModifier?: (modId: string, customization: FormattedMenuSectionInfo) => void
  isItemModal?: boolean
  isEditor?: boolean
}

const ItemModifierRow = ({
  modifierId,
  modifier,
  isSelected,
  isExpanded,
  isItemPreview,
  onModifierClick,
  onRowClick,
  isPreviewOnly,
  isNested,
  setSuspendUntilItem,
  existingSuspendUntil,
  checkBoxContainerStyle,
  modifiersExist = false,
  onChangeModifierValues,
  customization,
  onDeleteModifier,
  isItemModal,
  isEditor,
}: Props) => {
  const { isMobile } = useIsMobile()

  return (
    <Styled.ItemRow
      key={modifierId}
      isMobile={isMobile}
      isModifier={!isNested}
      isNestedModifier={isNested}
      isExpanded={isExpanded}
    >
      {!isPreviewOnly && !isItemPreview && !isEditor ? (
        <Styled.CheckboxModifierContainer style={checkBoxContainerStyle}>
          <Toggle
            id={`toggle-${modifierId}`}
            isToggled={isSelected}
            onToggle={() => onModifierClick && onModifierClick(modifierId)}
            activeColor={colors.blue[700]}
          />
        </Styled.CheckboxModifierContainer>
      ) : !isItemModal ? (
        <div style={{ flexBasis: '72px' }} />
      ) : null}
      {/* Only able to edit modifier attributes in the modal */}
      {isItemModal ? (
        <Styled.Input
          style={{ flexBasis: '100%' }}
          type='text'
          height='47px'
          width={isMobile ? '100%' : '170px'}
          value={modifier?.title}
          onChange={(e) => {
            if (onChangeModifierValues && customization) {
              onChangeModifierValues({
                modId: modifierId,
                price: modifier?.price,
                title: e.target.value,
                parentGroupId: customization.id,
                isNested: !!isNested,
              })
            }
          }}
        />
      ) : (
        <Styled.ItemTitle style={{ flexBasis: '100%' }}>{modifier?.title}</Styled.ItemTitle>
      )}
      {!isSelected && !isItemPreview && !isMobile && !isEditor ? (
        <Styled.Status active={isSelected}>
          <Styled.Icon src={isSelected ? ellipse_2_green : ellipse_2_red} alt='ellipseIcon' />
          {isSelected ? 'active' : 'inactive'}
        </Styled.Status>
      ) : (
        <div style={{ flexBasis: isItemModal ? '80%' : '20%' }} />
      )}
      <Styled.InputPriceContainer isMobile={isMobile}>
        <Styled.Input
          value={modifier?.price}
          type='number'
          pattern='[0-9]*'
          step='0.01'
          disabled={!isItemModal}
          onChange={(e) => {
            if (onChangeModifierValues && customization) {
              onChangeModifierValues({
                modId: modifierId,
                price: e.target.value ? parseFloat(e.target.value) : undefined,
                title: modifier?.title,
                parentGroupId: customization.id,
                isNested: !!isNested,
              })
            }
          }}
        />
      </Styled.InputPriceContainer>

      {isEditor && onDeleteModifier && customization && (
        <Styled.IconButton margin='0 10px 0 10px' onClick={(e) => e.stopPropagation()}>
          <img
            src={TRASH_ICON}
            width='16px'
            alt='delete'
            onClick={() => onDeleteModifier(modifierId, customization)}
            style={{ maxWidth: '16px' }}
          />
        </Styled.IconButton>
      )}

      {/* Another nested level of modifiers exists */}
      {onRowClick && modifiersExist && !isPreviewOnly && (
        <Styled.OptionsContainer onClick={onRowClick}>
          <Styled.OptionsIcon src={PLUS_ICON} alt='ellipse_menu' />
        </Styled.OptionsContainer>
      )}
      {!isSelected && !isItemPreview && !isEditor ? (
        <div
          style={{ flexBasis: '56px', display: 'flex', justifyContent: 'center' }}
          onClick={() => {
            if (modifier && setSuspendUntilItem) {
              // sets the item in the modal
              setSuspendUntilItem({ id: modifierId, type: TypeName.CustomizationOption })
            }
          }}
        >
          <Tippy content={existingSuspendUntil}>
            <Styled.Icon
              src={existingSuspendUntil !== INDEFINITELY_86ED ? clock_icon_green : clock_icon_gray}
              alt='clock icon'
              data-testid={`clock-icon-${existingSuspendUntil !== INDEFINITELY_86ED ? 'green' : 'gray'}`}
              iconSize='16'
            />
          </Tippy>
        </div>
      ) : (
        <div style={{ flexBasis: '56px' }} />
      )}
    </Styled.ItemRow>
  )
}

export default ItemModifierRow
