import * as dmApis from "api/digitalMarketing";
import { useCallback, useMemo, useState } from "react";
import useSafeAsync from "./useSafeAsync";


type ApiFunction<T extends keyof typeof dmApis> = typeof dmApis[T];
type ExcludeFirstParam<T extends (...args: any) => any> = 
  T extends (first: any, ...rest: infer R) => any ? (...args: R) => ReturnType<T> : never;

const stringifyError = (err: any) => {
  return typeof err === 'string' ? err : err?.message || JSON.stringify(err)
}

/**
 * Helper hook for invoking Digital Marketing APIs with automatic argument type inference,
 * error handling, loading states, and safe state updates.
 * 
 * The hook provides intellisense and type checking for the API method arguments.
 * 
 * @param partnerId - The partner ID to use for the API call. All Targetable Digital Marketing APIs 
 * require a partner ID.
 * 
 * How to use:
 * 
 * ```tsx
 * const [invokeApi, { isLoading, data, error }] = useDigitalMarketingApi();
 * 
 * const handleUIEvent = async () => {
 *   const { data, error } = await invokeApi('getQuestionnaireById', 'id123');
 *   // NOTE: you can either use the returned objects or the global states
 *   console.log(data, isLoading, error)
 * };
 * ```
 */
const useDigitalMarketingApi = <T=any>(partnerId?: string) => {
  const [operation, setOperation] = useState<{ data?: T | null, error: string | null, isLoading: boolean}>({
    data: undefined,
    error: null,
    isLoading: false,
  });

  const safeAsync = useSafeAsync();

  const resetState = useCallback(() => {
    setOperation({ data: undefined, error: null, isLoading: false })
  }, []);

  const invokeApi = useCallback(async <T extends keyof typeof dmApis>(
    api: T,
    ...args: Parameters<ExcludeFirstParam<ApiFunction<T>>>
  ): Promise<{
    data: Awaited<ReturnType<ApiFunction<T>>> | null;
    isLoading: boolean;
    error: string | null;
  }> => {
  
    const invokeFn = dmApis[api] as ApiFunction<T>;
    
    setOperation({ data: undefined, error: null, isLoading: true })
    
    if(partnerId === undefined) {
      setOperation({ data: undefined, error: null, isLoading: false })
      return {
        data: null,
        isLoading: false,
        error: 'You must provide a partner ID to use this API'
      }
    }
    

    // Using safeAsync here to prevent changing state after unmounting
    return safeAsync(
      // @ts-expect-error ts is loosing the tuple info from args for some reason
      invokeFn(partnerId, ...args) as Awaited<ReturnType<ApiFunction<T>>>
    ).then((data) => {
      setOperation({ data, isLoading: false, error: null })
      return { data, isLoading: false, error: null }
    }).catch((err) => {
      const errStr = stringifyError(err)
      setOperation({ data: null, isLoading: false, error: errStr })
      return { data: null, isLoading: false, error: errStr }
    });
  }, [safeAsync, partnerId]);

  
  return useMemo(() => [
    invokeApi,
    {
      data: operation?.data,
      isLoading: !!operation?.isLoading,
      error: operation?.error,
      resetState,
    }
  ] as const, [invokeApi, operation?.data, operation?.isLoading, operation?.error, resetState]);
};

export default useDigitalMarketingApi;
