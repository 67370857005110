import styled from '@emotion/styled';
import { Card, Typography, IconButton, CardHeader, CardContent } from '@mui/material';
import { KeyboardArrowUp as KeyboardArrowUpIcon } from '@mui/icons-material';

const textCommon = `
  color: #ebebec;
  text-shadow: 1px 1px 2px rgba(0, 0, 0, 0.5);
`;

export const StyledCard = styled(Card)`
  border-radius: 2px;
  max-width: 320px;
  height: 566px;
  display: flex;
  flex-direction: column;
  text-align: initial;
  margin: 0 auto;
  background-color: #d8d9df;
  background-size: cover;
  background-position: 50%;
  position: relative;
`;

export const HeaderRoot = styled(CardHeader)`
  padding: 20px 12px 12px 12px;
  z-index: 1;
  background: linear-gradient(180deg, rgba(0,0,0,0.6) 0%, rgba(0,0,0,0) 100%);
`;

export const HeaderTitle = styled(Typography)`
  ${textCommon}
  font-size: 13px;
  font-weight: 500;
  font-family: Arial;
`;

export const HeaderSubTitle = styled(Typography)`
  ${textCommon}
  color: #ced0d2;
  font-size: 10px;
  font-family: Arial;
`;

export const ProgressBar = styled.div`
  height: 2px;
  background-color: rgba(255, 255, 255, 0.5);
  position: absolute;
  top: 5px;
  left: 5px;
  width: calc(100% - 10px);
  z-index: 2;
  
  &::before {
    content: '';
    width: 70%;
    height: 2px;
    background-color: white;
    display: block;
  }
`;

export const BlurredImage = styled.img`
  height: calc(100% + 160px);
  position: absolute;
  width: calc(100% + 160px);
  object-fit: cover;
  filter: blur(40px);
  top: -80px;
`;

export const MainImage = styled.img`
  height: 320px;
  object-fit: cover;
  z-index: 1;
`;

export const ContentText = styled(Typography)`
  ${textCommon}
  line-height: normal;
  font-size: 20px;
  background-color: rgba(0, 0, 0, 0.64);
  padding: 10px;
  white-space: pre-wrap;
  word-break: break-word;
`;

export const StyledCardContent = styled(CardContent)`
  justify-content: flex-end;
  align-items: center;
  display: flex;
  flex-direction: column;
  z-index: 1;
  background: linear-gradient(0deg, rgba(0,0,0,0.6) 0%, rgba(0,0,0,0) 100%);
  height: 145;
  box-sizing: content-box;
`;

export const StyledButton = styled(IconButton)`
  background-color: white;
  border-radius: 20px;
  color: black;
  font-size: 24px;
  margin-bottom: 5px;
`;

export const ButtonLabel = styled.span`
  ${textCommon}
  display: flex;
  flex-direction: column;
  text-transform: none;
  font-size: 13px;
  align-items: inherit;
  justify-content: inherit;
  font-weight: 700;
`;

export const StyledArrowUpIcon = styled(KeyboardArrowUpIcon)`
  background-color: white;
  border-radius: 20px;
  color: black;
  font-size: 24px;
  margin-bottom: 5px;
`;

export const ContentContainer = styled.div`
  flex: 1;
  display: flex;
  align-items: center;
`;

export const CloseButton = styled(IconButton)`
  padding: 5px 3px;
`;

export const ChevronIcon = styled.span`
  display: inline-block;
  padding: 0 2px;
  font-size: 7px;
  vertical-align: middle;
`;
