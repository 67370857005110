import { algolia } from "config"
import algoliasearch, { SearchClient } from 'algoliasearch'
import { useCallback } from "react"
import type { SearchResponse } from '@algolia/client-search'

const searchClient = algoliasearch(algolia.appId, algolia.apiSearchKey)


/**
 * @returns a function that can be used to search multiple indices at once.
 */
const useSearchAlgoliaMultiIndex = () => {
  /**
   * A memoized function that can be used to search multiple indices at once.
   */
  const search = useCallback(async <T extends any[]>(
    queries: Parameters<SearchClient['search']>[0],
    cacheable = false
  ) => {
    const { results } = await searchClient.search<T>(queries, {
      cacheable,
    });

    // Remove algolia specific properties
    const list = (results || []) as SearchResponse<T[number]>[];
    const cleanResults = list.map((result) => ({
      ...result,
      hits: result.hits?.map((hit) => {
        const { objectID, _highlightResult, ...rest } = hit;
        return rest as T[number];
      })
    }));

    return cleanResults;
  }, [])

  return search;
}

export default useSearchAlgoliaMultiIndex;