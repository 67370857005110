import React from 'react';
import { Drawer, AppBar, Toolbar, IconButton, Typography, Box } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';

interface RightDrawerProps {
  isOpen: boolean;
  hideBackdrop?: boolean;
  onClose: () => void;
  title: string;
  children: React.ReactNode;
}

const RightDrawer: React.FC<RightDrawerProps> = ({
    isOpen,
    hideBackdrop,
    onClose,
    title,
    children,
}) => {
  return (
    <Drawer
      anchor="right"
      open={isOpen}
      onClose={onClose}
      PaperProps={{
        sx: { 
            maxWidth: { xs: '100%', sm: 520 },
            width: '100%', 
            display: 'flex',
            flexDirection: 'column',
            zIndex: 12,
         },
      }}
      hideBackdrop={hideBackdrop}
    >
      <AppBar
        position="static"
        elevation={0} 
        sx={{
            backgroundColor: '#fefefe',
            color: '#292929',
            borderBottom: '1px solid #d7d7d7'
        }}
      >
        <Toolbar>
          <Typography variant="h6" component="div" sx={{ flexGrow: 1 }}>
            {title}
          </Typography>
          <IconButton edge="end" color="inherit" onClick={onClose} aria-label="close">
            <CloseIcon />
          </IconButton>
        </Toolbar>
      </AppBar>

      <Box
        sx={{
          flexGrow: 1,
          overflowY: 'auto',
          p: 2,
          boxSizing: 'border-box',
          marginLeft: '8px',
          marginRight: '16px',
        }}
      >
        {children}
      </Box>
    </Drawer>
  );
};

export default RightDrawer;
