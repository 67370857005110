import { Button } from 'components'
import { useGetLocationPartners, useIsMobile } from 'hooks'
import { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { toast } from 'react-toastify'
import { FormattedAvailabilities } from 'types/Menu'
import { DAYS_OF_WEEK } from 'utils'

import MenuAvailableTable from '../MenuAvailability/MenuAvailabilityTable'
import * as Styled from '../styles'
import createNewMenuSectionWithAvailabilities from '../util/createNewMenuWithAvailabilities'
import validateAvailabilities from '../util/validateAvailabilities'

const CreateNewMenu = () => {
  const navigate = useNavigate()
  const { menuMappingMenuId, partner } = useGetLocationPartners()

  const { isMobile } = useIsMobile()
  const [menuSectionName, setMenuSectionName] = useState<string>('')
  const [newAvailabilities, setNewAvailabilities] = useState<FormattedAvailabilities>({})

  // pre-fill availabilities
  useEffect(() => {
    const formattedAvailabilities: FormattedAvailabilities = {}
    // Prefill availabilities object with existing availabilities
    DAYS_OF_WEEK.forEach((day) => {
      // if we are missing time periods for this day, prefill empty time periods to display options anyways
      formattedAvailabilities[day] = []
    })

    setNewAvailabilities(formattedAvailabilities)
  }, [])

  const handleCreateMenu = async () => {
    if (menuMappingMenuId) {
      try {
        const result = await createNewMenuSectionWithAvailabilities(
          menuMappingMenuId,
          menuSectionName,
          newAvailabilities,
        )

        if (result) {
          toast.success('Success! Your new menu has been created.', { autoClose: 5000 })
          navigate(`/manage-locations/${partner?.id}/menus/${result.menuSection.data.id}/edit`)
        }
      } catch (err: any) {
        console.log('error', err)
        toast.error(err.message)
      }
    }
  }

  const clearFormFields = () => {
    setMenuSectionName('')
    setNewAvailabilities({})
  }

  const timePeriodsError = validateAvailabilities(newAvailabilities) === false

  return (
    <Styled.Container width={isMobile ? '342px' : undefined}>
      <Styled.Subtitle>Create New Menu</Styled.Subtitle>
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          width: '100%',
          gap: 16,
          background: '#FEFEFE',
          padding: '24px',
        }}
      >
        <Styled.CreateMenuFormTitle>
          <b>Menu Title</b> (required)
        </Styled.CreateMenuFormTitle>
        <Styled.InputContainer height='47px'>
          <Styled.Input
            value={menuSectionName}
            onChange={(e) => setMenuSectionName(e.target.value)}
            placeholder='Enter menu title...'
          />
        </Styled.InputContainer>

        <Styled.LineSeparator />

        <Styled.CreateMenuFormTitle>
          <b>Menu Availability</b> (required)
        </Styled.CreateMenuFormTitle>
        <MenuAvailableTable
          newAvailabilities={newAvailabilities}
          setNewAvailabilities={setNewAvailabilities}
        />
      </div>
      {timePeriodsError && (
        <div style={{ width: '100%' }}>
          <Styled.TopRowText color='red' textAlign='end'>
            Please fix the errors above before proceeding.
          </Styled.TopRowText>
        </div>
      )}
      <div style={{ display: 'flex', width: '100%', justifyContent: 'flex-end', gap: 10 }}>
        <Button
          onClick={() => {
            clearFormFields()
            history.back()
          }}
          variant='textonly'
          narrow
        >
          Cancel
        </Button>
        <Button narrow onClick={handleCreateMenu} disabled={timePeriodsError}>
          Continue
        </Button>
      </div>
    </Styled.Container>
  )
}

export default CreateNewMenu
