import styled from '@emotion/styled';
import { Carousel } from '@trendyol-js/react-carousel';

import { Avatar, Card, CardHeader, Typography, Box, CardActions, CardContent } from '@mui/material';

export const StyledCard = styled(Card)`
  border-radius: 2px;
  max-width: 320px;
  width: auto;
  flex-direction: column;
  text-align: initial;
  margin: 0 auto;
`;

export const FullWidthContent = styled(Box)`
  display: block !important;
  padding: 0px !important;
  margin: 0px !important;
`;

export const StyledAvatar = styled(Avatar)`
  margin-right: 10px;
`;

export const HeaderRoot = styled(CardHeader)`
  padding: 12px;
`;

export const HeaderTitle = styled(Typography)`
  font-size: 14px;
  font-weight: bold;
  font-family: Arial;
`;

export const HeaderSubTitle = styled(Typography)`
  font-size: 11px;
  color: #616770;
  font-family: Arial;
`;

export const TypographyRootSeeMore = styled(Typography)`
  position: relative;
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  font-family: Helvetica;
`;

export const AdDescription = styled(CardContent)`
  font-family: Helvetica;
`;

export const SeeMoreLink = styled(Box)`
  right: 0;
  color: #36569a;
  cursor: pointer;
  bottom: 0;
`;

export const InstructionsRoot = styled(Box)`
  padding: 0 8px 8px;
  word-break: break-word;
`;

export const CardContentRoot = styled(CardContent, {
  shouldForwardProp: (prop) => prop !== 'fullwidth',
})<{ fullwidth: boolean | undefined }>`
  border-bottom: 1px solid #e5e5e5;
  padding: 0 0 16px 0;
  margin: 0 8px;
  display: flex;
  flex-direction: row;

  ${({ fullwidth }) =>
    fullwidth &&
    `
    display: block !important;
    padding: 0px !important;
    margin: 0px !important;
  `}
`;

export const CarouselContainer = styled(Carousel)`
  position: relative;

  & > div:first-of-type > .MuiSvgIcon-root:not([data-cy]) {
    position: absolute;
    left: 5px;
    height: 40px;
    width: 40px;
    cursor: pointer;
    z-index: 9;
    background: #ffffff;
    border-radius: 25px;
    top: 50%;
    margin-top: -20px;
    box-shadow: 0px 1px 1px -1px rgb(0 0 0 / 20%), 0px 1px 1px 0px rgb(0 0 0 / 14%), 0px 1px 3px 0px rgb(0 0 0 / 12%);
  }

  & > div:last-child > .MuiSvgIcon-root:not([data-cy]) {
    position: absolute;
    right: 5px;
    height: 40px;
    width: 40px;
    cursor: pointer;
    z-index: 9;
    background: #ffffff;
    border-radius: 25px;
    top: 50%;
    margin-top: -20px;
    box-shadow: 0px 1px 1px -1px rgb(0 0 0 / 20%), 0px 1px 1px 0px rgb(0 0 0 / 14%), 0px 1px 3px 0px rgb(0 0 0 / 12%);
  }

  .styles-module_item-container__a8zaY:not(:first-of-type) {
    margin-left: 12px;
  }
`;


export const CardActionsRoot = styled(CardActions)`
  padding: 8px 16px;
`;

export const IconButton = styled(Box)`
  transform: rotate(180deg);
  font-size: 20px;
  opacity: 0.6;
`;

