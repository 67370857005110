import { AdvancedLocationTargeting } from "./common";

export type InsightFeedbackQuestion = {
  displayOrder?: number;
  icon?: string;
  question: string;
  value?: string | number;
};

export type InsightFeedback = {
  questions: InsightFeedbackQuestion[];
  timestamp: number;
  userId?: string;
};

export type InsightImage = {
  filename: string;
  publicId: string;
  src: string;
}

export const enum InsightTypes {
  CampaignUpdate = 'campaign_update',
  Insight = 'insight',
  PaidSocial = 'paid_social',
  ManagedSocialMedia = 'managed_social_media',
  ManagedLandingPages = 'managed_landing_pages',
  CommunityManagement = 'community_management',
  AcquisitionAndAwarenessPlus = 'acquisition_and_awareness_plus',
  ManagedEmailMarketing = 'managed_email_marketing',
  Discoverability = 'discoverability',
}

export type InsightType =
  | 'insight'
  | 'paid_social'
  | 'managed_social_media'
  | 'managed_landing_pages'
  | 'community_management'
  | 'acquisition_and_awareness_plus'
  | 'managed_email_marketing'
  | 'discoverability'
  | 'campaign_update';

export type InsightPlatform =
  | 'facebook'
  | 'instagram'
  | 'pinterest'
  | 'google_my_business'
  | 'linkedin'
  | 'twitter';

export type InsightStatus =
  | 'approved'
  | 'draft'
  | 'published'
  | 'rejected'
  | 'review';

export enum InsightCampaignUpdateTypes {
  Budget = 'budget',
  HeadlinesAndDescriptions = 'headlines_descriptions',
  Locations = 'locations',
  EndDate = 'end_date',
  Keywords = 'keywords',
  RichProfile = 'rich_profile',
  Promotions = 'promotions',
}

export type GoogleLocationSettings = {
  resourceName: string;
  reach: string;
  targetType: string; // GeoTargetType
  canonicalName: string;
}

export const enum InsightPromotionDetails {
  None = 'none',
  PromoCode = 'promo_code',
  OnOrdersOver = 'on_orders_over',
}

export const enum InsightPromotionType {
  MonetaryDiscount = 'monetary_discount',
  PercentageDiscount = 'percent_discount',
  UpToMonetaryDiscount = 'up_monetary_discount',
  UpToPercentageDiscount = 'up_percent_discount',
}

export enum InsightScheduleDay {
  AllDays = 'all_days',
  MonFri = 'mon_frid',
  SatSun = 'sat_sun',
  Mondays = 'mondays',
  Tuesdays = 'tuesdays',
  Wednesdays = 'wednesdays',
  Thursdays = 'thursdays',
  Fridays = 'fridays',
  Saturdays = 'saturdays',
  Sundays = 'sundays',
}

export type PromotionScheduleEntry = {
  days: InsightScheduleDay;
  /** Eg: 2:00 AM */
  endTime: string;
  /** Eg: 2:30 AM */
  startTime: string;
};

export type InsightPromotionUpdate = {
  /** unknown */
  currentPromotions?: Record<string, unknown>,
  newPromotions: {
    /** date in `yyyy-dd-mm` format */
    displayedPromotionEnd?: string;
    /** date in `yyyy-dd-mm` format */
    displayedPromotionStart?: string;
    finalUrl: string;
    item: string;
    promotionDetails: InsightPromotionDetails;
    promotionDetailsValue: string;
    promotionEnd?: string;
    promotionStart?: string;
    promotionType: InsightPromotionType;
    promotionTypeValue: string;
    schedule: PromotionScheduleEntry[];
  }
}

export type TargetableInsight = {
  id: string;
  businessId: string;
  storeId: string;
  /**
   * This is to have a direct relationship between
   * Chowly’s "Location"/PartnerID object and Targetable’s StoreID object.
   */
  chowlyPartnerId?: string;
  created: number;
  published: number;
  responded: number;
  saved: number;
  updated?: number;
  expiryDate?: string;
  status: InsightStatus;
  questionnaireId?: string;
  campaignUpdate?: {
    override?: boolean;
    campaignId: string;
    marketingChannelId: string;
    updateType: InsightCampaignUpdateTypes;
    budget?: {
      currentBudget?: number;
      nextBudget: number;
    };
    originalGroup?: {
      fields: {
        keywords: string[];
        name: string;
        type: string;
      },
      id: string;
      referenceId: string;
    },
    group?: {
      fields: {
        keywords: string[];
        name: string;
        type: string;
      },
      id: string;
      referenceId?: string;
    },
    ad?: {
      headlines: string[];
      descriptions: string[];
    },
    originalAd?: {
      headlines: string[];
      descriptions: string[];
    },
    endDate?: {
      currentEndDate: string;
      nextEndDate: string;
    },
    locationSettings?: {
      googleCampaignId: string;
      newLocationSettings: GoogleLocationSettings[];
      oldLocationSettings: GoogleLocationSettings[];

    },
    /**
     * A list of locations with proximity or targeting configuration.
     */
    advancedLocations?: {
      newLocationSettings: AdvancedLocationTargeting[];
      oldLocationSettings: AdvancedLocationTargeting[];
    },
    promotions?: InsightPromotionUpdate;
  };
  displayDate?: {
    day: string;
    month: string;
    year: string;
  };
  displayTime?: {
    hour: number;
    minute: number;
  };
  templateId?: string;
  title: string;
  description: string;
  feedback?: InsightFeedback;
  overrideAutoAging?: boolean;
  type: InsightType;
  url?: string;
  platforms?: InsightPlatform[];
  postDateTime?: string;
  image?: InsightImage;
  /** Hold the date in ISO format when the record was "deleted" */
  deletedOn?: string;
}
