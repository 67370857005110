import { FormattedMenuSectionInfo, ItemModifier, MenuMappingItemDetails } from 'api'

type FilteredResults = {
  filteredItems: FormattedMenuSectionInfo[]
  filteredModifierGroups: { [id: MenuMappingItemDetails['id']]: FormattedMenuSectionInfo[] } // map to Items
  filteredModifiers: { [id: FormattedMenuSectionInfo['id']]: FormattedMenuSectionInfo[] } // map to Modifier Groups
}

// In 86ing Preview, we want to show all items and modifiers that have changed
// If a modifier was updated and not the item, we want to still show the item with its modifier group and modifier
const get86ingPreviewFilteredItemsModifiers = (
  items: FormattedMenuSectionInfo[],
  updatedItemIds: MenuMappingItemDetails['id'][],
  updatedModifierIds: ItemModifier['id'][],
  filteredModifierIds: FormattedMenuSectionInfo['id'][] = [], // modifiers that were already filtered out
): FilteredResults => {
  const filteredModifiers: { [id: FormattedMenuSectionInfo['id']]: FormattedMenuSectionInfo[] } = {} // map modifiers to their items
  const filteredModifierGroups: { [id: MenuMappingItemDetails['id']]: FormattedMenuSectionInfo[] } =
    {}

  const filteredItems: FormattedMenuSectionInfo[] = items.filter((item) => {
    const matchingItemModifierGroups: FormattedMenuSectionInfo[] = []

    // drill into the item, modifier group and modifier to check if there is a keyword match.
    item.children.customizations?.forEach((customization) => {
      const matchingItemModifiers: FormattedMenuSectionInfo[] = []

      // possible pre-existing modifier filter
      const customizationOptions = filteredModifierIds.length
        ? customization.children.customization_options?.filter((option) =>
            filteredModifierIds.includes(option.id),
          )
        : customization.children.customization_options

      customizationOptions?.forEach((option) => {
        if (updatedModifierIds.includes(option.id)) {
          matchingItemModifiers.push(option)
        }

        const matchingNestedModifiers: FormattedMenuSectionInfo[] = []

        // NESTED GROUPS & MODS
        option.children.customizations?.forEach((nestedCustomization) => {
          nestedCustomization.children.customization_options?.forEach((nestedMod) => {
            if (updatedModifierIds.includes(nestedMod.id)) {
              matchingNestedModifiers.push(nestedMod)
            }
          })

          if (matchingNestedModifiers.length) {
            matchingItemModifierGroups.push(nestedCustomization)
            filteredModifiers[nestedCustomization.id] = matchingNestedModifiers
          }
        })
      })

      if (matchingItemModifiers.length) {
        matchingItemModifierGroups.push(customization)
        filteredModifiers[customization.id] = matchingItemModifiers
      }
    })

    // also add the item even if it doesn't match, but it's modifiers do
    if (matchingItemModifierGroups.length) {
      filteredModifierGroups[item.id] = matchingItemModifierGroups
      return true
    }

    // Finally, if the item itself matches the keyword, show the item.
    if (updatedItemIds.includes(item.id)) {
      return true
    }

    return false
  })

  return { filteredItems, filteredModifierGroups, filteredModifiers }
}

export default get86ingPreviewFilteredItemsModifiers
