import {
  FormattedMenuSectionInfo,
  MenuMappingCustomizationOptionResource,
  MenuMappingCustomizationResource,
  MenuMappingItemResource,
  MenuMappingNestedCustomizationResource,
} from 'api'
import { createMenuMappingCustomizationOption } from 'api/menuMapping/customizationOptions'
import { createMenuMappingCustomization } from 'api/menuMapping/customizations'
import { createMenuMappingItem } from 'api/menuMapping/items'
import { createMenuMappingNestedCustomization } from 'api/menuMapping/nestedCustomizations'
import { AxiosError } from 'axios'

import { NESTED_GROUP_PREFIX } from '../MenuItemViewModal/index'
import { ModifierValuesMap } from '../MenuSectionEditor'
import { ResponseErrors } from './updateExistingMenuItem'

const createNewMenuItem = async ({
  menuId,
  subsectionId,
  attributes,
  modifierGroupsToAdd,
  modifiersToAdd,
}: {
  menuId: string
  subsectionId: string
  attributes: { title: string; description: string }
  modifierGroupsToAdd: FormattedMenuSectionInfo[]
  modifiersToAdd: ModifierValuesMap
}) => {
  let itemCreateData: MenuMappingItemResource | undefined
  let addModifierGroupsResponse: (MenuMappingCustomizationResource | undefined)[] | undefined
  let addModifiersResponse: (MenuMappingCustomizationOptionResource | undefined)[] | undefined
  let addNestedModifierGroupsResponse:
    | (MenuMappingNestedCustomizationResource | undefined)[]
    | undefined
  let addNestedModifiersResponse: (MenuMappingCustomizationOptionResource | undefined)[] | undefined

  const itemErrors: ResponseErrors = {}
  const modifierGroupErrors: ResponseErrors = {}
  const modifierErrors: ResponseErrors = {}

  // for new groups, store the actual generated id in a map to its temp id, to add the temp modifiers to the right place.
  const createdGroupIdsMap: { [tempId: string]: string } = {}
  const createdModifiersIdsMap: { [tempId: string]: string } = {} // need for nested groups and mods
  const createdNestedGroupsIdsMap: { [tempId: string]: string } = {} // need for nested mods

  const groupsToAdd: FormattedMenuSectionInfo[] = []
  const nestedGroupsToAdd: FormattedMenuSectionInfo[] = []
  const modsToAdd: ModifierValuesMap = {}
  const nestedModsToAdd: ModifierValuesMap = {}

  // split up groups and nested groups, this needs to be done in order
  modifierGroupsToAdd.forEach((group) => {
    if (group.id.includes(NESTED_GROUP_PREFIX)) {
      nestedGroupsToAdd.push(group)
    } else {
      groupsToAdd.push(group)
    }
  })

  // split up mods and nested mods
  Object.entries(modifiersToAdd).forEach(([id, values]) => {
    if (values.isNested) {
      nestedModsToAdd[id] = values
    } else {
      modsToAdd[id] = values
    }
  })

  try {
    const res = await createMenuMappingItem({
      attributes,
      menuId,
      subsections: [subsectionId],
    })

    if (res) {
      itemCreateData = res.data
    }
  } catch (err: any) {
    itemErrors.createErrors = [{ title: attributes.title, error: (err as AxiosError)?.message }]
  }

  // create new modifier groups
  if (groupsToAdd?.length) {
    addModifierGroupsResponse = await Promise.all(
      groupsToAdd.map(async (group) => {
        const { attributes } = group

        if (itemCreateData?.id) {
          try {
            const newGroupResult = await createMenuMappingCustomization({
              attributes,
              items: [itemCreateData.id],
              menuId,
            })

            if (newGroupResult) {
              // @ts-ignore - this is correct.
              createdGroupIdsMap[group.id] = newGroupResult.data.id

              return newGroupResult
            }
          } catch (err: any) {
            if (modifierGroupErrors.createErrors) {
              modifierGroupErrors.createErrors.push({
                title: attributes.title || group.id,
                error: (err as AxiosError)?.message,
              })
            } else {
              modifierGroupErrors.createErrors = [
                {
                  title: attributes.title || group.id,
                  error: (err as AxiosError)?.message,
                },
              ]
            }
          }
        }
      }),
    )
  }

  // create new modifiers
  if (Object.keys(modsToAdd).length) {
    // new modifiers can belong to an existing group or a newly created group
    addModifiersResponse = await Promise.all(
      Object.entries(modsToAdd).map(async ([id, values]) => {
        const groupId = values.parentGroupId
        const newGroupId = createdGroupIdsMap[groupId]

        const attributes = {
          title: values.title,
          customization_customization_options: {
            customization_id: newGroupId,
            customization_option_price: values.price || 0,
          },
        }

        if (newGroupId) {
          try {
            const modResult = await createMenuMappingCustomizationOption({
              attributes,
              customizations: [newGroupId],
              menuId,
            })

            if (modResult) {
              // @ts-ignore
              createdModifiersIdsMap[id] = modResult.data.id

              return modResult
            }
          } catch (err) {
            if (modifierErrors.createErrors) {
              modifierErrors.createErrors.push({
                title: values.title,
                error: (err as AxiosError)?.message,
              })
            } else {
              modifierErrors.createErrors = [
                { title: values.title, error: (err as AxiosError)?.message },
              ]
            }
          }
        }
      }),
    )
  }

  // create new nested groups
  // nested group id: `${NESTED_GROUP_PREFIX}:${parentModId}:${tempModGroupId}`
  if (nestedGroupsToAdd?.length) {
    addNestedModifierGroupsResponse = await Promise.all(
      nestedGroupsToAdd.map(async (group, idx) => {
        const { attributes } = group
        const groupIdArr = group.id.split(':')
        const parentModId = createdModifiersIdsMap[groupIdArr[1]]

        // Store values for new customization, then create nested customization relationship
        try {
          const newGroupResult = await createMenuMappingCustomization({
            attributes,
            menuId,
          })

          if (newGroupResult && parentModId) {
            // store for reference
            // @ts-ignore - this is correct.
            createdNestedGroupsIdsMap[group.id] = newGroupResult.data.id

            const nestedGroupResult = await createMenuMappingNestedCustomization({
              attributes: { sort_position: idx + 1 },
              parentOptionId: parentModId,
              // @ts-ignore
              customizationId: newGroupResult.data.id,
            })

            return nestedGroupResult
          }
        } catch (err: any) {
          if (modifierGroupErrors.createErrors) {
            modifierGroupErrors.createErrors.push({
              title: attributes.title || group.id,
              error: err,
            })
          } else {
            modifierGroupErrors.createErrors = [
              {
                title: attributes.title || group.id,
                error: err,
              },
            ]
          }
        }
      }),
    )
  }

  // create new nested mods
  if (Object.keys(nestedModsToAdd).length) {
    // new modifiers can belong to an existing group or a newly created group
    addNestedModifiersResponse = await Promise.all(
      Object.values(nestedModsToAdd).map(async (values) => {
        const groupId = values.parentGroupId
        const customizationId = createdNestedGroupsIdsMap[groupId]

        if (customizationId) {
          const attributes = {
            title: values.title,
            customization_customization_options: {
              customization_id: customizationId,
              customization_option_price: values.price || 0,
            },
          }

          try {
            const modResult = await createMenuMappingCustomizationOption({
              attributes,
              customizations: [customizationId],
              menuId,
            })

            return modResult
          } catch (err) {
            if (modifierErrors.createErrors) {
              modifierErrors.createErrors.push({ title: values.title, error: err })
            } else {
              modifierErrors.createErrors = [{ title: values.title, error: err }]
            }
          }
        }
      }),
    )
  }

  return {
    itemCreateData,
    addModifierGroupsResponse,
    addModifiersResponse,
    addNestedModifierGroupsResponse,
    addNestedModifiersResponse,
    itemErrors,
    modifierGroupErrors,
    modifierErrors,
  }
}

export default createNewMenuItem
