import { MenuMappingItemDetails } from 'api'
import { ReactComponent as WarningIcon } from 'assets/icons/warning-icon.svg'
import { FC } from 'react'
import { currencyFormat } from 'utils'

import * as Styled from './styles'

type Props = {
  data: MenuMappingItemDetails
  onClick?: () => void
}

const MenuItem: FC<Props> = ({ data, onClick }) => {
  const noPrice = typeof data.item_price !== 'number' || data.item_price <= 0
  const currencyCheck = data.currency_code ? data.currency_code : 'USD'
  const priceText = noPrice ? 'No Price' : currencyFormat(data.item_price, currencyCheck)
  const missingCustomizationOptions = data.missing_customization_options
  return (
    <Styled.ItemCard role='button' onClick={onClick}>
      <Styled.ItemThumb>
        {data.image_url ? (
          <img src={data.image_url} alt={`${data.title} image`} data-testid='item-image' />
        ) : (
          <WarningIcon data-testid='warning-icon' />
        )}
      </Styled.ItemThumb>
      <Styled.ItemContent>
        <Styled.ItemName data-testid='item-title' title={data.title}>
          {data.title}
        </Styled.ItemName>
        <Styled.ItemDescription data-testid='item-description' title={data.description}>
          {data.description}
        </Styled.ItemDescription>
        <Styled.ItemPrice data-testid='item-price' isError={noPrice}>
          {priceText}
        </Styled.ItemPrice>

        <Styled.ItemAction isError={!!missingCustomizationOptions} onClick={onClick}>
          {missingCustomizationOptions ? (
            <>
              View Customizations
              <WarningIcon />
            </>
          ) : (
            'View Customizations'
          )}
        </Styled.ItemAction>
      </Styled.ItemContent>
    </Styled.ItemCard>
  )
}

MenuItem.displayName = 'MenuItem'

export default MenuItem
