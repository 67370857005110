import styled from '@emotion/styled';
import { Box, Typography } from '@mui/material';
import InfoIcon from '@mui/icons-material/Info';

export const StyledBox = styled(Box)<{ alignment: string }>`
  display: flex;
  align-items: center;
  margin: 8px 0;

  ${({ alignment }) =>
    alignment === 'alignCenter'
      ? `
        justify-content: center;
      `
      : alignment === 'alignLeft'
      ? `
        justify-content: flex-start;
      `
      : ''}
`;

export const StyledInfoIcon = styled(InfoIcon)<{ variant: string }>`
  margin-right: 8px;
  fill: #8c8f9f;
  font-size: 16px;

  ${({ variant }) =>
    variant === 'center'
      ? `
        align-self: center;
      `
      : variant === 'top'
      ? `
        align-self: flex-start;
        margin-top: 6px;
      `
      : variant === 'firstLine'
      ? `
        align-self: flex-start;
      `
      : ''}
`;

export const StyledLabel = styled(Typography)`
  color: #6E7286;
`;
