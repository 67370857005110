import React from 'react'

import { CardImageIcon, IconContainer, RootBox } from './styles'

// Define the props type for the component
interface FacebookCardImageProps {
  src?: string | null
}

const FacebookCardImage: React.FC<FacebookCardImageProps> = ({ src }) => {
  return (
    <RootBox data-testid='facebookCardImage_container'>
      {src ? (
        <RootBox
          role='img'
          aria-label='image'
          style={{ backgroundImage: `url('${src}')` }}
          data-testid='facebookCardImage_image'
        />
      ) : (
        <IconContainer>
          <CardImageIcon data-testid='facebookCardImage_default_image' />
        </IconContainer>
      )}
    </RootBox>
  )
}

export default FacebookCardImage
