import { CampaignIcon } from "./styles"
import SavingsOutlinedIcon from '@mui/icons-material/SavingsOutlined';
import CampaignOutlinedIcon from '@mui/icons-material/CampaignOutlined';
import { ReactComponent as GooglePaidLocationSvg } from 'assets/icons/google-paid-location.svg';
import { ReactComponent as GooglePaidPromotionUpdateSvg } from 'assets/icons/google-paid-promotion-update.svg';
import { ReactComponent as GoogleEndDateSvg } from 'assets/icons/google-end-date.svg';
import { ReactComponent as GoogleKeywordsIcon } from 'assets/icons/google-keywords.svg';
import { ReactComponent as GoogleHeadlineAndDescriptionSvg } from 'assets/icons/google-headlines-descriptions.svg';


export const KeywordsIcon = () => {
  return (
    <CampaignIcon>
      <GoogleKeywordsIcon />
    </CampaignIcon>
  )
}

export const MetaPaidAdBudgetIcon = () => {
  return (
    <CampaignIcon>
      <SavingsOutlinedIcon />
    </CampaignIcon>
  )
}

export const NewCampaignIcon = () => {
  return (
    <CampaignIcon>
      <CampaignOutlinedIcon />
    </CampaignIcon>
  )
}

export const GooglePaidLocationIcon = () => {
  return (
    <CampaignIcon>
      <GooglePaidLocationSvg />
    </CampaignIcon>
  )
}

export const GooglePaidPromotionUpdateIcon = () => {
  return (
    <CampaignIcon>
      <GooglePaidPromotionUpdateSvg />
    </CampaignIcon>
  )
}

export const GoogleEndDateIcon = () => {
  return (
    <CampaignIcon>
      <GoogleEndDateSvg />
    </CampaignIcon>
  )
}

export const GoogleHeadlineAndDescriptionIcon = () => {
  return (
    <CampaignIcon>  
      <GoogleHeadlineAndDescriptionSvg />
    </CampaignIcon>
  )
}