import { chowlyApi } from 'config'

import client from '../chowly'
import {
  MenuMappingCustomization,
  MenuMappingCustomizationOptionResource,
  MenuMappingNestedCustomizationResource,
  TypeName,
} from '../models'

export const createMenuMappingNestedCustomization = async ({
  attributes,
  parentOptionId,
  customizationId,
}: {
  attributes: { sort_position?: number }
  parentOptionId: MenuMappingCustomizationOptionResource['id']
  customizationId: MenuMappingCustomization['id']
}) => {
  const url = chowlyApi.menuMappingNestedCustomizations

  const payload = formatMenuMappingNestedCustomizationPayload({
    attributes,
    parentOptionId,
    customizationId,
  })

  const { data } = await client.post<MenuMappingNestedCustomizationResource>(url, payload)

  return data
}

const formatMenuMappingNestedCustomizationPayload = ({
  attributes,
  parentOptionId,
  customizationId,
}: {
  attributes: { sort_position?: number }
  parentOptionId: MenuMappingCustomizationOptionResource['id']
  customizationId?: MenuMappingCustomization['id']
}) => {
  return {
    data: {
      type: TypeName.MenuMappingCustomization,
      attributes,
      relationships: {
        parent_option: {
          data: { id: parentOptionId, type: TypeName.MenuMappingCustomizationOption },
        },
        customization: {
          data: { id: customizationId, type: TypeName.MenuMappingCustomization },
        },
      },
    },
  }
}
