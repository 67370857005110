import { FormattedMenuSectionInfo, ItemModifier } from 'api'
import EDIT_ICON from 'assets/icons/edit-clear-icon.svg'
import { useIsMobile } from 'hooks'
import { FC, Fragment, useEffect, useRef, useState } from 'react'
import theme from 'theme'

import ItemModifierRow from './ItemModifierRow'
import { ModifierValuesMap } from './MenuSectionEditor'
import * as Styled from './styles'

type ExpandNestedModifiersMap = {
  [modifierId: ItemModifier['id']]: boolean
}

type Props = {
  customization: FormattedMenuSectionInfo
  groupModifiers?: FormattedMenuSectionInfo[]
  expandAllModifiers?: boolean
  modifierValues: ModifierValuesMap
  onChangeModifierValues?: ({
    modId,
    price,
    title,
  }: {
    modId: string
    price?: number
    title?: string
  }) => void
  onDeleteModifier: (modId: string, customization: FormattedMenuSectionInfo) => void
  isItemModal?: boolean
  setEditGroup?: (groupId: string) => void
  isEditGroup?: boolean
  groupValues?: { title?: string; min_permitted?: number; max_permitted?: number }
  updateGroupValues?: ({
    title,
    min_permitted,
    max_permitted,
  }: {
    title?: string
    min_permitted?: number
    max_permitted?: number
  }) => void
}

export type ModifiersById = {
  [id: string]: { title: string; price: number }
}

const ModifierSubsectionEditor: FC<Props> = ({
  customization,
  groupModifiers,
  expandAllModifiers,
  modifierValues,
  onChangeModifierValues,
  onDeleteModifier,
  isItemModal,
  setEditGroup,
  isEditGroup,
}) => {
  const { isMobile } = useIsMobile()
  const [expandNestedModifiers, setExpandNestedModifiers] = useState<ExpandNestedModifiersMap>({})
  const inputRef = useRef<HTMLInputElement | null>(null)

  useEffect(() => {
    if (expandAllModifiers) {
      const allModifierWithChildrenIds = groupModifiers
        ?.filter((mod) => mod.children.customizations?.length)
        ?.map((mod) => mod.id)
      const allModifiersMap: ExpandNestedModifiersMap = {}
      allModifierWithChildrenIds?.forEach((id) => {
        allModifiersMap[id] = true
      })

      setExpandNestedModifiers(allModifiersMap)
    } else {
      setExpandNestedModifiers({})
    }
  }, [expandAllModifiers])

  useEffect(() => {
    if (inputRef.current && isEditGroup) {
      inputRef.current?.focus()
    }
  }, [isEditGroup])

  // Modifiers can only be edited through the Item Individual View, not on the main menu, for simplicity's sake.
  return (
    <Styled.SubsectionContainer>
      <Styled.HeaderRow style={{ justifyContent: 'space-between' }}>
        <div style={{ display: 'flex', alignItems: 'center' }}>
          {isItemModal && setEditGroup && (
            <Styled.IconButton
              onClick={() => {
                setEditGroup(customization.id)
              }}
              margin='10px'
            >
              <img src={EDIT_ICON} alt='edit' />
            </Styled.IconButton>
          )}

          <Styled.ModifierTitle marginLeft={'12px'}>
            {customization.attributes.title}
          </Styled.ModifierTitle>
        </div>

        <Styled.TopRowText textAlign='end' fontWeight={theme.fontWeight.normal}>
          Min: {customization.attributes.min_permitted} / Max:{' '}
          {customization.attributes.max_permitted}
        </Styled.TopRowText>
      </Styled.HeaderRow>

      {groupModifiers?.map((modifier) => {
        const nestedModGroups = modifier?.children.customizations

        return (
          <Fragment key={modifier.id}>
            <ItemModifierRow
              key={modifier.id}
              modifierId={modifier.id}
              modifier={modifierValues[modifier.id]}
              checkBoxContainerStyle={{ paddingLeft: 12 }}
              modifiersExist={!!nestedModGroups?.length}
              onRowClick={() =>
                setExpandNestedModifiers((prevExpandStatus) => ({
                  ...prevExpandStatus,
                  [modifier.id]: !prevExpandStatus[modifier.id],
                }))
              }
              customization={customization}
              isExpanded={expandNestedModifiers[modifier.id]}
              onChangeModifierValues={onChangeModifierValues}
              onDeleteModifier={onDeleteModifier}
              isEditor
              isItemModal={isItemModal}
            />

            {/* Another level of nesting: customization group & modifiers */}
            {expandNestedModifiers[modifier.id] &&
              nestedModGroups?.map((customization) => (
                <Styled.SubsectionContainer key={customization.id}>
                  <Styled.HeaderRow>
                    <Styled.ModifierTitle marginLeft={isMobile ? '30px' : '120px'}>
                      {customization.attributes.title}
                    </Styled.ModifierTitle>
                    <Styled.TopRowText textAlign='end' fontWeight={theme.fontWeight.normal}>
                      Min: {customization.attributes.min_permitted} / Max:{' '}
                      {customization.attributes.max_permitted}
                    </Styled.TopRowText>
                  </Styled.HeaderRow>
                  {customization.children.customization_options?.map((modifier) => (
                    <ItemModifierRow
                      key={modifier.id}
                      modifierId={modifier.id}
                      modifier={modifierValues[modifier.id]}
                      customization={customization}
                      onChangeModifierValues={onChangeModifierValues}
                      isNested={true}
                      onDeleteModifier={onDeleteModifier}
                      isEditor
                      isItemModal={isItemModal}
                    />
                  ))}
                </Styled.SubsectionContainer>
              ))}
          </Fragment>
        )
      })}
    </Styled.SubsectionContainer>
  )
}

ModifierSubsectionEditor.displayName = 'ModifierSubsectionEditor'

export default ModifierSubsectionEditor
