import styled from '@emotion/styled';

export const NoData = styled.div`
  text-align: center;
  padding: 12px;
  font-size: 12px;
  font-weight: 500;
  color: #777;
  border-bottom: 1px solid #ddd;
  @media (max-width: 440px) {
    padding: 8px;
    font-size: 10px;
  }
`

export const TableRow = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  gap: 16px;
  
  &:not(:last-child) {
    border-bottom: 1px solid #D7D7D7;
  }

  @media (max-width: 600px) {
    flex-direction: column;
    align-items: flex-start;
  }
`

export const ToDoTable = styled.div`
  width: 100%;
  border-collapse: collapse;
  text-align: left;
`

export const TableCell = styled.div<{ centered?: boolean, fullWidth?: boolean, pushRight?: boolean }>`
  padding: 16px 0px;
  font-size: 12px;
  font-weight: 500;
  color: #292929;

  ${({ fullWidth }) => fullWidth && `flex-grow: 1;`}

  ${({ centered }) => centered && `text-align: center;`}

  ${({ pushRight }) => pushRight && `margin-left: auto;`}
`

export const TableHeaderCell = styled.div`
  padding: 12px;
  font-size: 12px;
  font-weight: 600;
  color: #777;
  text-align: left;

  @media (max-width: 600px) {
    padding: 8px;
    font-size: 10px;
  }
`

export const TodoContainer = styled.div`
  width: 100%;
  border-collapse: collapse;
  text-align: left;
`

export const ActionsButtons = styled.div`
  display: flex;
  gap: 8px;

  @media (max-width: 440px) {
    margin-left: auto  
  }
`

export const SuggestedAdContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 16px;
  align-self: stretch;
`

export const CampaignIcon = styled.div`
  color: #1B51A4;

  & svg {
    width: 40px;
    height: 40px;
    fill: #1B51A4;
  }
`

export const TitleContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  flex: 1 0 0;
  font-style: normal;
`

export const CampaignTitle = styled.div`
  color: #1B51A4;
  font-size: 14px;
  font-weight: 600;
  line-height: 150%;
  text-decoration-line: underline;
  cursor: pointer;
  
  max-width: 900px;
  overflow: hidden;
  text-overflow: ellipsis;
`

export const CampaignDescription = styled.div`
  color: #777;
  font-size: 12px;
  font-weight: 400;
  line-height: 150%;
`

