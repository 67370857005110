import Tippy from '@tippyjs/react'
import { FormattedMenuSectionInfo, ItemModifier, MenuMappingItemDetails, TypeName } from 'api'
import clock_icon_gray from 'assets/icons/clock-gray.svg'
import clock_icon_green from 'assets/icons/clock-green.svg'
import ellipse_2_green from 'assets/icons/ellipse-2-green.svg'
import ellipse_2_red from 'assets/icons/ellipse-2-red.svg'
import PLUS_ICON from 'assets/icons/plus-icon.svg'
import TRASH_ICON from 'assets/icons/trash-icon.svg'
import { LoadingSkeleton, Toggle } from 'components'
import { INDEFINITELY_86ED } from 'features/manage-locations/IndividualMenu/MenuSectionPreview'
import { useIsMobile } from 'hooks'
import { Dispatch, SetStateAction } from 'react'
import { colors } from 'theme'

import * as Styled from './styles'
import { formatItemDescription } from './util/formatMenuText'

type Props = {
  item: FormattedMenuSectionInfo
  itemIdx: number
  isSelected?: boolean
  isExpanded?: boolean
  onItemToggle?: (id: MenuMappingItemDetails['id']) => void
  isPreviewOnly?: boolean
  onRowExpand?: () => void
  modifiersExist?: boolean
  setSuspendUntilItem?: Dispatch<
    SetStateAction<
      | {
          id: MenuMappingItemDetails['id'] | ItemModifier['id']
          type: TypeName.Item | TypeName.CustomizationOption
        }
      | undefined
    >
  >
  existingSuspendUntil?: string | null | undefined
  checkBoxContainerStyle?: React.CSSProperties
  isModifiersLoading: boolean
  menuItemStyle?: React.CSSProperties
  menuItemTextStyle?: React.CSSProperties
  setSelectedItemInModal?: (item: FormattedMenuSectionInfo) => void
  isEditor?: boolean
  onDeleteItem?: (item: FormattedMenuSectionInfo) => void
  itemPrice?: number
  onChangeItemPrice?: (itemSubsectionId: string, newPrice?: number) => void
  itemSubsectionId?: string
}

const MenuItemRow = ({
  item,
  itemIdx,
  isSelected,
  isExpanded,
  onItemToggle,
  isPreviewOnly,
  onRowExpand,
  modifiersExist,
  setSuspendUntilItem,
  existingSuspendUntil,
  checkBoxContainerStyle,
  isModifiersLoading,
  menuItemStyle = {},
  menuItemTextStyle = {},
  setSelectedItemInModal,
  isEditor,
  onDeleteItem,
  itemPrice,
  onChangeItemPrice,
  itemSubsectionId,
}: Props) => {
  const { isMobile } = useIsMobile()

  return (
    <Styled.ItemRow
      key={item.id}
      isHighlighted={itemIdx % 2 === 0}
      isExpanded={isExpanded}
      style={menuItemStyle}
      role='button'
      onClick={
        setSelectedItemInModal && !isModifiersLoading
          ? () => setSelectedItemInModal(item)
          : undefined
      }
    >
      {!isPreviewOnly && !isEditor && onItemToggle && (
        <Styled.CheckboxContainer
          style={checkBoxContainerStyle}
          onClick={(e) => e.stopPropagation()}
        >
          <Toggle
            id={`toggle-${item.id}`}
            isToggled={isSelected}
            onToggle={() => {
              onItemToggle(item.id)
            }}
            activeColor={colors.blue[700]}
          />
        </Styled.CheckboxContainer>
      )}
      <Styled.ImgContainer padding={isPreviewOnly ? '0 12px 0 12px' : null}>
        <Styled.ItemImg src={item.attributes.image_url} />
      </Styled.ImgContainer>
      <div style={{ flexBasis: '100%' }}>
        <Styled.ItemTitle style={menuItemTextStyle}>{item.attributes.title}</Styled.ItemTitle>
        {!isMobile && (
          <Styled.ItemDescription>
            {formatItemDescription(item.attributes.description)}
          </Styled.ItemDescription>
        )}
      </div>
      {!isSelected && !isMobile && !isEditor && (
        <Styled.Status active={isSelected}>
          <Styled.Icon src={isSelected ? ellipse_2_green : ellipse_2_red} alt='ellipse icon' />
          inactive
        </Styled.Status>
      )}
      {/* row spacer */}
      {isSelected && !isMobile && <div style={{ flexBasis: '20%' }} />}

      <Styled.InputPriceContainer isMobile={isMobile} onClick={(e) => e.stopPropagation()}>
        <Styled.Input
          value={itemPrice}
          type='number'
          pattern='[0-9]*'
          disabled={!isEditor}
          onChange={(e) => {
            if (onChangeItemPrice && itemSubsectionId) {
              onChangeItemPrice(
                itemSubsectionId,
                e.target.value ? parseFloat(e.target.value) : undefined,
              )
            }
          }}
        />
      </Styled.InputPriceContainer>

      {isEditor && onDeleteItem && (
        <Styled.IconButton margin='0 10px 0 10px' onClick={(e) => e.stopPropagation()}>
          <img
            src={TRASH_ICON}
            width='16px'
            alt='delete'
            onClick={() => onDeleteItem(item)}
            style={{ maxWidth: '16px' }}
          />
        </Styled.IconButton>
      )}

      <Styled.OptionsContainer
        onClick={(e) => {
          if (onRowExpand) {
            e.stopPropagation()
            onRowExpand()
          }
        }}
      >
        {isModifiersLoading && <LoadingSkeleton isFlex={true} />}
        {modifiersExist && !isPreviewOnly && (
          <Styled.OptionsIcon src={PLUS_ICON} width={17} alt='ellipse_menu' />
        )}
      </Styled.OptionsContainer>

      {!isSelected && setSuspendUntilItem && !isEditor && (
        <div
          style={{ flexBasis: '56px' }}
          onClick={(e) => {
            if (item) {
              e.stopPropagation()
              setSuspendUntilItem({ id: item.id, type: TypeName.Item })
            }
          }}
        >
          <Tippy content={existingSuspendUntil}>
            <Styled.Icon
              src={existingSuspendUntil !== INDEFINITELY_86ED ? clock_icon_green : clock_icon_gray}
              alt='clock icon'
              iconSize='16'
            />
          </Tippy>
        </div>
      )}
    </Styled.ItemRow>
  )
}

export default MenuItemRow
