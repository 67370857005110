import styled from '@emotion/styled';
import { Card, Grid, Typography, LinearProgress, SvgIcon } from '@mui/material';

export const StyledCard = styled(Card)`
  border-radius: 2px;
  max-width: 320px;
  min-height: 150px;
  display: flex;
  flex-direction: column;
  text-align: initial;
  margin: 0 auto;
`;

export const HeaderRoot = styled(Grid)`
  padding: 7px 16px;
`;

export const HeaderUrl = styled(Grid)`
  position: relative;
  padding: 24px 16px 0 24px;
`;

export const HeaderUrlAd = styled(Typography)`
  font-family: Montserrat;
  font-weight: 600;
  font-size: 12px;
  color: #0E1436;
`;

export const Bullet = styled.div`
  width: 4px;
  height: 4px;
  background: #CFD0D7;
  border-radius: 100px;
  position: relative;
  top: 7px;
  margin-left: 8px;
  margin-right: 8px;
`;

export const HeaderUrlLink = styled(Typography)`
  font-family: Montserrat;
  font-weight: 400;
  font-size: 12px;
  color: #0E1436;
`;

export const HeaderUrlInfo = styled(Grid)`
  position: absolute;
  right: 15px;
`;

export const InfoIconStyled = styled.span`
  font-size: 16px;
  color: #CFD0D7;
`;

export const HeaderTitle = styled(Typography)`
  font-size: 16px;
  font-weight: 600;
  font-family: Montserrat;
  color: #007BFF;
`;

export const ContentContainer = styled(Grid)`
  padding-top: 0;
`;

export const ContentText = styled(Typography)`
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  font-family: Montserrat;
  color: #3E435E;
`;

export const PromotionWrapper = styled.div`
  margin: 12px 0 10px 16px;
`;

export const SiteLinkWrapper = styled.div`
  margin: 8px 0 10px 25px;
`;

export const SiteLink = styled(Typography)`
  line-height: 2;
  color: #007BFF;
`;

export const StyledLinearProgress = styled(LinearProgress)`
  height: 10px;
  border-radius: 5px;
  margin-top: 15px;
`;

export const LinearBar = styled.div`
  border-radius: 5px;
  background-color: #1a90ff;
`;

export const SkeletonWrapper = styled.div`
  margin-left: 25px;
  margin-bottom: 10px;
`;

export const InfoRemovedIcon = styled.span`
  font-size: 20px;
  color: #9FA1AF;
  position: relative;
  top: 5px;
  left: 5px;
`;

export const SellIcon = styled(SvgIcon)`
  font-size: 14px;
  color: #6e7286;
  position: relative;
  left: 5px;
`;

export const PromotionDescriptionText = styled(Typography)`
  color: #3e435e;
  font-size: 14px;
  font-weight: 600;
  font-family: Montserrat;
`;