import styled from 'styled-components';
import {
  TableCell as MuiTableCell,
  Tab,
  TableRow,
  Chip,
  Typography,
  IconButton,
  MenuItem,
} from '@mui/material';
import { colors, fontFamily, fontWeight, breakpoints } from 'theme';
import { OrderDisplayStatus } from './OrderManagement';

const getStatusStyles = (status: OrderDisplayStatus) => {
  switch (status) {
    case OrderDisplayStatus.COMPLETED:
      return {
        backgroundColor: 'rgba(50, 150, 93, 0.1)',
        color: '#32965D',
      };
    case OrderDisplayStatus.CANCELLED:
      return {
        backgroundColor: 'rgba(193, 48, 48, 0.1)',
        color: '#C13030',
      };
    default:
      return {
        backgroundColor: 'transparent',
        color: '#000',
      };
  }
};

const commonFontStyle = {
  fontFamily: fontFamily.inter,
  fontSize: '13px',
};

const cellPadding = {
  padding: '10px 16px',
};

const hideOnMobile = {
  [`@media ${breakpoints.xxs}`]: {
    '&&': {
      display: 'none',
    },
  },
};

const showOnMobile = {
  [`@media ${breakpoints.xxs}`]: {
    '&&': {
      display: 'table-cell',
    },
  },
};

export const Container = styled.div({
  display: 'flex',
  flexDirection: 'column',
  width: '100%',
  maxWidth: '1000px',
  rowGap: '1rem',
  paddingBottom: 'calc(2vh + 24px)',
  marginTop: '60px',
  [`@media ${breakpoints.xxs}`]: {
    marginTop: '28px',
  },
});

export const StyledTableRow = styled(TableRow)({
  borderTop: '1px solid #ddd',
  borderBottom: '1px solid #ddd',
});

export const StyledTab = styled(Tab)<{ active?: boolean }>(({ active }) => ({
  '&&': {
    ...commonFontStyle,
    fontSize: '13px',
    fontWeight: fontWeight.semibold,
    color: `${colors.blue.DEFAULT} !important`,
    backgroundColor: active ? colors.blue[400] : 'transparent',
    borderRadius: active ? '4px' : '0',
    textTransform: 'none',
    padding: '8px',
    minHeight: 'auto',
    minWidth: 'auto',
  },
}));

export const StyledChip = styled(Chip)<{ status: OrderDisplayStatus }>(({ status }) => ({
  '&&': {
    ...commonFontStyle,
    fontSize: '10px',
    fontWeight: fontWeight.medium,
    borderRadius: '5px',
    padding: 0,
    height: '23px',
    backgroundColor: getStatusStyles(status).backgroundColor,
    color: getStatusStyles(status).color,
    textTransform: 'capitalize',
  },
  '& .MuiChip-icon': {
    color: `${getStatusStyles(status).color} !important`,
    marginLeft: 0,
    marginRight: 0,
  },
}));

export const CustomTableCell = styled(MuiTableCell)({
  '&&': {
    ...commonFontStyle,
    fontSize: '11px',
    fontWeight: fontWeight.medium,
    ...cellPadding,
    color: '#777777',
  },
  ...hideOnMobile,
});

export const TableCell = styled(MuiTableCell)({
  '&&': {
    ...commonFontStyle,
    fontWeight: fontWeight.medium,
    ...cellPadding,
    color: '#000',
  },
  ...hideOnMobile,
});

export const MobileVisibleTableCell = styled(TableCell)({
  ...showOnMobile,
});

export const MobileVisibleTableHeader = styled(MuiTableCell)({
  '&&': {
    ...commonFontStyle,
    fontSize: '11px',
    fontWeight: fontWeight.medium,
    ...cellPadding,
    color: '#777777',
  },
  ...showOnMobile,
});

export const RowPair = styled.div({
  display: 'flex',
  justifyContent: 'space-between',
  gap: '16px',
});

export const RowSingle = styled.div({
  display: 'flex',
  flexDirection: 'column',
  padding: '8px 0',
});


export const SubmissionTime = styled(Typography)({
  '&&': {
    ...commonFontStyle,
    fontWeight: fontWeight.medium,
    color: '#000',
  },
});

export const SubmissionDate = styled(Typography)({
  '&&': {
    ...commonFontStyle,
    color: '#777777',
  },
});

export const Address = styled(Typography)({
  '&&': {
    ...commonFontStyle,
    fontWeight: fontWeight.medium,
    color: '#000',
  },
});

export const MenuItems = styled(MenuItem)({
  '&&': {
    ...commonFontStyle,
  },
});

export const Location = styled(Typography)({
  '&&': {
    ...commonFontStyle,
    color: '#777777',
  },
});

export const CenteredIconButton = styled(IconButton)({
  '&&': {
    justifyContent: 'center',
    alignItems: 'center',
    textAlign: 'center',
  },
});

export const CenterTableCell = styled(TableCell)({
  '&&': {
    textAlign: 'center',
  },
});

export const PaginationContainer = styled.div({
  display: 'flex',
  justifyContent: 'flex-end',
  padding: '1rem',
});
