import { algolia } from "config"
import algoliasearch, {SearchIndex} from 'algoliasearch/lite'
import { useCallback, useMemo } from "react"
import { getPrefixedIndexName } from "utils/algolia"

const searchClient = algoliasearch(algolia.appId, algolia.apiSearchKey)
/**
 * @param indexName - The name of the Algolia index to search without the environment prefix.
 * e.g: 'Businesses' instead of 'dev_Businesses'
*/
const useSearchAlgoliaIndex = <T>(indexName: string) => {
  const index = useMemo(() => searchClient.initIndex(getPrefixedIndexName(indexName)), [indexName]);

  /**
   * Searches the configured Algolia index with the given query and options.
   * This function is memoized so it's safe to use it as a dependency in other hooks.
   */
  const search = useCallback(async (
    query: string, 
    options?: Parameters<SearchIndex['search']>[1]
  ) => {
    return await index.search<T>(query, options)
  }, [index])

  return search;
}

export default useSearchAlgoliaIndex;