import { ReactComponent as AlertCircle } from 'assets/icons/alert-icon-wrap.svg'
import { ReactComponent as RightArrow } from 'assets/icons/chevron-right-icon.svg'
import { Button } from 'components'
import { Field } from 'formik'
import { Link as RouterLink } from 'react-router-dom'
import styled from 'styled-components'
import { borderRadius, breakpoints, colors, fontFamily, fontWeight, typography } from 'theme'

export const AvailabilityContainer = styled.div<{ isColumn?: boolean }>(
  {
    display: 'flex',
    width: '50px',
    height: '15px',
    top: '5px',
    left: '203px',
    gap: '5px',
  },
  ({ isColumn }) =>
    isColumn && {
      flexDirection: 'column',
    },
)

export const MobileRowContainer = styled.div({
  border: '0px 0px 1px 0px',
  display: 'flex',
  gap: '12px',
  flexDirection: 'column',
  fontFamily: fontFamily.inter,
  fontSize: typography.xs.fontSize,
  fontWeight: fontWeight.semibold,
  lineHeight: typography.xxs.lineHeight,
  letterSpacing: '0em',
  textAlign: 'left',
  color: colors.primary.medium,
  width: '100%',
})

export const PlatformContainer = styled.div({
  fontFamily: fontFamily.inter,
  fontSize: typography.xxs.fontSize,
  fontWeight: fontWeight.semibold,
  lineHeight: typography.xxs.lineHeight,
  letterSpacing: '0em',
  textAlign: 'left',
  color: colors.primary.medium,
  display: 'flex',
  alignItems: 'center',
})

export const MenuContainer = styled.div({
  border: '0px 0px 1px 0px',
  display: 'flex',
  justifyContent: 'space-between',
  // flexDirection: 'column',
  fontFamily: fontFamily.inter,
  fontSize: typography.xs.fontSize,
  fontWeight: fontWeight.semibold,
  lineHeight: typography.xxs.lineHeight,
  letterSpacing: '0em',
  textAlign: 'left',
  color: colors.primary.medium,
})

export const MobilePlatformContainer = styled.div({
  fontFamily: fontFamily.inter,
  fontSize: typography.xxs.fontSize,
  fontWeight: fontWeight.semibold,
  lineHeight: typography.xxs.lineHeight,
  letterSpacing: '0em',
  textAlign: 'left',
  color: colors.primary.medium,
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  width: '100%',
})

export const RedStatus = styled.div({
  color: colors.red[500],
  width: '39px',
  height: '15px',
  fontFamily: fontFamily.inter,
  fontSize: typography.xxxs.fontSize,
  fontWeight: fontWeight.medium,
  lineHeight: typography.xxxs.lineHeight,
  letterSpacing: '0em',
})

export const SuccessStatus = styled.div({
  width: '39px',
  height: '15px',
  fontFamily: fontFamily.inter,
  fontSize: typography.xxxs.fontSize,
  fontWeight: fontWeight.medium,
  lineHeight: typography.xxxs.lineHeight,
  letterSpacing: '0em',
  color: colors.green.darkGreen,
})

export const HeaderCell = styled.th<{ width?: number | string }>({
  color: colors.gray.mediumLight,
  fontFamily: fontFamily.inter,
  fontSize: typography.xs.fontSize,
  fontWeight: fontWeight.normal,
  lineHeight: typography.xxs.lineHeight,
  letterSpacing: '0em',
  [`@media ${breakpoints.xxs}`]: {
    lineHeight: typography.xxxs.lineHeight,
    fontSize: typography.xxxs.fontSize,
  },
})

export const MobileTableContainer = styled.div({
  width: '50px',
  height: '15px',
  top: '5px',
  left: '203px',
  gap: '5px',
})

export const MobileRow = styled.div({
  gap: '8px',
  alignItems: 'center',
  display: 'flex',
})

export const AvailabilityTimeStamp = styled.div({
  ...typography.xs,
})

export const MenuPublishTimeStamp = styled.div({
  color: colors.primary.medium,
  fontFamily: fontFamily.inter,
  fontSize: '9px',
  fontStyle: 'normal',
  fontWeight: fontWeight.normal,
  lineHeight: '150%' /* 13.5px */,
  whiteSpace: 'nowrap',
})

export const TextButton = styled.button({
  fontWeight: fontWeight.semibold,
  color: colors.primary.DEFAULT,
  ':disabled': {
    color: colors.gray.DEFAULT,
  },
})

export const Grid = styled.div({
  display: 'flex',
  flexWrap: 'wrap',
  gap: '24px',
  height: 'auto',
  width: '100%',
})

export const LocationContainer = styled.div<{ width?: number | string }>({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  maxWidth: '668px',
  width: '100%',
  paddingBottom: '5rem',
  backgroundColor: '#FAFAFA',
  gap: '24px',
  marginTop: '60px',
  [`@media ${breakpoints.xxs}`]: {
    marginTop: '28px',
  },
})

export const LocationName = styled.div<{
  width?: number | string | null
  singleLocation: number | undefined
}>(
  {
    fontSize: '20px',
    lineHeight: '28px',
    fontWeight: fontWeight.semibold,
    width: 'auto',
    padding: '12px 0',
    border: '1px solid #D7D7D7',
    borderRadius: '8px',
  },
  ({ width }) => width && { width },
  ({ singleLocation }) =>
    singleLocation && {
      border: 0,
    },
)

export const LocationHeader = styled.div({
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  width: '100%',
  flexWrap: 'wrap-reverse',
  gap: '12px',
})

export const LocationFormItem = styled.div<{ width?: string | null; upchargeReadOnly?: boolean }>(
  {
    display: 'flex',
    height: '217px',
    width: '100%',
    padding: '24px 24px',
    backgroundColor: colors.gray[450],
    borderRadius: '8px',
  },
  ({ upchargeReadOnly }) =>
    upchargeReadOnly && {
      width: '668px',
    },
  ({ width }) => width && { width },
  {
    [`@media (min-width: 734px)`]: {
      width: '322px',
    },
  },
)

export const LocationConnectionContainer = styled.div({
  display: 'flex',
  flexDirection: 'row',
  height: 'auto',
  width: '100%',
  maxWidth: '100%',
})

type StatusProps = { isSuccess: boolean }

export const StatusContainer = styled.div<{ width?: string }>(
  {
    display: 'flex',
    gap: '5px',
    alignItems: 'center',
    width: 'auto',
  },
  ({ width }) => width && { width },
)

export const StatusText = styled.h4<StatusProps>(
  {
    display: 'block',
    fontSize: '10px',
    fontWeight: fontWeight.medium,
    color: colors.red.darkRed,
    '&:first-letter': {
      textTransform: 'capitalize',
    },
  },
  ({ isSuccess }) =>
    isSuccess && {
      color: colors.green.darkGreen,
    },
)

export const ImportStatusText = styled(StatusText)({
  width: '90%',
})

export const RightColumn = styled.div({
  width: '60%',
  display: 'block',
  fontSize: '1rem',
  marginTop: '.5rem',
})

export const RightStatusColumn = styled(RightColumn)({
  marginTop: '0rem',
})

type EnqueuedProps = { isVisible: boolean }

export const SmallText = styled.span<EnqueuedProps>(
  {
    display: 'none',
    fontSize: '0.7rem',
    color: 'black',
    textTransform: 'none',
    marginLeft: '5px',
  },
  ({ isVisible }) =>
    isVisible && {
      display: 'inline',
    },
)

export const TimeStampText = styled.p({
  width: '100%',
  display: 'block',
  fontSize: '1rem',
  fontWeight: fontWeight.medium,
  color: colors.blue[700],
  margin: '.5rem 0',
})

export const EditMenuLabel = styled.div({
  fontSize: '.8rem',
  fontWeight: fontWeight.bold,
  color: colors.black,
  margin: '.5rem 0 0 0',
})

export const ButtonContainer = styled.div({
  display: 'flex',
  flexDirection: 'row',
  columnGap: '0.5rem',
  width: '100%',
  marginBottom: 0,
})

export const TooltipCard = styled.div({
  position: 'relative',
  '&:hover .tooltipBox': {
    backgroundColor: 'white',
    visibility: 'visible',
    width: '300px',
    padding: '8px 8px',
  },
})

export const TooltipBox = styled.div({
  position: 'absolute',
  left: '30px',
  visibility: 'hidden',
  color: 'black',
  backgroundColor: 'transparent',
  width: '150px',
  padding: '5px 5px',
  borderRadius: '4px',
  fontWeight: fontWeight.medium,
  zIndex: 1,
})

export const InputUpchargeContainer = styled.div({
  display: 'inline-block',
  position: 'relative',
  width: 128,
  '&::before': {
    position: 'absolute',
    top: 8,
    right: '.5em',
    content: '"%"',
  },
})

export const Input = styled(Field)({
  width: '100%',
  padding: '0.5rem 1.5rem .5rem 1rem',
  backgroundColor: colors.white,
  appearance: 'none',
  borderWidth: 1,
  borderColor: colors.blue['300'],
  borderRadius: borderRadius.lg,
  fontSize: '1rem',
  lineHeight: '1.5rem',
  fontWeight: fontWeight.bold,
})

export const Section = styled.div<{ width?: number | string }>(
  {
    overflow: 'hidden',
    color: colors.primary.medium,
    fontFamily: fontFamily.inter,
    fontSize: typography.semiXS.fontSize,
    fontWeight: fontWeight.bold,
    lineHeight: typography.semiXS.lineHeight,
    letterSpacing: '0em',
    textAlign: 'left',
    backgroundColor: colors.gray[450],
    padding: '24px',
    borderRadius: '8px',
    maxWidth: '668px',
    minWidth: '342px',
    display: 'flex',
    flexDirection: 'column',
    rowGap: '40px',
    [`@media ${breakpoints.md}`]: {
      minWidth: '668px',
    },
    [`@media ${breakpoints.xs}`]: {
      width: '100%',
    },
    [`@media ${breakpoints.xxs}`]: {
      rowGap: '24px',
    },
  },
  ({ width }) => ({ width }),
)

export const SectionHeader = styled.div({
  display: 'flex',
  justifyContent: 'space-between',
  flexWrap: 'wrap',
  rowGap: '24px',
  alignItems: 'center',
  [`@media ${breakpoints.xxs}`]: {
    width: '100%',
    flexDirection: 'column',
    alignItems: 'baseline',
  },
})

export const SectionHeaderRight = styled.div({
  display: 'flex',
  columnGap: '0.75rem',
})

export const SectionTitle = styled.div({
  fontFamily: fontFamily.inter,
  fontSize: typography.semiXS.fontSize,
  fontWeight: fontWeight.bold,
  lineHeight: typography.semiXS.lineHeight,
  letterSpacing: '0em',
  textAlign: 'left',
  width: '72px',
  height: '23px',
  color: colors.primary.medium,
})

export const BaseButton = styled(Button)<{ variant?: string }>(
  {
    minWidth: 'auto',
    padding: '8px 16px 8px 16px',
    fontFamily: fontFamily.inter,
    fontSize: typography.semiXS.fontSize,
    fontWeight: fontWeight.semibold,
    lineHeight: typography.semiXS.lineHeight,
    textWrap: 'nowrap',
    letterSpacing: '0em',
    textAlign: 'left',
    backgroundColor: 'transparent',
    borderStyle: 'solid',
    color: colors.blue[700],
    borderColor: colors.primary.light,
    borderWidth: 1,
    height: '39px',
    '&:hover': {
      backgroundColor: colors.blue[100],
    },
    [`@media ${breakpoints.xxs}`]: {
      fontSize: typography.xs.fontSize,
      lineHeight: typography.xs.lineHeight,
    },
  },
  ({ variant }) =>
    variant === 'solid' && {
      backgroundColor: colors.blue[700],
      color: colors.gray[450],
    },
)

export const Link = styled(RouterLink)({
  whiteSpace: 'nowrap',
})

export const DoordashMenuErrorContainer = styled.div({
  display: 'flex',
  gap: '12px',
  alignItems: 'flex-start',
  borderLeft: '6px solid #ffbe0b',
  backgroundColor: colors.gray[450],
  borderRadius: '2px',
  width: '100%',
  height: 'auto',
  margin: '1rem 0',
})

export const AlertIcon = styled(AlertCircle)({
  margin: '1rem .75rem',
  width: '40px',
})

export const ChevronRightIcon = styled(RightArrow)({
  margin: '.2rem 0',
})

export const FeedbackSection = styled.div({
  display: 'flex',
  alignItems: 'flex-start',
  margin: '1.5rem',
})

export const ActivatePlatformSection = styled.div({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  marginTop: '2rem',
  width: '80%',
  borderLeft: '5px solid #ffbe0b',
  backgroundColor: '#FFFFFF',
  borderRadius: '2px',
})

export const FeedbackMessage = styled.div({
  margin: '1rem 0',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'flex-start',
})

export const ActivateWrapper = styled.div({
  margin: '.5rem',
  display: 'flex',
  flexDirection: 'row',
})

export const ActivateMessage = styled.div({
  margin: '.5rem .5rem',
})

export const ActivateLinkSection = styled.div({
  display: 'flex',
  flexDirection: 'row',
  margin: '.5rem 3.3rem',
})
export const ActivateLink = styled.div({
  display: 'flex',
  flexDirection: 'row',
  marginRight: '1rem',
  fontWeight: fontWeight.semibold,
  color: colors.blue[700],
  '&:hover': {
    cursor: 'pointer',
  },
})

export const MenuErrorMessage = styled.div({
  margin: '.5rem 0',
})

export const ResyncActivateButtonWrapper = styled.div({
  margin: '1rem 0',
})

export const IconContainer = styled.button(
  {
    margin: '.3rem',
    img: {
      height: 25,
      width: 25,
    },
  },
  {
    ':disabled': {
      backgroundColor: colors.gray[300],
      borderColor: colors.gray[300],
      color: colors.gray.dark,
      img: {
        color: colors.gray.dark,
      },
    },
  },
)

export const Icon = styled.img<{ iconSize?: string }>(
  {
    width: '6px',
    height: '6px',
  },
  ({ iconSize }) =>
    iconSize && {
      width: `${iconSize}px`,
      height: `${iconSize}px`,
    },
)

export const ChilipiperMessageSection = styled.div<{ width?: string }>(
  {
    backgroundColor: 'rgba(27, 81, 164, 0.1)',
    display: 'flex',
    flexDirection: 'column',
    gap: '8px',
    width: '100%',
    padding: '12px',
    borderRadius: '4px',
    fontWeight: fontWeight.semibold,
  },
  ({ width }) =>
    width && {
      width: width,
    },
)

export const ChilipiperToastMessage = styled.div({
  color: colors.primary.medium,
  fontSize: '13px',
})

export const ChilipiperToastLink = styled.button({
  display: 'flex',
  alignItems: 'center',
  color: colors.blue[700],
  width: 'max-content',
  fontWeight: fontWeight.semibold,
  fontSize: '12px',
})

export const ChilipiperEmbed = styled.iframe({
  width: '340px',
  height: '500px',
  [`@media ${breakpoints.lg}`]: {
    width: '560px',
    height: '700px',
  },
  [`@media ${breakpoints.xl}`]: {
    width: '560px',
    height: '700px',
  },
})

export const TextContainer = styled.div({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'flex-start',
})

export const DoordashWarningToastButton = styled.button({
  fontFamily: fontFamily.inter,
  fontWeight: fontWeight.semibold,
  fontSize: typography.semiXS.fontSize,
  lineHeight: typography.semiXS.lineHeight,
  color: colors.blue[700],
  backgroundColor: 'transparent',
  display: 'flex',
  alignItems: 'center',
  gap: '4px',
  ':hover': {
    cursor: 'pointer',
  },
})
