import { useEffect, useState } from 'react'
import { ReactComponent as DownloadIcon } from 'assets/icons/download.svg'
import { LoadingSkeleton, Select } from 'components'
import useGetInvoices from 'hooks/useGetInvoices'
import { orderBy, isEmpty, startCase } from 'lodash'
import moment from 'moment'
import * as Styled from './styles'
import { useLocations } from 'components/MainLayout/MainLayout'
import { LocationSummary } from 'types'

const Billing = () => {
  const { locationsData } = useLocations()
  const { locations } = locationsData
  const [partnerId, setPartnerId] = useState<string | undefined>(undefined)
  const mapLocations = locations?.map((loc: LocationSummary) => ({
    value: loc.id.toString(),
    label: loc.name,
  }))

  useEffect(() => {
    if (!isEmpty(mapLocations) && !partnerId) {
      setPartnerId(mapLocations[0]?.value)
    }
  }, [mapLocations, partnerId])
 
  const [{ invoices, isLoading }] = useGetInvoices(partnerId)

  const sortedInvoices = orderBy(invoices, (item) => moment(item?.attributes?.due_date, 'MM/DD/YYYY'), 'desc')

  const renderSkeletonRows = () => {
    return Array.from({ length: 5 }).map((_, index) => (
      <tr key={index}>
        <Styled.InvoiceTableCell colSpan={4}>
          <LoadingSkeleton />
        </Styled.InvoiceTableCell>
      </tr>
    ))
  }

  const renderNoData = () => {
    return (
      <tr>
        <Styled.InvoiceTableCellNoData colSpan={4}>
          No data
        </Styled.InvoiceTableCellNoData>
      </tr>
    )
  }

  const handleLocationChange = (v: string) => {
    setPartnerId(v)
  }

  return (
    <Styled.BillingContainer>
      <Styled.BillingTitle>Billing</Styled.BillingTitle>
      <Styled.LocationsContainer>
        <Select
          options={mapLocations}
          placeholder={mapLocations[0]?.label || 'Loading...'}
          styles={{
            container: Styled.selectContainer,
            control: Styled.selectControl,
            menu: Styled.selectMenu,
            menuList: Styled.selectMenuList,
            singleValue: Styled.selectSingleValue,
            placeholder: Styled.selectPlaceholder,
            option: Styled.selectOption,
          }}
          onChange={handleLocationChange}
          value={mapLocations.find(option => option.value === partnerId)}
        />
      </Styled.LocationsContainer>
      <Styled.InvoiceContainer>
        <Styled.InvoiceTitle>Invoices</Styled.InvoiceTitle>
        <Styled.InvoiceTable>
          <thead>
            <tr>
              <Styled.InvoiceTableHeaderCell>Amount</Styled.InvoiceTableHeaderCell>
              <Styled.InvoiceTableHeaderCell>Due Date</Styled.InvoiceTableHeaderCell>
              <Styled.InvoiceTableHeaderCell>Status</Styled.InvoiceTableHeaderCell>
              <Styled.InvoiceTableHeaderCell></Styled.InvoiceTableHeaderCell>
            </tr>
          </thead>
          <tbody>
            {isLoading ? (
              renderSkeletonRows()
            ) : invoices?.length === 0 ? (
              renderNoData()
            ) : (
              sortedInvoices?.map((invoice, index) => (
                <tr key={index}>
                  <Styled.InvoiceTableCell>{`$${invoice.attributes.amount}`}</Styled.InvoiceTableCell>
                  <Styled.InvoiceTableCell>{invoice.attributes.due_date}</Styled.InvoiceTableCell>
                  <Styled.InvoiceTableCell>
                    {invoice.attributes.status === 'Paid' ? (
                      <Styled.PaidStatus>{startCase(invoice.attributes.status)}</Styled.PaidStatus>
                    ) : (
                      <Styled.CurrentStatus>{startCase(invoice.attributes.status)}</Styled.CurrentStatus>
                    )}
                  </Styled.InvoiceTableCell>
                  <Styled.InvoiceTableCell>
                    <Styled.DownloadIcon onClick={() => window.open(invoice.attributes.pdf_url.replace(/\s/g, ''))}>
                      <DownloadIcon />
                    </Styled.DownloadIcon>
                  </Styled.InvoiceTableCell>
                </tr>
              ))
            )}
          </tbody>
        </Styled.InvoiceTable>
      </Styled.InvoiceContainer>
    </Styled.BillingContainer>
  )
}

export default Billing
