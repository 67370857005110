import {
  ChevronLeft as ChevronLeftIcon,
  ChevronRight as ChevronRightIcon,
  Store as StoreIcon,
} from '@mui/icons-material'
import _ from 'lodash'
import React from 'react'
import { AdCard, CampaignAd } from 'types/digitalMarketing'
import { getCTAOptions } from 'utils/dmUtils'
import { v4 as uuidv4 } from 'uuid'

import FacebookButton from '../FacebookButton/FacebookButton'
import FacebookCard from '../FacebookCard/FacebookCard'
import {
  AdDescription,
  CardActionsRoot,
  CardContentRoot,
  CarouselContainer,
  HeaderRoot,
  HeaderSubTitle,
  HeaderTitle,
  SeeMoreLink,
  StyledAvatar,
  StyledCard,
} from './styles'

interface FacebookCardProps {
  profileImageUrl?: string | null
  storeName?: string
  firstAd: CampaignAd
}

const FacebookMobileNewsFeed: React.FC<FacebookCardProps> = ({
  profileImageUrl,
  storeName,
  firstAd,
}) => {
  const { type: adType, displayLink, mainMessage, adCards = [] } = firstAd.ad
  const fullWidth = ['image', 'video', 'event', 'instant-form'].includes(adType)
  const adCtaOptions = _.find(getCTAOptions(), { value: firstAd.ad.callToAction })
  const adCtaText = adCtaOptions && adCtaOptions.value !== 'NO_BUTTON' ? adCtaOptions.text : ''
  const getLinkFromPreset = (card: any) => card?.link
  const getImage = (card: any) => (card?.type === 'image' ? card?.image?.src : card?.video?.src)

  const videoCard = _.find(adCards, { type: 'video' })

  let defaultAdCards = adCards
  if (adType === 'image') {
    const adCard = _.find(adCards, { type: 'image' })
    if (!adCard) {
      const newId = uuidv4()
      const newCard = {
        id: newId,
        type: 'image',
        imageDescription: videoCard?.imageDescription || '',
        subMessage: videoCard?.subMessage || '',
        link: '',
      } as AdCard
      defaultAdCards = [newCard]
    } else {
      defaultAdCards = [adCard]
    }
  }

  if (adType === 'carousel') {
    const adCard = _.find(defaultAdCards, { type: 'image' })
    if (!adCard) {
      const newId = uuidv4()
      const newCard = {
        id: newId,
        type: 'image',
        imageDescription: videoCard?.imageDescription || '',
        subMessage: videoCard?.subMessage || '',
        link: '',
      } as AdCard
      const newId2 = uuidv4()
      defaultAdCards = [newCard, { ...newCard, id: newId2 }]
    }
  }

  const showFacebookCards = (c: any[]) =>
    c.map((card, index) => (
      <FacebookCard
        key={index} // eslint-disable-line react/no-array-index-key
        type={card?.type}
        adType={adType}
        image={getImage(card)}
        cardLink={displayLink || getLinkFromPreset(card)}
        buttonLabel={card?.type === 'map' ? '' : adCtaText}
        description={card?.imageDescription}
        title={card?.subMessage}
      />
    ))

  const showCarousel = (c: any[]) => (
    <CarouselContainer
      show={1.25}
      slide={1}
      swiping
      infinite={false}
      useArrowKeys
      dynamic
      rightArrow={<ChevronRightIcon />}
      leftArrow={<ChevronLeftIcon />}
    >
      {showFacebookCards(c)}
    </CarouselContainer>
  )

  return (
    <StyledCard variant='outlined'>
      <HeaderRoot
        avatar={
          <StyledAvatar src={profileImageUrl || ''}>
            <StoreIcon />
          </StyledAvatar>
        }
        title={<HeaderTitle>{storeName}</HeaderTitle>}
        subheader={<HeaderSubTitle>Sponsored</HeaderSubTitle>}
      />
      {mainMessage && (
        <AdDescription>
          {_.truncate(mainMessage, { length: 93 })}
          <SeeMoreLink>See more</SeeMoreLink>
        </AdDescription>
      )}
      <CardContentRoot fullwidth={fullWidth}>
        {fullWidth ? showFacebookCards(defaultAdCards) : showCarousel(defaultAdCards)}
      </CardContentRoot>
      <CardActionsRoot>
        <FacebookButton icon='ThumbUp' content='Like' />
        <FacebookButton icon='Comment' content='Comment' />
        <FacebookButton icon='Share' content='Share' />
      </CardActionsRoot>
    </StyledCard>
  )
}

export default FacebookMobileNewsFeed
