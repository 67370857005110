import styled from '@emotion/styled';
import { MobileStepper, Button } from '@mui/material';

export const StyledContainer = styled.div`
  padding: 0;
  margin: 16px 0;
  display: flex;
  flex-direction: column;

  @media (min-width: 960px) {
    padding: 0 16px 0 0;
    margin: 0;
    height: calc(100% - 64px);
    overflow-y: auto;
    display: block;
    width: 336px;
  }
`;

export const StyledInner = styled.div`
  @media (min-width: 960px) {
    margin: 16px 0;
  }
`;

export const StyledStepper = styled(MobileStepper)`
  margin-top: 8px;
  margin-bottom: 8px;
  margin-left: auto;
  margin-right: auto;
  max-width: 320px;
`;

export const StyledButton = styled(Button)`
  font-size: 0.875rem;
  padding: 6px 16px;
  text-transform: none;
`;
