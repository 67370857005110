import styled from '@emotion/styled';
import { Box, Typography, Button } from '@mui/material';

export const ContentLeft = styled(Box)`
  flex: 1;
`;

export const Title = styled(Typography, {
  shouldForwardProp: (prop) => prop !== 'fullheight',
})<{ fullheight?: boolean }>`
  font-family: Arial;
  font-size: 13px;
  line-height: 1.2em;
  font-weight: 600;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  word-break: break-word;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  ${({ fullheight }) =>
    fullheight &&
    `
    min-height: 30px;
    margin-bottom: 6px;
  `}
`;

export const TitleFullHeight = styled(Title)`
  min-height: 30px;
  margin-bottom: 6px;
`;

export const CardLink = styled(Typography)`
  color: #71767e;
  font-family: Arial;
  font-size: 10px;
  max-width: 120px;
  white-space: break-spaces;
  word-break: break-word;
`;

export const Description = styled(Typography)`
  font-family: Arial;
  min-height: 16px;
  font-size: 11.5px;
  font-weight: 100;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  word-break: break-word;
  max-width: 120px;
`;

export const StyledButton = styled(Button)`
  font-family: Arial;
  width: 85px !important;
  display: block;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  text-transform: unset;
  letter-spacing: normal;
  font-weight: 100;
  font-size: 12px;
  line-height: 1em;
  padding: 5px 0;
  height: 30px;
  color: #0E1436;
  border: 1px solid rgba(0, 0, 0, 0.23);
`;
