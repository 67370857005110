import { chowlyApi } from 'config'
import { generatePath } from 'react-router'

import client from '../chowly'
import {
  MenuMappingItemResource,
  MenuMappingSectionResource,
  MenuMappingSubsectionInput,
  MenuMappingSubsectionItemResource,
  MenuMappingSubsectionPayloadAttributes,
  MenuMappingSubsectionResponse,
  TypeName,
} from '../models'

type QueryParams = { include: 'items' }

export const getMenuMappingSubsection = async (
  menuMappingSubsectionId: string,
  params?: QueryParams,
) => {
  const url = generatePath(chowlyApi.menuMappingSubsection, { id: menuMappingSubsectionId })
  const { data } = await client.get<MenuMappingSubsectionResponse>(url, { params })
  return data
}

export const createNewMenuMappingSubsection = async (
  payloadAttributes: MenuMappingSubsectionPayloadAttributes,
  sectionId: MenuMappingSectionResource['id'],
) => {
  const url = chowlyApi.menuMappingSubsections

  const payload = formatMenuMappingSubsectionPayload(
    payloadAttributes,
    sectionId,
    // we have to pass in an empty [] for these relationships otherwise the call wont work.
    [],
    [],
  )

  const { data } = await client.post(url, payload)

  return data
}

export const deleteMenuMappingSubsection = async (menuMappingSubsectionId: string) => {
  const url = generatePath(chowlyApi.menuMappingSubsection, { id: menuMappingSubsectionId })

  const res = await client.delete(url)

  return res
}

export const updateMenuMappingSubsection = async (
  menuMappingSubsectionId: string,
  attributes: MenuMappingSubsectionPayloadAttributes,
  sectionId?: MenuMappingSectionResource['id'],
  itemIds?: MenuMappingItemResource['id'][],
  subsectionItemIds?: MenuMappingSubsectionItemResource['id'][],
) => {
  if (!menuMappingSubsectionId) {
    throw new Error('Subsection id required.')
  }

  if (!Object.keys(attributes).length) {
    throw new Error('Attributes are missing.')
  }
  const url = generatePath(chowlyApi.menuMappingSubsection, { id: menuMappingSubsectionId })

  const params = formatMenuMappingSubsectionPayload(
    attributes,
    sectionId,
    itemIds,
    subsectionItemIds,
  )
  const { data } = await client.patch<MenuMappingSubsectionResponse>(url, params)

  return data
}

/**
 *
 * @param attributes - menu subsection attributes to create or update
 * @param sectionId - the section relationship id
 * @param itemIds - this is tricky. If we are creating a new subsection, we need to pass in an empty array for this relationship. If we are omitting this value, this means we are leaving items in the database alone. If we pass in an empty array when updating subsection attributes, it will delete the items from the subsection.
 * @param subsectionItemIds - same as itemIds
 * @returns
 */
export const formatMenuMappingSubsectionPayload = (
  attributes: MenuMappingSubsectionPayloadAttributes,
  sectionId?: MenuMappingSectionResource['id'],
  itemIds?: MenuMappingItemResource['id'][],
  subsectionItemIds?: MenuMappingSubsectionItemResource['id'][],
): MenuMappingSubsectionInput => {
  return {
    data: {
      type: TypeName.MenuMappingSubsection,
      attributes,
      relationships: {
        ...(sectionId && {
          section: {
            data: {
              id: sectionId,
              type: TypeName.MenuMappingSection,
            },
          },
        }),
        ...(itemIds && {
          items: {
            data: itemIds.map((id) => ({ id, type: TypeName.MenuMappingItem })),
          },
        }),
        ...(subsectionItemIds && {
          subsection_items: {
            data: subsectionItemIds.map((id) => ({ id, type: TypeName.MenuMappingSubsectionItem })),
          },
        }),
      },
    },
  }
}
