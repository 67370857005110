
import chevron from 'assets/icons/Chevron-Down.svg'
import closeIcon from 'assets/icons/close-icon.svg'
import outline_minus_button from 'assets/icons/outline_minus_button.svg'
import Modal from 'features/modal/Modal'
import { FormEvent, useEffect, useMemo, useRef, useState } from 'react'
import * as styled from './styles'
import { PrepTimesResource, updatePrepTimes } from 'api'
import { toast } from 'react-toastify'
import { v4 as uuidv4 } from 'uuid';
import InfoCircle from 'assets/icons/info-circle.svg'

type Tier = {
    lower_price_limit: number;
    upper_price_limit: number;
    prep_minutes: number; // prep time for each tier must increment by at least 5 minutes. And should be a min of 25
    id?: number; // tiers with out an `id` are created (and given an id) when sent to the BE
    _destroy?: boolean; // tiers with `_destroy` are deleted when sent to the BE
    showDropDown: boolean;
    subtotalError: string;
    prepTimeError: string;
    tempId?: number // newly added tiers are given a tempId for ref. This will not be sent to the BE
}
interface Tiers {
    [id: number]: Tier
}

type Props = {
    partnerId: string | undefined
    platformPrepTimes: PrepTimesResource[] | []
    showModal: boolean
    toggleModal: () => void
}


export const OrderThrottleEditor = ({ partnerId, platformPrepTimes, showModal, toggleModal }: Props) => {
    const [tiers, setTiers] = useState<Tiers>({}) // stores all tiers incl. tiers pending delete (`_destroy: true`)
    const [displayTiers, setDisplayTiers] = useState<Tier[]>([])
    const dropDownRef = useRef<number | null>(null)
    const selectRef = useRef(null);
    let userInput: number

    useMemo(() => {
        if (platformPrepTimes.length > 0) {
            // set prep times pulled from DB
            const setInitalTiers: { [key: number]: any } = {};
            platformPrepTimes.forEach((p) => {
                setInitalTiers[parseInt(p.id)] = {
                    id: p.id,
                    lower_price_limit: p.attributes.lower_price_limit,
                    upper_price_limit: p.attributes.upper_price_limit,
                    prep_minutes: p.attributes.prep_minutes,
                    kind: 'price_based',
                    _destroy: p.attributes._destroy
                }
                return
            })
            setTiers(setInitalTiers)
        } else {
            // if no prep times returned from DB, the first tier displayed will have default values
            const tempId = parseInt(uuidv4().replace(/-/g, '')) // new tiers wont have id's
            setTiers(
                {
                    [tempId]: {
                        lower_price_limit: 0,
                        upper_price_limit: 0,
                        showDropDown: false,
                        prep_minutes: 25,
                        subtotalError: '',
                        prepTimeError: '',
                        tempId
                    }
                }
            )
            return
        }

    }, [platformPrepTimes])

    const handleInput = (e: FormEvent<HTMLDivElement>, tierId: number | undefined) => {
        if (!tierId) return

        e.preventDefault()
        userInput = parseFloat(e.currentTarget.innerText) || 0
        const currentTierIndex = displayTiers.indexOf(tiers[tierId])
        const tierToUpdateId = displayTiers[currentTierIndex + 1]?.id || displayTiers[currentTierIndex + 1]?.tempId

        /** increment the total for the next tier by $1 to account for the entire range. Users must input whole numbers */
        const newTotal = userInput + 1
        setTiers((prevTiers) => ({
            ...prevTiers,
            [tierId]: {
                ...prevTiers[tierId],
                upper_price_limit: userInput,
            },
            ...((tierToUpdateId && prevTiers[tierToUpdateId]) && {
                [tierToUpdateId]: {
                    ...prevTiers[tierToUpdateId],
                    lower_price_limit: newTotal,
                },
            }),
        }))
        dropDownRef.current = tierId;

        validateForm()

        // After updating state, set the caret to the end to avoid `contentEditable` bugs
        const range = document.createRange();
        const selection = window.getSelection();
        range.selectNodeContents(e.currentTarget);
        range.collapse(false); // Collapse range to the end (false means to the end, true to the start)
        selection?.removeAllRanges();
        selection?.addRange(range);
    }

    const handleClick = (e: React.MouseEvent<HTMLElement>, tierId: number | undefined) => {
        if (!tierId) return
        e.preventDefault()

        /* Update selected value for dropdown */
        setTiers((prevTiers) => ({
            ...prevTiers,
            [tierId]: {
                ...prevTiers[tierId],
                // @ts-ignore
                prep_minutes: parseInt(e.target.innerText),
                prepTimeError: '',
                showDropDown: false
            },
        }))

        validateForm()
    }

    const openDropDown = (tierId: number | undefined) => {
        if (!tierId) return;
    
        // Use ref to track the current open dropdown
        dropDownRef.current = tierId;
    
        setTiers((prevTiers) => 
            Object.fromEntries(
                Object.entries(prevTiers).map(([id, tier]) => {
                    return [
                        id,
                        {
                            ...tier,
                            // Toggle only the selected tier, close all others
                            showDropDown: id === String(tierId) ? !tier.showDropDown : false,
                        }
                    ];
                })
            )
        );
    };
    

    const addTier = () => {
        const lastTier = Object.values(tiers)[Object.values(tiers).length - 1]
        const tempId = lastTier.id + 1 || lastTier.tempId + 1
        const startPrice =
            lastTier?.upper_price_limit > 0
                ? lastTier?.upper_price_limit + 1
                : 0
        setTiers((prevTiers) => ({
            ...prevTiers,
            [tempId]: {
                lower_price_limit: startPrice,
                upper_price_limit: 0,
                showDropDown: false,
                prep_minutes: 25,
                subtotalError: '',
                prepTimeError: '',
                tempId
            },
        }))
    }

    const removeTier = (tierId: number | undefined, tempId: number | undefined) => {
        if (tierId) {
            // if tier has an id, it exists in the DB and needs to be removed via `_destroy: true`
            setTiers((prevTiers) => ({
                ...prevTiers,
                [tierId]: {
                    ...prevTiers[tierId],
                    _destroy: true
                },
            }))
        } else {
            // if tier has no id, it can be filtered out of the payload
            setTiers((prevTiers) => {
                // Filter out the tier to remove
                const newTiersArray = Object.entries(prevTiers).filter(
                    ([key]) => key !== tempId?.toString(),
                )
                // Create a new object with updated indexes
                const newTiers: Tiers = {}
                newTiersArray.forEach(([_, value]) => {
                    const currentId = value.id || value.tempId
                    newTiers[currentId] = value
                })
                return newTiers // Return the new tiers object
            })
        }
        return
    }

    useEffect(() => {
        const displayArray: Tier[] = []
        Object.values(tiers).map((t) => {
            if (!t._destroy) {
                displayArray.push(t)
            }
        })
        setDisplayTiers(displayArray)
    }, [tiers])

    const formatTiers = () => {
        const formattedTiers = Object.keys(tiers).map((t) => {
            const currentTier = tiers[parseInt(t)]
            return {
                id: currentTier?.id,
                lower_price_limit: currentTier.lower_price_limit,
                upper_price_limit: currentTier.upper_price_limit,
                prep_minutes: currentTier.prep_minutes,
                kind: 'price_based',
                _destroy: currentTier._destroy
            }
        })
        return {
            prep_times_attributes: [
                ...formattedTiers
            ]
        }
    }

    const handleFetch = async () => {
        if (!partnerId) return
        if (validateForm() === false) return
        const formattedTiers = formatTiers()
        try {
            updatePrepTimes(parseInt(partnerId), formattedTiers)
            toggleModal()
        } catch (e) {
            toast.error(`Error updating prep times: ${e}`, { autoClose: 5000 })
        }
        return
    }

    const validateForm = () => {
        let response = true
        displayTiers.forEach((t, i) => {
            const tierId = t.id || t.tempId
            if (!tierId) return
            const currentInput = dropDownRef.current == tierId && userInput ? userInput : null
            const previousTier = displayTiers[i - 1]

            // reset
            setTiers((prevTiers) => ({
                ...prevTiers,
                [tierId]: {
                    ...prevTiers[tierId],
                    subtotalError: '',
                    prepTimeError: '',
                },
            }))

            if (previousTier?.prep_minutes &&
                (previousTier?.prep_minutes >= t.prep_minutes)) { 
                setTiers((prevTiers) => ({
                    ...prevTiers,
                    [tierId]: {
                        ...prevTiers[tierId],
                        prepTimeError: 'Please select a value more than the previous tier`s value',
                    },
                }))
                response = false
            }

            if (currentInput && currentInput === 0) {
                setTiers((prevTiers) => ({
                    ...prevTiers,
                    [tierId]: {
                        ...prevTiers[tierId],
                        subtotalError: 'This field is required',
                    },
                }))
                response = false
            }

            if (previousTier && !t.lower_price_limit) {
                setTiers((prevTiers) => ({
                    ...prevTiers,
                    [tierId]: {
                        ...prevTiers[tierId],
                        subtotalError: 'This field is required',
                    },
                }))
                response = false
            }

            /* Input must be a whole number, each tier's subtotal must be greater than the tier before it */
            if (currentInput && currentInput % 1 !== 0) {
                setTiers((prevTiers) => ({
                    ...prevTiers,
                    [tierId]: {
                        ...prevTiers[tierId],
                        subtotalError: 'Please enter a whole number (ex: 35)',
                    },
                }))
                response = false
            }

            if (previousTier?.upper_price_limit && t.lower_price_limit <= previousTier?.upper_price_limit) {
                setTiers((prevTiers) => ({
                    ...prevTiers,
                    [tierId]: {
                        ...prevTiers[tierId],
                        subtotalError: 'Please enter a value more than the previous tier`s value',
                    },
                }))
                response = false
            }

            if (currentInput && t.lower_price_limit == currentInput) {
                setTiers((prevTiers) => ({
                    ...prevTiers,
                    [tierId]: {
                        ...prevTiers[tierId],
                        subtotalError: 'Please enter a valid range',
                    },
                }))
                response = false
            }

            if (t.lower_price_limit >= t.upper_price_limit) {
                setTiers((prevTiers) => ({
                    ...prevTiers,
                    [tierId]: {
                        ...prevTiers[tierId],
                        subtotalError: 'Please enter a valid range',
                    },
                }))
                response = false
            }

            if (currentInput && currentInput <= t.lower_price_limit) {
                setTiers((prevTiers) => ({
                    ...prevTiers,
                    [tierId]: {
                        ...prevTiers[tierId],
                        subtotalError: 'Please enter a valid range',
                    },
                }))
                response = false
            }

            // if no error
            if (response) {
                setTiers((prevTiers) => ({
                    ...prevTiers,
                    [tierId]: {
                        ...prevTiers[tierId],
                        subtotalError: '',
                        prepTimeError: '',
                    },
                }))
            }
        })
        return response
    }

    const handleClickOutside = (event: MouseEvent) => {
        // @ts-ignore onclick does not exist on event
        if (!event?.target?.onclick) {
            setTiers((prevTiers) => Object.fromEntries(
                Object.entries(prevTiers).map(([tierId, tier]) => {
                    return [tierId, { ...tier, showDropDown: false }]
                })

            ));
        };
    }

    useEffect(() => {
        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, [tiers]);
    
    return (
        <div data-testid={'orderThrottleEditor'}>
            <Modal isOpen={showModal} width={'600px'}>
                <styled.EditorContainer>
                    <styled.EditorHeader>
                        <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                            <styled.Title>Order Throttling</styled.Title>
                            <styled.Icon onClick={toggleModal} src={closeIcon}></styled.Icon>
                        </div>
                        <styled.EditorDescription>
                            Order Throttling is exclusively designed for Chowly Online Ordering (COO) and enables dynamic preparation time adjustments based on order subtotals.
                        </styled.EditorDescription>
                    </styled.EditorHeader>

                    <styled.EditorContent>
                        {
                            displayTiers.map((tier, tierIndex) => (
                                <div key={`tier-${tierIndex}`}>
                                    <styled.EditorRow key={`tier-${tierIndex}`}>
                                        <styled.ContentHeader>{`Tier ${tierIndex + 1}`}</styled.ContentHeader>
                                        <div style={{ display: 'flex', flexDirection: 'column' }}>
                                            <styled.SubtotalContainer>
                                                <styled.SubtotalHeaderContainer error={Boolean(tier.subtotalError)}>
                                                    <styled.Text> Max Order Subtotal</styled.Text>
                                                    <styled.SubText>(required)</styled.SubText>
                                                </styled.SubtotalHeaderContainer>
                                                <styled.InputContainer error={Boolean(tier.subtotalError)}>
                                                    <styled.SubtotalStart>{`$${tier.lower_price_limit ?? 0
                                                        }`}</styled.SubtotalStart>
                                                    <styled.SubtotalStart>-</styled.SubtotalStart>
                                                    <styled.SubtotalEnd>$</styled.SubtotalEnd>
                                                    <styled.SubtotalEnd
                                                        data-testid={'upper_price_limit'}
                                                        contentEditable={true}
                                                        suppressContentEditableWarning={true}
                                                        style={{ outline: '0px', width: '86px'}}
                                                        role='textbox'
                                                        tabIndex={0}
                                                        onInput={(e) => handleInput(e, tier.id || tier.tempId)}
                                                    >{tier.upper_price_limit}</styled.SubtotalEnd>
                                                    <styled.InfoToolTip content="Each maximum order subtotal value includes all cents up to the next whole dollar amount (e.g., a tier ending at $35 covers up to $35.99)." placement='top'>
                                                        <styled.AlertCircle src={InfoCircle} />
                                                    </styled.InfoToolTip>
                                                </styled.InputContainer>
                                            </styled.SubtotalContainer>
                                            {<styled.ErrorMessage>{tier.subtotalError || ''}</styled.ErrorMessage>}
                                        </div>
                                        <div style={{ display: 'flex', flexDirection: 'column' }}>
                                            <styled.PrepTimeContainer>
                                                <styled.Text error={Boolean(tier.prepTimeError)}>Prep Time</styled.Text>
                                                {/* style={{ display: 'flex', flexDirection: 'column', position: 'relative' }} */}
                                                <div>
                                                    <styled.Dropdown error={Boolean(tier.prepTimeError)} id={`tier-${tier.id}`}>
                                                        {`${tier.prep_minutes > 0 ? tier.prep_minutes : tier.prep_minutes + 5} minutes`}
                                                        <styled.Icon src={chevron} onClick={() => openDropDown(tier.id || tier.tempId)} role="button"></styled.Icon>
                                                    </styled.Dropdown>
                                                    <styled.DropDownOptions ref={selectRef} active={tier.showDropDown}>
                                                        {/* increment the prep time dropdown options by 5. Tiers > than the 0th index should start at the previous index + 5 */}
                                                        {[5, 10, 15, 20, 25, 30, 40].map((addValue) => (
                                                            <styled.OptionsRow key={`option-${tierIndex + addValue}`} onClick={(e) => handleClick(e, tier.id || tier.tempId)}>
                                                                <styled.RowDetails data-value={tierIndex}>{20 + addValue}</styled.RowDetails>
                                                            </styled.OptionsRow>
                                                        ))}
                                                    </styled.DropDownOptions>
                                                </div>
                                            </styled.PrepTimeContainer>
                                            {<styled.ErrorMessage>{tier.prepTimeError || ''}</styled.ErrorMessage>}
                                        </div>
                                        <div style={{ height: '46px' }}>
                                            <styled.Icon
                                                onClick={() => removeTier(tier.id, tier.tempId)}
                                                src={outline_minus_button}
                                                alt='ellipseIcon'
                                            />
                                        </div>
                                    </styled.EditorRow>
                                    {Object.keys(displayTiers)[tierIndex + 1] && <styled.Divider></styled.Divider>}
                                </div>
                            ))}
                    </styled.EditorContent>
                    <div style={{ display: 'flex', justifyContent: 'end', width: '536px' }}>
                        <styled.AddTier onClick={addTier}>Add Tier</styled.AddTier>
                    </div>
                    <styled.Footer>
                        <styled.Cancel onClick={toggleModal}>Cancel</styled.Cancel>
                        <styled.PrimaryButton onClick={handleFetch}>Save</styled.PrimaryButton>
                    </styled.Footer>
                </styled.EditorContainer>
            </Modal>
        </div>
    )
}

export default OrderThrottleEditor
