import CheckIcon from '@mui/icons-material/Check'
import CloseIcon from '@mui/icons-material/Close'
// import MoreHorizIcon from '@mui/icons-material/MoreHoriz'
import {
  // Menu,
  Table,
  TableBody,
  TableContainer,
  TableHead,
  TablePagination,
  Tabs,
} from '@mui/material'
import { getOrderReport } from 'api/orderManagment'
import { Link, OrderReportResponse, ReportingOrder } from 'api/orderManagment/types'
import { useLocations } from 'components/MainLayout/MainLayout'
import React, { useEffect, useState } from 'react'
import { fontFamily } from 'theme'
import { LocationSummary } from 'types'
import { formatSubmissionDate, formatSubmissionTime } from 'utils'

import StyledSelect from '../digital-marketing/StyledSelect/StyledSelect'
import { LocationsContainer } from '../digital-marketing/styles'
import OrderDetailsDrawer from './OrderDetailsDrawer'
import * as Styled from './OrderManagementStyles'

export enum OrderDisplayStatus {
  ALL = '',
  COMPLETED = 'completed',
  CANCELLED = 'cancelled',
}

export const statusConfig: Record<OrderDisplayStatus, { color: string; icon: JSX.Element }> = {
  [OrderDisplayStatus.COMPLETED]: { color: 'green', icon: <CheckIcon fontSize='small' /> },
  [OrderDisplayStatus.CANCELLED]: { color: 'red', icon: <CloseIcon fontSize='small' /> },
  [OrderDisplayStatus.ALL]: { color: 'gray', icon: <></> },
}

export const mapOrderStatus = (status: string): OrderDisplayStatus => {
  switch (status) {
    case 'completed':
      return OrderDisplayStatus.COMPLETED
    case 'cancelled':
      return OrderDisplayStatus.CANCELLED
    default:
      return OrderDisplayStatus.ALL
  }
}

const TableComponent = () => {
  const { locationsData } = useLocations()
  const { locations } = locationsData
  const [partnerId, setPartnerId] = useState<string | undefined>(undefined)
  const mapLocations =
    locations?.map((loc: LocationSummary) => ({
      value: loc.id.toString(),
      label: loc.name,
    })) || []

  const [statusTab, setStatusTab] = useState<OrderDisplayStatus>(OrderDisplayStatus.ALL)
  const [rowsPerPage, setRowsPerPage] = useState(15)
  const [page, setPage] = useState(0)
  const [data, setData] = useState<ReportingOrder[]>([])
  // const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null)
  // const [selectedRow, setSelectedRow] = useState<number | null>(null)
  const [link, setLink] = useState<Link>({
    first: null,
    prev: null,
    self: null,
    next: null,
    last: null,
  })

  useEffect(() => {
    if (mapLocations.length > 0 && !partnerId) {
      setPartnerId(mapLocations[0]?.value)
    }
  }, [mapLocations])
  const [isDrawerOpen, setIsDrawerOpen] = useState(false)
  const [selectedOrder, setSelectedOrder] = useState<ReportingOrder | null>(null)

  const fetchOrderReport = async (link: string | null = null) => {
    if (!partnerId) {
      return
    }

    try {
      const response: OrderReportResponse = await getOrderReport({
        partnerId,
        link,
        limit: rowsPerPage,
        filter: statusTab,
      })
      const { data: orders, links } = response

      setLink(links)
      setData(orders)
    } catch (error) {
      console.error('Error fetching order report:', error)
    }
  }

  useEffect(() => {
    fetchOrderReport()
  }, [statusTab, rowsPerPage, partnerId])

  const handleTabChange = (event: React.SyntheticEvent, newValue: OrderDisplayStatus) => {
    setStatusTab(newValue)
    setPage(0)
  }

  // const handleMenuOpen = (event: React.MouseEvent<HTMLButtonElement>, rowIndex: number) => {
  //   setAnchorEl(event.currentTarget)
  //   setSelectedRow(rowIndex)
  // }

  // const handleMenuClose = () => {
  //   setAnchorEl(null)
  //   setSelectedRow(null)
  // }

  const handleRowClick = (order: ReportingOrder) => {
    setSelectedOrder(order)
    setIsDrawerOpen(true)
  }

  const handleCloseDrawer = () => {
    setIsDrawerOpen(false)
    setSelectedOrder(null)
  }

  const handleChangePage = (event: any, newPage: number) => {
    if (newPage > page && link.next) {
      fetchOrderReport(link.next)
    } else if (newPage < page && link.prev) {
      fetchOrderReport(link.prev)
    } else {
      fetchOrderReport(link.self)
    }
    setPage(newPage)
  }

  const handleChangeRowsPerPage = (event: any) => {
    setRowsPerPage(parseInt(event.target.value, 10))
    setPage(0)
  }

  return (
    <Styled.Container>
      <LocationsContainer>
        <StyledSelect
          options={mapLocations}
          placeholder={mapLocations[0]?.label || 'Loading...'}
          onChange={setPartnerId}
          value={mapLocations.find((option) => option.value === partnerId)}
        />
      </LocationsContainer>
      <Tabs
        value={statusTab}
        onChange={handleTabChange}
        TabIndicatorProps={{ style: { display: 'none' } }}
        textColor='primary'
      >
        <Styled.StyledTab
          label='All'
          value={OrderDisplayStatus.ALL}
          active={statusTab === OrderDisplayStatus.ALL}
        />
        <Styled.StyledTab
          label='Completed'
          value={OrderDisplayStatus.COMPLETED}
          active={statusTab === OrderDisplayStatus.COMPLETED}
        />
        <Styled.StyledTab
          label='Cancelled'
          value={OrderDisplayStatus.CANCELLED}
          active={statusTab === OrderDisplayStatus.CANCELLED}
        />
      </Tabs>

      <TableContainer>
        <Table>
          <TableHead>
            <Styled.StyledTableRow>
              <Styled.MobileVisibleTableHeader>Platform Ticket ID</Styled.MobileVisibleTableHeader>
              <Styled.CustomTableCell>Platform</Styled.CustomTableCell>
              <Styled.MobileVisibleTableHeader>Submission Time</Styled.MobileVisibleTableHeader>
              <Styled.CustomTableCell>POS Ticket ID </Styled.CustomTableCell>
              <Styled.MobileVisibleTableHeader>Customer Name</Styled.MobileVisibleTableHeader>
              <Styled.CustomTableCell>Delivery Address</Styled.CustomTableCell>
              {/* <Styled.CustomTableCell>Actions</Styled.CustomTableCell> */}
            </Styled.StyledTableRow>
          </TableHead>
          <TableBody>
            {data.length > 0 ? (
              data.map((row) => {
                const {
                  order_platform_order_id,
                  order_status,
                  platform_name,
                  submission_time,
                  pos_order_id,
                  customer_first_name,
                  customer_last_name,
                  delivery_street,
                } = row.attributes

                const [streetAddress, ...remainingAddress] = delivery_street.split(',')

                return (
                  <Styled.StyledTableRow key={row.id} onClick={() => handleRowClick(row)}>
                    <Styled.MobileVisibleTableCell>
                      {order_platform_order_id} &nbsp;
                      {statusConfig[mapOrderStatus(order_status)] && (
                        <Styled.StyledChip
                          label={mapOrderStatus(order_status)}
                          icon={
                            statusConfig[mapOrderStatus(order_status) as OrderDisplayStatus].icon
                          }
                          status={mapOrderStatus(order_status)}
                          size='small'
                        />
                      )}
                    </Styled.MobileVisibleTableCell>

                    <Styled.CustomTableCell>{platform_name}</Styled.CustomTableCell>

                    <Styled.MobileVisibleTableCell>
                      <Styled.SubmissionTime>
                        {formatSubmissionTime(submission_time)}
                      </Styled.SubmissionTime>
                      <Styled.SubmissionDate variant='body2'>
                        {formatSubmissionDate(submission_time)}
                      </Styled.SubmissionDate>
                    </Styled.MobileVisibleTableCell>

                    <Styled.CustomTableCell>{pos_order_id}</Styled.CustomTableCell>

                    <Styled.MobileVisibleTableCell>
                      {`${customer_first_name} ${customer_last_name}`}
                    </Styled.MobileVisibleTableCell>

                    <Styled.CustomTableCell>
                      <Styled.Address>{streetAddress}</Styled.Address>
                      <Styled.Address>{remainingAddress}</Styled.Address>
                    </Styled.CustomTableCell>

                    {/* <Styled.CenterTableCell>
                      <Styled.CenteredIconButton
                        onClick={(e) => handleMenuOpen(e, index)}
                        data-testid='more-button'
                      >
                        <MoreHorizIcon sx={{ fontSize: 13 }} />
                      </Styled.CenteredIconButton>
                      <Menu
                        anchorEl={anchorEl}
                        open={selectedRow === index}
                        onClose={handleMenuClose}
                      >
                        <Styled.MenuItems onClick={handleMenuClose}>Edit</Styled.MenuItems>
                        <Styled.MenuItems onClick={handleMenuClose}>Delete</Styled.MenuItems>
                      </Menu>
                    </Styled.CenterTableCell> */}
                  </Styled.StyledTableRow>
                )
              })
            ) : (
              <Styled.StyledTableRow>
                <Styled.CustomTableCell colSpan={7} align='center'>
                  No data available
                </Styled.CustomTableCell>
              </Styled.StyledTableRow>
            )}
          </TableBody>
        </Table>
      </TableContainer>
      <OrderDetailsDrawer isOpen={isDrawerOpen} onClose={handleCloseDrawer} order={selectedOrder} />
      <Styled.PaginationContainer>
        <TablePagination
          component='div'
          count={page * rowsPerPage + data.length}
          page={page}
          onPageChange={(event, newPage) => {
            handleChangePage(event, newPage)
          }}
          slotProps={{
            actions: {
              previousButton: {
                disabled: !link?.prev,
              },
              nextButton: {
                disabled: !link?.next,
              },
            },
          }}
          rowsPerPage={rowsPerPage}
          onRowsPerPageChange={handleChangeRowsPerPage}
          labelRowsPerPage='Rows per page'
          rowsPerPageOptions={[5, 10, 15, 20, 25]}
          sx={{
            '& .MuiTablePagination-selectLabel, & .MuiTablePagination-caption, & .MuiTablePagination-input, & .MuiTablePagination-select':
              {
                fontFamily: fontFamily.inter,
                fontSize: 13,
              },
          }}
        />
      </Styled.PaginationContainer>
    </Styled.Container>
  )
}

export default TableComponent
