import React from 'react'
import { STATIC_BASE_URL } from 'utils'

import { Icon, RootBox, Text } from './styles'

interface IconStyles {
  backgroundSize: string
  backgroundPosition: string
  file: string
}

interface FacebookButtonProps {
  icon: keyof typeof iconStyles
  content: string
  dataCy?: string | undefined
}

const iconStyles: Record<string, IconStyles> = {
  Comment: {
    backgroundSize: '55px 344px',
    backgroundPosition: '0 -156px',
    file: 'facebook_map_2.png',
  },
  Share: {
    backgroundSize: '55px 344px',
    backgroundPosition: '-19px -175px',
    file: 'facebook_map_2.png',
  },
  ThumbUp: {
    backgroundSize: '73px 477px',
    backgroundPosition: '-38px -422px',
    file: 'facebook_map.png',
  },
}

const FacebookButton: React.FC<FacebookButtonProps> = ({ icon, content, dataCy }) => {
  const styles = iconStyles[icon] || {}

  return (
    <RootBox {...(dataCy && { 'data-testid': dataCy })}>
      {icon && (
        <Icon
          backgroundimage={`${STATIC_BASE_URL}/images/${styles.file}`}
          backgroundsize={styles.backgroundSize}
          backgroundposition={styles.backgroundPosition}
        />
      )}
      <Text>{content}</Text>
    </RootBox>
  )
}

export default FacebookButton
