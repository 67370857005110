import {
  CustomizationOptionsByCustomizationId,
  ItemModifier,
  ItemModifierGroup,
  MenuMappingCustomization,
  MenuMappingCustomizationCustomizationOption,
  MenuMappingCustomizationOption,
  MenuMappingCustomizationOptionAttributes,
  MenuMappingCustomizationOptionResource,
  MenuMappingCustomizationResource,
  MenuMappingItemCustomization,
  MenuMappingItemResource,
  MenuMappingMenuResource,
  MenuMappingNestedCustomization,
  SectionModifier,
  TypeName,
} from 'api'
import { chowlyApi } from 'config'
import { generatePath } from 'react-router'
import { toast } from 'react-toastify'

import client from '../chowly'

// Separate endpoint for customization options: api/v3/menu_mapping/customization_options?menu_mapping_menu_id={ID} GET
// Customization Options = Modifers; Customizations = Modifier Groups
// The nesting for groups and modifiers can repeat 2 levels down, represented by nestedCustomizations, which have the customization option as the parent.
export const getMenuMappingCustomizationOptions = async ({
  menuMappingMenuId,
  menuMappingSectionId,
}: {
  menuMappingMenuId: string
  menuMappingSectionId: string
}) => {
  const url = chowlyApi.menuMappingCustomizationOptions

  // Initialize empty objects for formattedData and formattedGroup
  const formattedData: CustomizationOptionsByCustomizationId = {}
  const formattedGroups: { [key: string]: ItemModifierGroup } = {}

  try {
    const { data } = await client.get(
      `${url}?menu_mapping_menu_id=${menuMappingMenuId}&menu_mapping_section_id=${menuMappingSectionId}&include=customizations,nested_customizations.customization_options,nested_customizations.customizations,customization_customization_options.customization_option_price`,
    )

    const customizationOptionsData: MenuMappingCustomizationOptionResource[] = data.data || []
    const includedData: any[] = data.included || []
    const combinedData = [...customizationOptionsData, ...includedData]

    // Map the data just like formatted data below, plus easy access later
    const modifierGroups: Record<string, MenuMappingCustomizationResource> = {}
    const customizationCustomizationOptions: Record<
      string,
      MenuMappingCustomizationCustomizationOption
    > = {}
    const nestedCustomizations: Record<string, MenuMappingNestedCustomization> = {}

    // Sort the included data out once into their own objects key/value pairs for O(1) access later
    includedData.forEach((dataField: any) => {
      if (dataField.type === TypeName.MenuMappingCustomization) {
        modifierGroups[dataField.id] = dataField
      }

      if (dataField.type === TypeName.MenuMappingCustomizationCustomizationOption) {
        customizationCustomizationOptions[dataField.id] = dataField
      }

      if (dataField.type === TypeName.MenuMappingNestedCustomization) {
        nestedCustomizations[dataField.id] = dataField
      }
    })

    const formatNestedOptionsData = (nestedModifiers?: MenuMappingCustomizationOption[]) => {
      const formattedOptionsData: SectionModifier[] = []

      if (!nestedModifiers?.length) {
        return []
      }

      nestedModifiers.forEach((modifier) => {
        const modifierObj = combinedData.find((entry) => entry.id === modifier.id)

        if (!modifierObj) {
          return null
        }

        const customizationCustomizationOptionId =
          modifierObj.relationships.customization_customization_options?.data?.[0]?.id

        let optionPrice

        // have to get the price through this join table
        if (customizationCustomizationOptionId) {
          optionPrice =
            customizationCustomizationOptions[customizationCustomizationOptionId]?.attributes
              .customization_option_price
        }

        if (modifierObj) {
          formattedOptionsData.push({
            id: modifierObj.id,
            type: modifierObj.type,
            attributes: {
              ...modifierObj.attributes,
              price: optionPrice,
            },
          })
        }
      })

      return formattedOptionsData
    }

    const formatNestedGroupsData = (
      nestedGroups?: MenuMappingNestedCustomization[],
    ): ItemModifierGroup[] => {
      const nestedModifierGroups: ItemModifierGroup[] = []

      nestedGroups?.forEach((group) => {
        const nestedGroupFullObj: MenuMappingNestedCustomization | undefined =
          nestedCustomizations[group.id]

        if (!nestedGroupFullObj) {
          return null
        }
        // need to map to actual customization
        const customizationObj: MenuMappingCustomizationResource | undefined =
          modifierGroups[nestedGroupFullObj.relationships.customization?.data?.id]

        if (!customizationObj) {
          return null
        }
        const groupCustomizationOptions: MenuMappingCustomizationOption[] | undefined =
          customizationObj?.relationships.customization_options?.data

        nestedModifierGroups.push({
          id: customizationObj.id,
          type: customizationObj.type,
          attributes: {
            sort_position: nestedGroupFullObj.attributes.sort_position, // this attribute is from the nested_customizations table
            title: customizationObj?.attributes.title,
            max_permitted: customizationObj?.attributes.max_permitted,
            min_permitted: customizationObj?.attributes.min_permitted,
          },
          children: {
            customization_options: formatNestedOptionsData(groupCustomizationOptions),
          },
        })
      })

      return nestedModifierGroups
    }

    customizationOptionsData.forEach(
      (customizationOption: MenuMappingCustomizationOptionResource) => {
        const customizations = customizationOption.relationships?.customizations?.data
        const nestedCustomizations = customizationOption.relationships?.nested_customizations?.data
        const ccOptions =
          customizationOption.relationships?.customization_customization_options?.data
        const fullCcOptionObjs: MenuMappingCustomizationCustomizationOption[] = []

        // the price of the customization option (modifier) lives on the customizationCustomizationOption join table.

        // filter out full ccOptions belonging to this customizationOption
        ccOptions?.forEach((ccOption) => {
          // 0(1) lookup on main ccOption object
          if (customizationCustomizationOptions[ccOption.id]) {
            fullCcOptionObjs.push(customizationCustomizationOptions[ccOption.id])
          }
        })

        customizations?.forEach((customization) => {
          const customizationId = customization.id

          // find THE customizationCustomizationOption on this customization and customizationOption
          const matchingCcOption = fullCcOptionObjs.find(
            (ccOption) => ccOption.relationships.customization.data.id === customization.id,
          )
          const customizationOptionPrice = matchingCcOption?.attributes.customization_option_price

          if (customizationId) {
            const modifierData: ItemModifier = {
              id: customizationOption.id,
              type: customizationOption.type,
              attributes: {
                external_id: customizationOption.attributes.external_id,
                suspend_until: customizationOption.attributes.suspend_until,
                title: customizationOption.attributes.title,
                price: customizationOptionPrice,
              },
              children: {
                customizations: formatNestedGroupsData(nestedCustomizations),
              },
            }

            if (formattedData[customizationId]) {
              formattedData[customizationId]?.push(modifierData)
            } else {
              formattedData[customizationId] = [modifierData]
            }

            // Find the modifier group for the customizationId
            const modifierGroup = modifierGroups[customizationId]
            // If it exists, create or push to formattedGroup array
            if (modifierGroup) {
              // Create new modifier item with additional information
              formattedGroups[customizationId] = {
                ...modifierGroup,
                children: {
                  customization_options: formattedData[customizationId],
                },
              }
            }
          }
        })
      },
    )

    return { formattedData, formattedGroups }
  } catch (err) {
    console.log('Error fetching customization options', err)
    toast.error('Error fetching modifiers. Please try again later.')
    throw err // Re-throw the error to indicate failure
  }
}

export const updateMenuMappingCustomizationOption = async ({
  id,
  attributes,
  customizations,
  customizationCustomizationOptions,
  customizationOptions,
  defaultCustomizationOptions,
  itemCustomizations,
  items,
  nestedCustomizations,
}: {
  id: MenuMappingCustomizationOption['id']
  attributes: MenuMappingCustomizationOptionAttributes
  customizations?: MenuMappingCustomization['id'][]
  customizationCustomizationOptions?: MenuMappingCustomizationCustomizationOption['id'][]
  customizationOptions?: MenuMappingCustomizationOption['id'][]
  defaultCustomizationOptions?: MenuMappingCustomizationOption['id'][]
  itemCustomizations?: MenuMappingItemCustomization['id'][]
  items?: MenuMappingItemResource['id'][]
  nestedCustomizations?: MenuMappingNestedCustomization['id'][]
}) => {
  const url = generatePath(chowlyApi.menuMappingCustomizationOption, { id })

  const payload = formatMenuMappingCustomizationOptionPayload({
    attributes,
    customizations,
    customizationCustomizationOptions,
    customizationOptions,
    defaultCustomizationOptions,
    itemCustomizations,
    items,
    nestedCustomizations,
  })

  const { data } = await client.patch<MenuMappingCustomizationOptionResource>(url, payload)

  return data
}

// soft deletion of the item under the specific subsection
export const removeMenuMappingCustomizationOptionRelationship = async ({
  modifierId,
  customizationId,
}: {
  modifierId: MenuMappingCustomizationOption['id']
  customizationId: MenuMappingCustomization['id']
}) => {
  if (!modifierId) {
    throw new Error('Modifier id is required.')
  }

  if (!customizationId) {
    throw Error('Customization id is required.')
  }

  const url = generatePath(chowlyApi.menuMappingCustomizationOption, { id: modifierId })
  // this is handled as a special case with query params, which allows us to remove the relationship rather than hard deleting the option in the api
  const urlWithQueryParams = `${url}?customization_id=${customizationId}`

  const response = await client.delete(urlWithQueryParams)

  return response
}

export const createMenuMappingCustomizationOption = async ({
  attributes,
  customizations,
  menuId,
}: {
  attributes: MenuMappingCustomizationOptionAttributes
  customizations: MenuMappingCustomization['id'][]
  menuId: MenuMappingMenuResource['id']
}) => {
  const url = chowlyApi.menuMappingCustomizationOptions
  const payload = formatMenuMappingCustomizationOptionPayload({
    attributes,
    customizations,
    menuId,
  })

  const { data } = await client.post<MenuMappingCustomizationOptionResource>(url, payload)
  return data
}

const formatMenuMappingCustomizationOptionPayload = ({
  attributes,
  menuId,
  customizations,
  customizationCustomizationOptions,
  customizationOptions,
  defaultCustomizationOptions,
  itemCustomizations,
  items,
  nestedCustomizations,
}: {
  attributes: MenuMappingCustomizationOptionAttributes
  menuId?: MenuMappingMenuResource['id']
  customizations?: MenuMappingCustomization['id'][]
  customizationCustomizationOptions?: MenuMappingCustomizationCustomizationOption['id'][]
  customizationOptions?: MenuMappingCustomizationOption['id'][]
  defaultCustomizationOptions?: MenuMappingCustomizationOption['id'][]
  itemCustomizations?: MenuMappingItemCustomization['id'][]
  items?: MenuMappingItemResource['id'][]
  nestedCustomizations?: MenuMappingNestedCustomization['id'][]
}) => {
  return {
    data: {
      type: TypeName.MenuMappingCustomizationOption,
      attributes,
      relationships: {
        ...(menuId && {
          menu_id: {
            data: { id: menuId, type: TypeName.MenuMappingMenu },
          },
        }),
        ...(customizations && {
          customizations: {
            data: customizations.map((id) => ({
              id,
              type: TypeName.MenuMappingCustomization,
            })),
          },
        }),
        ...(customizationCustomizationOptions && {
          customization_customization_options: {
            data: customizationCustomizationOptions.map((id) => ({
              id,
              type: TypeName.MenuMappingCustomizationCustomizationOption,
            })),
          },
        }),
        ...(customizationOptions && {
          customization_options: {
            data: customizationOptions.map((id) => ({
              id,
              type: TypeName.MenuMappingCustomizationOption,
            })),
          },
        }),
        ...(defaultCustomizationOptions && {
          default_customization_options: {
            data: defaultCustomizationOptions.map((id) => ({
              id,
              type: TypeName.MenuMappingDefaultCustomizationOption,
            })),
          },
        }),
        ...(itemCustomizations && {
          item_customizations: {
            data: itemCustomizations.map((id) => ({
              id,
              type: TypeName.MenuMappingItemCustomization,
            })),
          },
        }),
        ...(items && {
          items: {
            data: items.map((id) => ({ id, type: TypeName.MenuMappingItem })),
          },
        }),
        ...(nestedCustomizations && {
          nested_customizations: {
            data: nestedCustomizations.map((id) => ({
              id,
              type: TypeName.MenuMappingNestedCustomization,
            })),
          },
        }),
      },
    },
  }
}
