import styled from '@emotion/styled';
import { Box } from '@mui/material';
import ImageIcon from '@mui/icons-material/Image';

export const RootBox = styled(Box)`
  height: 100%;
  background: no-repeat center center;
  background-size: cover;
`;

export const IconContainer = styled(Box)`
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #d8d9df;
`;

export const CardImageIcon = styled(ImageIcon)`
  fill: white;
  font-size: 4rem;
`;