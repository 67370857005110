export const formatItemDescription = (text?: string) => {
  if (!text?.length) {
    return ''
  }
  if (text.length > 100) {
    return `${text.slice(0, 100)}...`
  }
  return text
}

export const formatItemPrice = (price?: number) => {
  return price?.toLocaleString('en-US', { style: 'currency', currency: 'USD' })
}

export const convertItemPriceToNumber = (price?: string) => {
  // Regular expression to match a USD price
  const regex = /\$([0-9,]+(?:\.[0-9]{1,2})?)/

  // Extract the price portion from the string
  const match = price?.match(regex)

  if (match) {
    // Remove commas and convert to a float
    const price = parseFloat(match[1].replace(/,/g, ''))

    return price
  }
}

/**
 * @param updatedItemsLength - num of newly suspended and unsuspended items
 * @param updatedModifiersLength - num of newly suspended and unsuspended modifiers
 * @returns text to display in header rows for num of items suspended
 */
export const getRowText = (updatedItemsLength: number, updatedModifiersLength: number) => {
  let numUpdatedStr = ''

  if (updatedItemsLength > 0) {
    numUpdatedStr = numUpdatedStr.concat(`${updatedItemsLength} Product(s)`)
  }

  if (updatedModifiersLength > 0) {
    if (updatedItemsLength > 0) {
      numUpdatedStr = numUpdatedStr.concat(' and ')
    }
    numUpdatedStr = numUpdatedStr.concat(`${updatedModifiersLength} Modifier(s)`)
  }

  if (numUpdatedStr.length) {
    return `${numUpdatedStr} Updated`
  }
  return 'No Changes'
}
