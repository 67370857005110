import Button from '@mui/material/Button';
import styled from '@emotion/styled';


const DMBaseButton = styled(Button)`
  font-size: 13px;
  font-style: normal;
  font-weight: 600;
  line-height: 140%;
  padding: 6px 8px;
  text-transform: none;
`

export default DMBaseButton