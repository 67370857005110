import KeyboardArrowLeft from '@mui/icons-material/KeyboardArrowLeft'
import KeyboardArrowRight from '@mui/icons-material/KeyboardArrowRight'
import Disclaimer from 'components/AdPreviews/Disclaimer/Disclaimer'
import FacebookMobileNewsFeed from 'components/AdPreviews/FacebookMobileNewsFeed/FacebookMobileNewsFeed'
import InstagramMobileStory from 'components/AdPreviews/InstagramMobileStory/InstagramMobileStory'
import CustomSwipeableViews from 'components/CustomSwipeableViews/CustomSwipeableViews'
import React, { Fragment, useState } from 'react'
import { CampaignAdInfo, FacebookCampaignInfo } from 'types/digitalMarketing'
import { TargetableStore } from 'types/digitalMarketing/store'

import { StyledButton, StyledStepper } from './styles'

interface FacebookAdPreviewStepperProps {
  campaign: FacebookCampaignInfo
  associatedStore: TargetableStore
  stepperButtonsPos?: 'top' | 'bottom'
}

interface AdPlacement {
  id: 'facebook' | 'instagram'
  content: JSX.Element
}

const FacebookAdPreviewStepper: React.FC<FacebookAdPreviewStepperProps> = ({
  campaign,
  associatedStore,
  stepperButtonsPos = 'top',
}) => {
  const [activeStep, setActiveStep] = useState(0)

  const ad: Partial<CampaignAdInfo> = campaign?.ads?.[0]?.ad || {}
  const hasFacebookPlacement = ad?.adPlacement?.facebook || false
  const hasInstagramPlacement = ad?.adPlacement?.instagram || false

  const storeFacebookIntegration = associatedStore?.integrations?.[0] || {}
  const profileUrl = storeFacebookIntegration?.avatar || ''
  const instagramUsername = storeFacebookIntegration?.instagramUsername

  const placements = [
    (hasFacebookPlacement || (!hasFacebookPlacement && !hasInstagramPlacement)) && {
      id: 'facebook',
      content: (
        <FacebookMobileNewsFeed
          profileImageUrl={profileUrl}
          storeName={associatedStore?.name}
          firstAd={campaign?.ads?.[0] || {}}
        />
      ),
    },
    hasInstagramPlacement && {
      id: 'instagram',
      content: (
        <InstagramMobileStory
          profileImageUrl={profileUrl}
          handle={instagramUsername || associatedStore?.name || 'InstagramHandle'}
          firstAd={campaign?.ads?.[0] || {}}
        />
      ),
    },
  ].filter((placement) => placement) as AdPlacement[]

  const steps = placements.length

  const renderMobileStepper = () => (
    <>
      {steps > 1 && (
        <StyledStepper
          variant='text'
          steps={steps}
          position='static'
          activeStep={activeStep}
          nextButton={
            <StyledButton
              size='small'
              onClick={() => setActiveStep((prevActiveStep) => prevActiveStep + 1)}
              disabled={activeStep === steps - 1}
              data-testid='FacebookAdPreviewStepper_next_button'
            >
              NEXT
              <KeyboardArrowRight />
            </StyledButton>
          }
          backButton={
            <StyledButton
              size='small'
              onClick={() => setActiveStep((prevActiveStep) => prevActiveStep - 1)}
              disabled={activeStep <= 0}
              data-testid='FacebookAdPreviewStepper_back_button'
            >
              <KeyboardArrowLeft />
              BACK
            </StyledButton>
          }
        />
      )}
    </>
  )

  return (
    <>
      {stepperButtonsPos === 'top' && renderMobileStepper()}
      <CustomSwipeableViews
        index={activeStep}
        onIndexChange={(newIndex: number) => setActiveStep(newIndex)}
        hideButtons
      >
        {placements.map((placement) => (
          <Fragment key={placement.id}>
            {placement.content}
            {stepperButtonsPos === 'bottom' && renderMobileStepper()}
            {(hasFacebookPlacement || hasInstagramPlacement) && (
              <Disclaimer
                text='Ad appearance may vary across devices'
                variant='firstLine'
                dataCy='FacebookAdPreviewStepper_previewDisclaimer_label'
              />
            )}
            {!hasFacebookPlacement && !hasInstagramPlacement && (
              <Disclaimer
                text='Preview only - enable an ad placement to publish your campaign!'
                variant='firstLine'
                dataCy='FacebookAdPreviewStepper_adPlacementDisclaimer_label'
              />
            )}
          </Fragment>
        ))}
      </CustomSwipeableViews>
    </>
  )
}

export default FacebookAdPreviewStepper
