import { LoadingSkeleton } from 'components'
import React from 'react'

import * as Styled from './styles'
import { TABS } from './util/constants'

type Props = {
  selectedTab: number
  inactiveItems?: number
  activeItems?: number
  setSelectedTab: (tabIdx: number) => void
  isPreviewOnly?: boolean
  isLoading: boolean
}

const MenuTabs = ({ selectedTab = 0, setSelectedTab, isLoading }: Props) => {
  const tabs = [
    {
      mobileLabel: 'All',
      desktopLabel: TABS.ALL_PRODUCTS,
    },
    {
      mobileLabel: TABS.ACTIVE,
      desktopLabel: TABS.ACTIVE,
    },
    {
      mobileLabel: TABS.INACTIVE,
      desktopLabel: TABS.INACTIVE,
    },
  ]

  if (isLoading) {
    return <LoadingSkeleton isFlex />
  }

  return (
    <>
      <Styled.MenuTabs>
        {tabs.map((tab, i) => (
          <React.Fragment key={i}>
            <Styled.DesktopMenuTab
              isSelected={selectedTab === i}
              tabIndex={i}
              onClick={() => setSelectedTab(i)}
            >
              {tab.desktopLabel}
            </Styled.DesktopMenuTab>
            <Styled.MobileMenuTab
              isSelected={selectedTab === i}
              tabIndex={i}
              onClick={() => setSelectedTab(i)}
            >
              {tab.mobileLabel}
            </Styled.MobileMenuTab>
          </React.Fragment>
        ))}
      </Styled.MenuTabs>
      {/* {selectedTab !== 0 && (
        <Styled.ModifierContainer>
          <Styled.CheckboxModifierContainer>
            <Toggle
              id='viewByModifier'
              isToggled={viewByModifier}
              onToggle={() => setViewByModifier(!viewByModifier)}
              activeColor='#32965D'
            />
          </Styled.CheckboxModifierContainer>
          <Styled.CheckboxLabel>View by Modifier</Styled.CheckboxLabel>
        </Styled.ModifierContainer>
      )} */}
    </>
  )
}

export default MenuTabs
