import { getAllPartnerLocations } from 'api'
import { PartnerResource } from 'api/models'
import { ReactComponent as ChowlyNavLogo } from 'assets/icons/ChowlyNavImg.svg'
import clearIcon from 'assets/icons/clear-icon.svg'
import closeIcon from 'assets/icons/close-icon.svg'
import { ChowlyHelpButton, HamburgerButton, Spinner, UserMenu } from 'components'
import { Experiments } from 'config'
import { useOpenModal } from 'hooks'
import useIsMobile from 'hooks/useIsMobile'
import { useAuth0 } from 'libs/auth0-react'
import { debounce } from 'lodash'
import { ChangeEvent, Fragment, ReactNode, useCallback, useRef, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { LocationSummary } from 'types/Partner'
import { showError } from 'utils'
import formatLocations from 'utils/formatLocations'

import * as Styled from './styles'

type NavLinkItem = {
  title: string
  route?: string
  href?: string
  target?: string
  popOut?: boolean
  icon?: ReactNode
  onClick?: () => void
  subNav?: Array<NavLinkItem>
}

const NavBar = () => {
  const navigate = useNavigate()
  const { isMobile } = useIsMobile()

  const [isExpanded, setIsExpanded] = useState<Record<string, boolean>>({
    Reporting: false,
    'Design System': false,
  })

  const [filteredLocations, setFilteredLocations] = useState<LocationSummary[] | undefined>([])
  const [locationsLoading, setLocationsLoading] = useState(false)
  const [searchTerm, setSearchTerm] = useState('')
  const [isNavBarOpen, setIsNavBarOpen] = useState(false)
  const toggleNavBar = () => setIsNavBarOpen(!isNavBarOpen)
  const activateSubnav = (navTitle: string) =>
    setIsExpanded((state) => ({ ...state, [navTitle]: !isExpanded[navTitle] }))
  const searchInputRef = useRef<HTMLInputElement | null>(null)

  const { user, logout, authorizedFeatures } = useAuth0()
  const isAdminUser = user?.email?.includes('@chowlyinc.com')
  const billingIsEnabled = authorizedFeatures?.find(
    (feature) => feature[Experiments.BILLING_NAVIGATION],
  )
  const dmIsEnabled = authorizedFeatures?.find((feature) => feature[Experiments.DIGITAL_MARKETING])
  const omIsEnabled = authorizedFeatures?.find((feature) => feature[Experiments.ORDER_MANAGEMENT])

  const openModal = useOpenModal()

  const navLinks: NavLinkItem[] = [
    {
      title: 'Home',
      route: '/home',
    },
    {
      title: 'Locations',
      route: '/manage-locations',
    },
    ...(omIsEnabled
      ? [
          {
            title: 'Order Management',
            route: '/order-management',
          },
        ]
      : []),
    ...(dmIsEnabled
      ? [
          {
            title: 'Digital Marketing',
            route: '/digital-marketing',
          },
        ]
      : []),
    {
      title: 'Analytics',
      route: '/reporting',
    },
  ]

  const manageAccountLinks: NavLinkItem[] = [
    {
      title: 'Manage Account',
      onClick: () => {
        openModal('ManageAccount')
      },
    },
    ...(billingIsEnabled
      ? [
          {
            title: 'Billing',
            onClick: () => {
              navigate('/billing')
            },
          },
        ]
      : []),
    {
      title: 'Log Out',
      onClick: () => {
        logout({ logoutParams: { returnTo: `${window.location.origin}/login` } })
      },
    },
  ]

  const resetInput = () => {
    setSearchTerm('')
    setFilteredLocations([])
    if (searchInputRef.current) searchInputRef.current?.focus()
  }

  const fetchFilteredLocations = async (event: ChangeEvent<HTMLInputElement>) => {
    const val = event.target.value

    // if empty, clear out filtered locations
    if (val === '') {
      setFilteredLocations([])
    } else {
      const searchTerm = String(val)

      let data: PartnerResource[] = []

      try {
        const partnerLocationsRes = await getAllPartnerLocations(undefined, searchTerm)

        if (partnerLocationsRes) {
          data = partnerLocationsRes.data
        }
      } catch (err) {
        showError(err)
      }

      if (data) {
        const _locations = formatLocations(data)
        setFilteredLocations(_locations)
      }
    }

    setLocationsLoading(false)
  }

  const functionDebounce = useCallback(
    debounce((event: ChangeEvent<HTMLInputElement>) => {
      fetchFilteredLocations(event)
    }, 300),
    [],
  )

  const handleChange = async (event: ChangeEvent<HTMLInputElement>) => {
    setLocationsLoading(true)
    setSearchTerm(event.target.value)
    functionDebounce(event)
  }

  // eslint-disable-next-line  @typescript-eslint/no-explicit-any
  const changeSelectHandler = (selectedOption: any) => {
    // setSelectedOption(selectedOption)
    if (selectedOption?.id) {
      navigate(`/manage-locations/${selectedOption?.id}`)
      resetInput()
      toggleNavBar()
    }
  }

  return (
    <>
      {isNavBarOpen && <Styled.Backdrop onClick={toggleNavBar} />}
      <Styled.MobileContainer>
        <HamburgerButton onClick={toggleNavBar} />
        <Styled.LogoLinkMobile to='/'>
          {/* <ChowlyLogo /> */}
          <ChowlyNavLogo />
        </Styled.LogoLinkMobile>
      </Styled.MobileContainer>
      <Styled.Container isOpen={isNavBarOpen}>
        <Styled.Header>
          <Styled.CloseButton alt='cancel' src={closeIcon} onClick={toggleNavBar} />
          <Styled.LogoLink to='/'>
            {/* <ChowlyLogo /> */}
            <ChowlyNavLogo />
          </Styled.LogoLink>
          <Styled.Nav>
            {/* <section> */}
            {navLinks.map((link, linkIndex) => (
              <Fragment key={linkIndex}>
                {link.href ? (
                  <Styled.NavLink
                    onClick={link.onClick}
                    key={link.route}
                    as='a'
                    href={link.href}
                    target={link.target}
                  >
                    <Styled.NavLinkText>
                      {link.icon}
                      <span>{link.title}</span>
                    </Styled.NavLinkText>
                    {link.popOut && <Styled.PopOutIcon aria-label='popout icon' />}
                  </Styled.NavLink>
                ) : link.route === '/manage-locations' ? (
                  <Styled.NavLink
                    key={link.route}
                    to={link.route}
                    onClick={() => {
                      link.onClick
                      toggleNavBar()
                    }}
                  >
                    <Styled.NavLinkText>
                      {link.icon}
                      <span>{link.title}</span>
                    </Styled.NavLinkText>
                  </Styled.NavLink>
                ) : (
                  <Styled.NavLink
                    key={link.route}
                    to={link.route ?? ''}
                    onClick={() => {
                      if (link.subNav) {
                        activateSubnav(link.title)
                      } else {
                        toggleNavBar()
                      }
                    }}
                  >
                    <Styled.NavLinkText>
                      {link.icon}
                      <span>{link.title}</span>
                    </Styled.NavLinkText>
                    {link.subNav && (
                      <Styled.DownArrow
                        aria-label='down arrow'
                        $isExpanded={isExpanded[link.title]}
                      />
                    )}
                  </Styled.NavLink>
                )}
                {isExpanded[link.title] &&
                  link.subNav?.map((item, subLinkIndex) => (
                    <Styled.DropdownLink
                      onClick={() => {
                        item.onClick?.()
                        toggleNavBar()
                      }}
                      to={item.route ?? ''}
                      key={subLinkIndex}
                    >
                      {item.icon}
                      <span>{item.title}</span>
                    </Styled.DropdownLink>
                  ))}
              </Fragment>
            ))}
          </Styled.Nav>
        </Styled.Header>

        <Styled.ToolBar>
          <Styled.SearchWrapper>
            <Styled.Search
              type='text'
              placeholder='Location Name'
              value={searchTerm}
              onChange={handleChange}
              ref={searchInputRef}
            />
            {!!searchTerm.length && (
              <Styled.ClearButton alt='cancel' src={clearIcon} onClick={resetInput} />
            )}
            {!!filteredLocations?.length && !!searchTerm.length && (
              <Styled.SearchResults>
                {filteredLocations.map((location, index) => (
                  <Styled.Result key={index} onClick={() => changeSelectHandler(location)}>
                    {location.name}
                  </Styled.Result>
                ))}
              </Styled.SearchResults>
            )}
            {filteredLocations?.length === 0 && !!searchTerm.length && (
              <Styled.SearchResults>
                <Styled.Result>
                  {locationsLoading ? <Spinner /> : 'No results found.'}
                </Styled.Result>
              </Styled.SearchResults>
            )}
          </Styled.SearchWrapper>

          {!isAdminUser && isMobile && (
            <Styled.NavLink
              key='help_center'
              as='a'
              href='https://chowly.help/s/'
              target='_blank'
              rel='noreferrer noopener'
            >
              <Styled.NavLinkText>Help Center</Styled.NavLinkText>
            </Styled.NavLink>
          )}
          {!isAdminUser && !isMobile && <ChowlyHelpButton />}

          <UserMenu />
          <Styled.ManageAccount>
            {manageAccountLinks.map((link, linkIndex) => (
              <Styled.ManageLink key={linkIndex} to={link.route ?? ''} onClick={link.onClick}>
                <Styled.NavLinkText>
                  {link.title === 'Log Out' ? (
                    <Styled.Logout>{link.title}</Styled.Logout>
                  ) : (
                    <span>{link.title}</span>
                  )}
                </Styled.NavLinkText>
              </Styled.ManageLink>
            ))}
          </Styled.ManageAccount>
        </Styled.ToolBar>
      </Styled.Container>
    </>
  )
}

export default NavBar
