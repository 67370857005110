import { PartnerResource, PrepTimesResource, fetchPrepTimes, updatePartner } from 'api'
import Toggle from 'components/Toggle/Toggle'
import { useEffect, useState } from 'react'
import { colors } from 'theme'

import * as styled from './styles'
import { toast } from 'react-toastify'
import OrderThrottleEditor from './OrderThrottleEditor'

type Props = {
    partnerId: string | undefined
    attributes: PartnerResource['attributes'] | undefined
    isOrderThrottlingEnabled: boolean | undefined
}

const OrderThrottleToggle = ({ partnerId, attributes, isOrderThrottlingEnabled }: Props) => {
    const [throttleEnabled, setThrottleEnabled] = useState(isOrderThrottlingEnabled) // pull from BE
    const [prepTimes, setPrepTimes] = useState<PrepTimesResource[] | []>();
    const [showModal, setShowModal] = useState(false)

    const fetchPartnerPrepTimes = async (partnerId: string) => {
        try {
            return await fetchPrepTimes(partnerId)
        } catch (e) {
            toast.error(`Failed to fetch prep times: ${e}`, { autoClose: 5000 })
        }
        return
    }

    useEffect(() => {
        if (!partnerId) return
        const fetchPrepTimes = async () => {
            const response = await fetchPartnerPrepTimes(partnerId)
            if (response) setPrepTimes(response.data)
        }
        fetchPrepTimes()
    }, [partnerId, showModal])


    const toggleModal = () => {
        setShowModal(!showModal)
        // refetch after save
        if (!partnerId) return
        const fetchPrepTimes = async () => {
            const response = await fetchPartnerPrepTimes(partnerId)
            if (response) setPrepTimes(response.data)
        }
        fetchPrepTimes()
    }

    const handleToggleClick = async () => {
        if (!partnerId || !attributes) return
        const payload = { 
            ...attributes,
            price_based_prep_times: !throttleEnabled 
        }
        // disable throttle
        await updatePartner(partnerId, { attributes: payload })
        setThrottleEnabled(!throttleEnabled)
        return
    }

    return (
        <styled.OrderThrottleContainer>
            <styled.OrderThrottleHeader>
                <styled.ThrottleTableLabel>Order Throttling</styled.ThrottleTableLabel>
                {prepTimes && (
                    <Toggle
                        id={'order_throttling'}
                        isToggled={throttleEnabled}
                        onToggle={handleToggleClick}
                        activeColor={colors.blue[700]}

                    />
                )}
            </styled.OrderThrottleHeader>
            <styled.ThrottleTable>
                {throttleEnabled &&
                    <div>
                        {prepTimes?.map((data, i) => (
                            <styled.ThrottleRow key={i}>
                                <div>{`$${data.attributes?.lower_price_limit} - $${data?.attributes.upper_price_limit}`}</div>
                                <div>{`${data.attributes?.prep_minutes} minutes`}</div>
                            </styled.ThrottleRow>
                        ))}
                        <styled.EditButton onClick={toggleModal} >Edit</styled.EditButton>
                    </div>
                }
                {showModal && <OrderThrottleEditor partnerId={partnerId} showModal={showModal} platformPrepTimes={prepTimes || []} toggleModal={toggleModal} />}
            </styled.ThrottleTable>
        </styled.OrderThrottleContainer>
    )
}

export default OrderThrottleToggle
