import { Grid, SvgIcon, Typography } from "@mui/material";
import styled from '@emotion/styled';

export const StyledLabel = styled(Typography)<{ marginTop?: string }>`
  color: #6E7286;
  ${({ marginTop }) =>
    marginTop &&
    `
    margin-top: ${marginTop};
    display: block;
  `}
`;

export const AdPreviewLabel = styled(Typography)`
  color: #6E7286;
  margin-bottom: 16px;
  display: block;
`;

export const StyledRedLabel = styled(Typography)<{ marginTop?: string }>`
  color: #C13030;
  ${({ marginTop }) =>
    marginTop &&
    `
    margin-top: ${marginTop};
    display: block;
  `}
`;

export const StyledGreenLabel = styled(Typography)<{ marginTop?: string }>`
  color: #32965D;
  ${({ marginTop }) =>
    marginTop &&
    `
    margin-top: ${marginTop};
    display: block;
  `}
`;

export const StyledTypography = styled(Typography)`
  padding-bottom: 16px;
`;

export const StyledSubtitle = styled(Typography)`
  font-weight: bold;
  font-size: 13px;
`;

export const AdTypeValue = styled.div`
  color: #1B51A4;
  font-size: 13px;
`;

export const AdTypeSpan = styled.span`
  color: #1B51A4;
  font-size: 13px;
`;

export const StyledBulletTypography = styled(Typography)`
  padding-bottom: 4px;
  position: relative;
  padding-left: 24px;
  &::before {
    content: "•";
    margin-right: 8px;
    font-size: 1.2em;
    position: absolute;
    left: 8px;
    top: 8px;
    transform: translateY(-50%);
  }
`;

export const ActionsButtons = styled.div`
  display: flex;
  gap: 8px;
  margin-top: 24px;

  @media (max-width: 440px) {
    margin-left: auto  
  }
`;

export const PromotionWrapper = styled(Grid)`
  position: relative;
  top: -15px;
  max-width: 420px;
  margin: 24px auto;
`;

export const PromotionDescriptionWrapper = styled(Grid)`
  margin-top: 10px;
  padding: 10px;
  background: #f1f1f3;
`;

export const PromotionDescriptionContent = styled(Grid)`
  display: flex;
  border-radius: 4px;
  background: #ffffff;
  box-shadow: 0px 2px 4px 0px rgba(14, 20, 54, 0.04);
  padding: 8px;
`;

export const PromotionDescriptionText = styled(Typography)`
  color: #3e435e;
  font-size: 14px;
  font-weight: 600;
  font-family: Montserrat;
`;

export const PromoCode = styled.span`
  display: inline-block;
  font-weight: 400;
`;

export const FinalUrl = styled.span`
  display: block;
  font-size: 12px;
  color: #007bff;
  font-weight: 500;
`;

export const SellIcon = styled(SvgIcon)`
  font-size: 14px;
  color: #6e7286;
  margin-right: 7px;
  position: relative;
  top: 3px;
`;
