import React, { useState, useEffect } from 'react';
import { Box, Button } from '@mui/material';

interface CustomSwipeableViewsProps {
  children: React.ReactNode[];
  index?: number; // Optional index controlled by the parent
  onIndexChange?: (newIndex: number) => void; // Callback for the parent to handle index changes
  hideButtons?: boolean; // Option to hide the default navigation buttons
}

const CustomSwipeableViews: React.FC<CustomSwipeableViewsProps> = ({
  children,
  index: parentIndex,
  onIndexChange,
  hideButtons = false,
}) => {
  // Manage index internally if the parent does not provide it
  const [internalIndex, setInternalIndex] = useState(0);
  const currentIndex = parentIndex !== undefined ? parentIndex : internalIndex;

  const handleNext = () => {
    if (currentIndex < children.length - 1) {
      const newIndex = currentIndex + 1;
      if (onIndexChange) {
        onIndexChange(newIndex);
      } else {
        setInternalIndex(newIndex);
      }
    }
  };

  const handleBack = () => {
    if (currentIndex > 0) {
      const newIndex = currentIndex - 1;
      if (onIndexChange) {
        onIndexChange(newIndex);
      } else {
        setInternalIndex(newIndex);
      }
    }
  };

  // Handle external changes to `parentIndex`
  useEffect(() => {
    if (parentIndex !== undefined) {
      setInternalIndex(parentIndex);
    }
  }, [parentIndex]);

  return (
    <div>
      <div style={{ display: 'flex', overflow: 'hidden' }}>
        {children.map((child, idx) => (
          <Box
            key={idx}
            sx={{
              flexShrink: 0,
              width: '100%',
              transition: 'transform 0.5s ease',
              transform: `translateX(-${currentIndex * 100}%)`,
            }}
          >
            {child}
          </Box>
        ))}
      </div>
      
      {/* Only show buttons if hideButtons is false */}
      {!hideButtons && (
        <div>
          <Button onClick={handleBack} disabled={currentIndex === 0}>
            Back
          </Button>
          <Button onClick={handleNext} disabled={currentIndex === children.length - 1}>
            Next
          </Button>
        </div>
      )}
    </div>
  );
};

export default CustomSwipeableViews;
