import { chowlyApi } from 'config'
import { generatePath } from 'react-router'

import client from '../chowly'
import {
  MenuMappingCustomization,
  MenuMappingCustomizationAttributes,
  MenuMappingCustomizationOption,
  MenuMappingCustomizationResource,
  MenuMappingCustomizationResponse,
  MenuMappingDefaultCustomizationOption,
  MenuMappingItemResource,
  MenuMappingMenuResource,
  MenuMappingNestedCustomization,
  TypeName,
} from '../models'

type QueryParams = { include: 'customization_options' }

export const getMenuMappingCustomization = async (id: string, params?: QueryParams) => {
  const url = generatePath(chowlyApi.menuMappingCustomization, { id })
  const { data } = await client.get<MenuMappingCustomizationResponse>(url, { params })
  return data
}

export const createMenuMappingCustomization = async ({
  attributes,
  items,
  menuId,
  nestedCustomizations,
}: {
  attributes: MenuMappingCustomizationAttributes
  items?: MenuMappingItemResource['id'][]
  menuId: MenuMappingMenuResource['id']
  nestedCustomizations?: MenuMappingCustomization['id'][]
}) => {
  const url = chowlyApi.menuMappingCustomizations

  const payload = formatMenuMappingCustomizationPayload({
    attributes,
    items,
    menuId,
    nestedCustomizations,
  })

  const { data } = await client.post<MenuMappingCustomizationResource>(url, payload)

  return data
}

export const updateMenuMappingCustomization = async ({
  id,
  attributes,
}: {
  id: MenuMappingCustomization['id']
  attributes: MenuMappingCustomizationAttributes
}) => {
  const url = generatePath(chowlyApi.menuMappingCustomization, { id })

  const payload = formatMenuMappingCustomizationPayload({ attributes })

  const { data } = await client.patch<MenuMappingCustomizationResource>(url, payload)

  return data
}

export const deleteMenuMappingCustomization = async ({
  id,
}: {
  id: MenuMappingCustomization['id']
}) => {
  const url = generatePath(chowlyApi.menuMappingCustomization, { id })

  const response = await client.delete(url)

  return response
}

export const formatMenuMappingCustomizationPayload = ({
  attributes,
  menuId,
  customizationOptions,
  defaultCustomizationOptions,
  itemCustomizations,
  items,
  nestedCustomizations,
}: {
  attributes: MenuMappingCustomizationAttributes
  menuId?: MenuMappingMenuResource['id']
  customizationOptions?: MenuMappingCustomizationOption['id'][]
  defaultCustomizationOptions?: MenuMappingDefaultCustomizationOption['id'][]
  itemCustomizations?: MenuMappingCustomization['id'][]
  items?: MenuMappingItemResource['id'][]
  nestedCustomizations?: MenuMappingNestedCustomization['id'][]
}) => {
  return {
    data: {
      type: TypeName.MenuMappingCustomization,
      attributes,
      relationships: {
        ...(menuId && {
          menu_id: {
            data: { id: menuId, type: TypeName.MenuMappingMenu },
          },
        }),
        ...(customizationOptions && {
          customization_options: {
            data: customizationOptions.map((id) => ({
              id,
              type: TypeName.MenuMappingCustomizationOption,
            })),
          },
        }),
        ...(defaultCustomizationOptions && {
          default_customization_options: {
            data: defaultCustomizationOptions.map((id) => ({
              id,
              type: TypeName.MenuMappingDefaultCustomizationOption,
            })),
          },
        }),
        ...(itemCustomizations && {
          item_customizations: {
            data: itemCustomizations.map((id) => ({
              id,
              type: TypeName.MenuMappingItemCustomization,
            })),
          },
        }),
        ...(items && {
          items: {
            data: items.map((id) => ({ id, type: TypeName.MenuMappingItem })),
          },
        }),
        ...(nestedCustomizations && {
          nested_customizations: {
            data: nestedCustomizations.map((id) => ({
              id,
              type: TypeName.MenuMappingNestedCustomization,
            })),
          },
        }),
      },
    },
  }
}
