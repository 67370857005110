import {
  CustomizationGroupByCustomizationId,
  CustomizationOptionsByCustomizationId,
  ExtendedMenuMappingItemDetails,
  FormattedMenuSectionInfo,
  ItemModifier,
  MenuMappingItemDetails,
  MenuMappingMenuResource,
  MenuMappingSectionFormatted,
  getMenuMappingCustomizationOptions,
  requestMenuPublish,
} from 'api'
import EXPAND_ICON from 'assets/icons/expand-icon.svg'
import { AxiosError, isAxiosError } from 'axios'
import { Button, NotificationBanner } from 'components'
import {
  useGetLocationPartners,
  useGetMenuMappingSections,
  useIsMobile,
  useRouteMenuSectionId,
} from 'hooks'
import { useAuth0 } from 'libs/auth0-react'
import moment from 'moment'
import { useEffect, useState } from 'react'
import { toast } from 'react-toastify'
import { useRecoilRefresher_UNSTABLE, useRecoilValueLoadable } from 'recoil'
import { menuMappingSectionQuery } from 'state/atoms'

import MenuAvailabilityModal from './MenuAvailability/MenuAvailabilityModal'
import MenuHeader from './MenuHeader'
import MenuSearch from './MenuSearch'
import MenuSectionEditor from './MenuSectionEditor'
import MenuSectionPreview from './MenuSectionPreview'
import MenuTabs from './MenuTabs'
import * as Styled from './styles'
import { formatMenuSection } from './util/formatMenuSection'

export const itemIsActive = (item: MenuMappingItemDetails | ItemModifier['attributes']) => {
  // suspend_until field can be missing, null, or a date that has already passed
  if (!item.suspend_until || (item.suspend_until && moment(item.suspend_until) < moment())) {
    return true
  }
  return false
}

/**
 * @param item
 * @param modifiers
 * @returns flatted array of modifiers for all the customization options of an item. (item.itemCustomizations[id] = key in itemModifiers)
 */
export const mapItemToModifiers = (
  item: ExtendedMenuMappingItemDetails,
  modifiers: CustomizationOptionsByCustomizationId,
): ItemModifier[] | [] => {
  let flattenedModifiers: ItemModifier[] | [] = []

  item.itemCustomizations?.map(({ id }) => {
    const mods = modifiers[id]

    if (mods?.length) {
      flattenedModifiers = [...flattenedModifiers, ...mods]
    }
  })

  return flattenedModifiers
}

type Props = {
  isEditor: boolean
}

const IndividualMenu = ({ isEditor = false }: Props) => {
  const { menuSectionId } = useRouteMenuSectionId()
  const { isLoading, menuMappingMenuId, partner } = useGetLocationPartners()

  const { menuMappingSections: menuSections, isLoading: isMenuSectionsLoading } =
    useGetMenuMappingSections(menuMappingMenuId)

  const { isMobile } = useIsMobile()
  const { user } = useAuth0()

  // toggles menu between Editor and Viewer modes
  const [isMenuEditor, setIsMenuEditor] = useState<boolean>(false)

  const [isMenuSectionLoading, setIsMenuSectionLoading] = useState<boolean>(true)
  const [isMenuSectionError, setIsMenuSectionError] = useState<boolean>(false)
  // dropdown for menu sections
  const [selectedMenuSectionId, setSelectedMenuSectionId] = useState<string | undefined>()
  const [allMenuItems, setAllMenuItems] = useState<FormattedMenuSectionInfo[]>([])

  const menuSectionLoadable = useRecoilValueLoadable(menuMappingSectionQuery(selectedMenuSectionId))
  const refreshMenuSection = useRecoilRefresher_UNSTABLE(
    menuMappingSectionQuery(selectedMenuSectionId),
  )
  const [menuSection, setMenuSection] = useState<MenuMappingSectionFormatted>()

  const [combinedMenuSectionData, setCombinedMenuSectionData] = useState<FormattedMenuSectionInfo>()

  const [itemModifierGroups, setItemModifierGroups] = useState<
    CustomizationGroupByCustomizationId | undefined
  >(undefined)

  const [selectedTab, setSelectedTab] = useState<number>(0)
  const [is86PreviewOnly, setIs86PreviewOnly] = useState<boolean>(false)
  const [input, setInput] = useState<string>('')
  const [expandAllModifiers, setExpandAllModifiers] = useState(false)

  const [isMenuPublishOutOfSync, setIsMenuPublishOutOfSync] = useState(false)
  const [isModifiersLoading, setIsModifiersLoading] = useState(false)
  const [isMenuAvailabilityModalOpen, setIsMenuAvailabilityModalOpen] = useState(false)

  const posName = partner?.attributes?.pos?.toLowerCase() || ''
  const isPrinterClient = posName.includes('epson') // We don't want to show printer clients the menu resync option
  const isAdminUser = user?.email?.includes('@chowlyinc.com')

  // switch to menu editor view triggered by props
  useEffect(() => {
    if (isEditor) {
      setIsMenuEditor(isEditor)
    }
  }, [isEditor])

  // switch to menu editor/preview triggered by state
  useEffect(() => {
    handleUrlChange()
  }, [isMenuEditor, selectedMenuSectionId, partner])

  const handleUrlChange = () => {
    if (!isMenuEditor) {
      history.replaceState(
        {},
        document.title,
        `/manage-locations/${partner?.id}/menus/${selectedMenuSectionId}/preview`,
      )
    } else {
      history.replaceState(
        {},
        document.title,
        `/manage-locations/${partner?.id}/menus/${selectedMenuSectionId}/edit`,
      )
    }
  }

  useEffect(() => {
    if (menuSections?.length) {
      if (menuSectionId) {
        setSelectedMenuSectionId(menuSectionId)
      } else {
        // default to the first one
        setSelectedMenuSectionId(menuSections[0].id)
      }
    }
  }, [menuSections])

  useEffect(() => {
    if (selectedMenuSectionId !== undefined) {
      refreshMenuSection()
      // update menu section id in url
      handleUrlChange()
    }
  }, [selectedMenuSectionId])

  useEffect(() => {
    if (menuSectionLoadable.state) {
      switch (menuSectionLoadable.state) {
        case 'hasValue':
          setMenuSection(menuSectionLoadable.contents)
          setIsMenuSectionLoading(false)
          break
        case 'loading':
          setIsMenuSectionLoading(true)
          break
        case 'hasError':
          setIsMenuSectionError(true)
          break
      }
    }
  }, [menuSectionLoadable.state])

  useEffect(() => {
    if (menuSection?.relationships?.menu?.data?.id) {
      const combinedMenuData = formatMenuSection(menuSection)
      setCombinedMenuSectionData(combinedMenuData)

      fetchMenuModifiers(menuSection)
    }
  }, [menuSection])

  const fetchMenuModifiers = async (menuSection: MenuMappingSectionFormatted) => {
    const menuMappingMenuId: MenuMappingMenuResource['id'] =
      menuSection?.relationships?.menu.data.id

    setIsModifiersLoading(true)
    let modifierGroupsData = {}

    try {
      const { formattedGroups: modifierGroups } = await getMenuMappingCustomizationOptions({
        menuMappingMenuId,
        menuMappingSectionId: menuSection.id,
      })

      if (Object.keys(modifierGroups)?.length) {
        setItemModifierGroups(modifierGroups)
        modifierGroupsData = modifierGroups
      }
    } catch (err) {
      console.log('failed to fetch item modifiers', err)
    }

    if (menuSection) {
      // format nested menu by combining the menu section data with its modifiers, groups and nested modifiers
      const combinedMenuData = formatMenuSection(menuSection, modifierGroupsData)
      setCombinedMenuSectionData(combinedMenuData)
    }

    setIsModifiersLoading(false)
  }

  useEffect(() => {
    if (menuSection?.subsections?.length) {
      setIsMenuSectionLoading(false)
    }
  }, [menuSection])

  const publishMenu = async () => {
    try {
      const response = await requestMenuPublish(menuSection?.relationships?.menu.data.id)

      // the first publish doesn't have a message, other publishes within 15 mins do.
      if (response?.data?.message) {
        toast.success(response.data.message)
      } else {
        toast.success(
          'Products have been updated. Publishing these changes out to all platforms can take a few minutes and will not be reflected right away.',
        )
      }
      setIsMenuPublishOutOfSync(false)
    } catch (err) {
      if (isAxiosError(err) && err.response?.status === 429) {
        toast.error(
          'A menu publish was enqueued less than 15 minutes ago. Please wait at least 15 minutes before publishing again. For additional help please contact Chowly Support using the “C” icon in the lower right corner of your screen.',
        )
      } else {
        toast.error(`Menu publishing couldn’t complete due to ${(err as AxiosError).message}`)
      }
      // if Publish wasn't able to complete, we are out of sync with the menu in the db and need to still publish out these changes. Show alert to user and ask to manually republish.
      setIsMenuPublishOutOfSync(true)
    }
  }

  const handleOnSearchChange = (
    event: React.ChangeEvent<HTMLInputElement> | React.MouseEvent<HTMLImageElement>,
  ) => {
    const input = (event.target as HTMLInputElement).value
    setInput(input)

    // b/c when searching we could have a keyword match on a modifier, we want to expand the modifiers if there's a search term
    if (input.length) {
      setExpandAllModifiers(true)
    } else {
      setExpandAllModifiers(false)
    }
  }

  if (isMenuSectionError) {
    return <Styled.Container>No menu data</Styled.Container>
  }

  // shown in menu header
  // const numberInactiveItems = allMenuItems.filter(
  //   (item) => !selectedItemIds.includes(item.id),
  // )?.length
  // const numberActiveItems = selectedItemIds.length

  return (
    <Styled.Container width={isMobile ? '342px' : undefined}>
      <MenuHeader
        menuSectionData={combinedMenuSectionData}
        is86PreviewOnly={is86PreviewOnly}
        onBackClick={() => setIs86PreviewOnly(false)}
        menuSections={menuSections}
        setSelectedMenuSectionId={setSelectedMenuSectionId}
        selectedMenuSectionId={selectedMenuSectionId}
        isPrinterClient={isPrinterClient}
        setIsMenuAvailabilityModalOpen={setIsMenuAvailabilityModalOpen}
        isMenuEditor={isMenuEditor}
      />
      {isMenuPublishOutOfSync && (
        <NotificationBanner
          message='Your RCC menu and 3PD menus may be out of sync due to multiple menu publish attempts. Please try publishing your menu again in 10 minutes.'
          buttonTitle='Retry Publish'
          onButtonClick={publishMenu}
        />
      )}
      <Styled.MenuTable>
        <Styled.TopRow height='63px'>
          <MenuSearch handleOnSearchChange={handleOnSearchChange} input={input} />
          {!isMenuEditor ? (
            <MenuTabs
              selectedTab={selectedTab}
              setSelectedTab={setSelectedTab}
              // inactiveItems={numberInactiveItems}
              // activeItems={numberActiveItems}
              isPreviewOnly={is86PreviewOnly}
              isLoading={isLoading}
            />
          ) : (
            <div style={{ flexGrow: 1 }} />
          )}
          <div style={{ display: 'flex', marginLeft: 'auto', marginRight: 0 }}>
            {isPrinterClient && isAdminUser ? (
              <Button size='small' narrow onClick={() => setIsMenuEditor(!isMenuEditor)}>
                {isMenuEditor ? 'View Menu' : 'Edit Menu'}
              </Button>
            ) : (
              <div style={{ flexGrow: 1 }} />
            )}
            <Styled.IconButton
              type='button'
              onClick={() => setExpandAllModifiers(!expandAllModifiers)}
            >
              <img src={EXPAND_ICON} />
            </Styled.IconButton>
          </div>
        </Styled.TopRow>
        {isMenuEditor ? (
          <MenuSectionEditor
            combinedMenuSectionData={combinedMenuSectionData}
            isLoading={
              isLoading || isMenuSectionLoading || isMenuSectionsLoading || isModifiersLoading
            }
            expandAllModifiers={expandAllModifiers}
            refreshMenuSection={refreshMenuSection}
          />
        ) : (
          <MenuSectionPreview
            combinedMenuSectionData={combinedMenuSectionData}
            isLoading={
              isLoading || isMenuSectionLoading || isMenuSectionsLoading || isModifiersLoading
            }
            refreshMenuSection={refreshMenuSection}
            publishMenu={publishMenu}
            searchInput={input}
            setSearchInput={setInput}
            itemModifierGroups={itemModifierGroups}
            selectedTab={selectedTab}
            setSelectedTab={setSelectedTab}
            expandAllModifiers={expandAllModifiers}
            setExpandAllModifiers={setExpandAllModifiers}
            setIs86PreviewOnly={setIs86PreviewOnly}
            is86PreviewOnly={is86PreviewOnly}
            allMenuItems={allMenuItems}
            setAllMenuItems={setAllMenuItems}
          />
        )}
      </Styled.MenuTable>
      {isMenuAvailabilityModalOpen &&
        combinedMenuSectionData?.attributes.availabilities &&
        menuSectionId && (
          <MenuAvailabilityModal
            isOpen={isMenuAvailabilityModalOpen}
            onClose={() => setIsMenuAvailabilityModalOpen(false)}
            availabilities={combinedMenuSectionData?.attributes.availabilities}
            menuSectionId={menuSectionId}
            refetchMenu={refreshMenuSection}
          />
        )}
    </Styled.Container>
  )
}

export default IndividualMenu
