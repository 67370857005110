import DMBaseButton from './DMBaseButton';
import { styled } from '@mui/material/styles';
import { ButtonProps } from '@mui/material/Button';

const RedButtonWrapper = styled(DMBaseButton)`
  ${props => props.variant === 'contained' && `background-color: #C13030; color: #FEFEFE;`}
  ${props => props.variant === 'outlined' && `border: 1px solid #C13030; color: #C13030;`}
  ${props => props.variant === 'text' && `color: #C13030;`}

  &:hover {
    background-color: ${props => props.variant === 'contained' ? '#C13030' : 'rgba(211, 47, 47, 0.04)'};
  }
`

/**
 * A styled MUI button with a red background. Default variant is 'contained'.
 */
const RedButton = (props: ButtonProps) => {
  return <RedButtonWrapper variant="contained" {...props} />
}

export default RedButton
