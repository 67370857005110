import React from 'react'

import { StyledBox, StyledInfoIcon, StyledLabel } from './styles'

interface DisclaimerProps {
  text: string
  variant?: 'center' | 'top' | 'firstLine'
  alignment?: 'alignLeft' | 'alignCenter'
  dataCy?: string | undefined
}

const Disclaimer: React.FC<DisclaimerProps> = ({
  text,
  variant = 'center',
  alignment = 'alignCenter',
  dataCy,
}) => {
  return (
    <StyledBox alignment={alignment} data-testid={dataCy}>
      <StyledInfoIcon variant={variant} />
      <StyledLabel variant='caption'>{text}</StyledLabel>
    </StyledBox>
  )
}
export default Disclaimer
