import { updateMenuMappingCustomizationOption } from 'api/menuMapping/customizationOptions'
import { updateMenuMappingItem } from 'api/menuMapping/items'
import { updateMenuMappingSubsection } from 'api/menuMapping/subsections'

import { CategoryNamesMap, ItemPriceMap, ModifierValuesMap } from '../MenuSectionEditor'

type Props = {
  itemsToUpdate: ItemPriceMap
  categoriesToUpdate: CategoryNamesMap
  modifiersToUpdate: ModifierValuesMap
}
/**
 * Used to update category and item attributes from the Menu Editor section view, where we can update the following:
 * Category titles in bulk
 * Item prices in bulk
 * Modifier and Nested Modifier prices in bulk
 *  */
export const updateItemsAndCategories = async ({
  itemsToUpdate,
  categoriesToUpdate,
  modifiersToUpdate,
}: Props) => {
  let updatedPricesResult = null
  let updatedCategoriesResult = null
  let updateModifiersResult = null

  // update item prices
  if (Object.keys(itemsToUpdate).length) {
    updatedPricesResult = await Promise.all(
      Object.entries(itemsToUpdate).map(async ([combinedId, values]) => {
        const { price } = values
        const splitIdsArr = combinedId.split(':')
        const itemId = splitIdsArr[0]
        const subsectionId = splitIdsArr[1]

        return await updateMenuMappingItem({
          itemId,
          attributes: {
            subsection_items: { subsection_id: subsectionId, item_price: price || 0 },
          },
        })
      }),
    )
  }

  // udpate category (subsection) titles
  if (Object.keys(categoriesToUpdate).length) {
    updatedCategoriesResult = await Promise.all(
      Object.entries(categoriesToUpdate).map(async ([id, title]) => {
        const attributes = { title }
        return await updateMenuMappingSubsection(id, attributes)
      }),
    )
  }

  // update modifier prices
  if (Object.keys(modifiersToUpdate).length) {
    updateModifiersResult = await Promise.all(
      Object.entries(modifiersToUpdate).map(async ([modId, values]) => {
        const customizationOptionId = modId
        const customizationId = values.parentGroupId
        const { price } = values

        const attributes = {
          customization_customization_options: {
            customization_id: customizationId,
            customization_option_price: price,
          },
        }

        return await updateMenuMappingCustomizationOption({ id: customizationOptionId, attributes })
      }),
    )
  }
  // TODO later: print better error/success messaging to the user for failed/succeeded items.
  return { updatedPricesResult, updatedCategoriesResult, updateModifiersResult }
}
